import React, { useState, useEffect } from "react";
import NavBar from "../../../components/layout/NavBar";
import Loading1 from "../../../components/action/Loading1";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Button, Paper, IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { Helmet } from "react-helmet";

function TestimoniesIndex({ rootLink }) {
  const [response, setResponse] = useState({ status: "", response: "" });
  const [alert, setAlert] = useState(false);
  const [testimonies, setTestimonies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    LoadEvents();
  }, []);

  //FETCH TESTIMONIES
  const LoadEvents = () => {
    const formData = new FormData();
    formData.append("fetchTestimonies", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/testimony.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setTestimonies(jsonData);

      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF FETCH TESTIMONIES

  //PAGINATION
  const itemsPerPage = 20;
  const pagesVisited = pageNumber * itemsPerPage;

  const displayItems = testimonies
    .slice(pagesVisited, pagesVisited + itemsPerPage)
    .map((item) => {
      return (
        <div className='eventCard' key={item.ref}>
          <Link to={"/testimonials/" + item.test_ref}>
            <div>
              <div>
                <img src={"/images/testimony/photo/" + item.photo} />
              </div>

              <div className='name'>
                <div>{item.name}</div>
              </div>
            </div>
          </Link>
        </div>
      );
    });

  const pageCount = Math.ceil(testimonies.length / itemsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  //END OF PAGINATION

  return (
    <>
      <NavBar
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <Helmet>
        <title>HUMAN TELOS INITIATIVE - TESTIMONIALS</title>
        <meta
          name='description'
          content='Human Telos Initiative - Testimonials'
        />
        <meta
          name='keywords'
          content='Human Telos Initiative Testimonials, Human Telos Testimonials, Human Telos, Human Telos Initiative, Donations, Charity, Help the less privileged, Fund raising,'
        />
      </Helmet>

      <div className='container'>
        <div className='testimonies'>
          <h2 className='title'>Testimonials</h2>

          <Paper
            elevation={2}
            sx={{
              maxWidth: "1200px",
              width: "95%",
              margin: "0 auto",
              padding: "20px",
              borderRadius: "5px",
            }}
          >
            {testimonies.length === 0 ? (
              <h3>No Testimony</h3>
            ) : (
              <div className='eventSlider'>{displayItems}</div>
            )}

            {testimonies.length < itemsPerPage + 1 ? null : (
              <div className='pagination'>
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationContainer"}
                  previousLinkClassName={"prevBtn"}
                  nextLinkClassName={"nextBtn"}
                  activeClassName={"activePagination"}
                />
              </div>
            )}

            <br />
            <IconButton onClick={() => navigate("../../")}>
              <ArrowBack />
            </IconButton>
          </Paper>
        </div>

        <br />
        <br />
        <center>
          <Button onClick={() => navigate("./add")}>Add Testimony</Button>
        </center>
      </div>
      <Loading1 loading={isLoading} />
    </>
  );
}

export default TestimoniesIndex;

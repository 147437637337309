import React from "react";
import { Routes, Route } from "react-router-dom";
import TestimoniesIndex from "./index";
import ViewTestimonies from "./view";
import AddTestimony from "./add";

function TestimoniesRouter({ rootLink }) {
  return (
    <Routes>
      <Route path='/' element={<TestimoniesIndex rootLink={rootLink} />} />
      <Route path='/add' element={<AddTestimony rootLink={rootLink} />} />
      <Route path={"/:ref"} element={<ViewTestimonies rootLink={rootLink} />} />
    </Routes>
  );
}

export default TestimoniesRouter;

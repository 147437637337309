import { Routes, Route } from "react-router-dom";
import WebsiteIndex from "./index";

function WebsiteRouter({ rootLink }) {
  return (
    <>
      <Routes>
        <Route path={"/"} element={<WebsiteIndex rootLink={rootLink} />} />
      </Routes>
    </>
  );
}

export default WebsiteRouter;

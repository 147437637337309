import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";

function DeleteQualification({
  qRef,
  modal,
  rootLink,
  setResponse,
  setModal,
  setAlert,
  loadQualifications,
}) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const deleteId = qRef;

  const deleteBtn = (e) => {
    setLoadingBtn(true);
    e.preventDefault();

    //DELETE
    const formData = new FormData();
    formData.append("deleteQualification", deleteId);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/qualification.php", true);
    http.onload = function () {
      setResponse(JSON.parse(this.responseText));
      setModal(false);
      setAlert(true);
      setLoadingBtn(false);
      loadQualifications();
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF DELETE

  return (
    <Dialog open={modal}>
      <DialogTitle>Delete Qualification</DialogTitle>

      <DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={() => {
              setModal(false);
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loadingBtn ? true : false}
            onClick={deleteBtn}
            variant='contained'
            color='error'
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default DeleteQualification;

import Nav from "../../../components/layout/Nav";
import { useEffect, useState } from "react";
import Loading1 from "../../../components/action/Loading1";
import {
  Paper,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteImage from "../../../components/action/modals/gallery/DeleteImage";
import { NavLink } from "react-router-dom";

function GalleryIndex({ rootLink }) {
  const [alert, setAlert] = useState(false);
  const [response, setResponse] = useState({ status: "", response: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [loadContent, setLoadContent] = useState([]);
  const [modal, setModal] = useState(false);
  const [imageRef, setImageRef] = useState("");

  useEffect(() => {
    loadGallery();
  }, []);

  //LOAD CONTENTS
  function loadGallery() {
    const formData = new FormData();
    formData.append("fetchGallery", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/gallery.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setLoadContent(jsonData);

      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD CONTENTS

  //OPEN MODAL
  const openModal = (imageRef) => {
    setImageRef(imageRef);
    setModal(true);
  };
  //END OF OPEN MODAL

  return (
    <>
      <Nav
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <div className='content'>
        <div className='breadCrumb'>
          <NavLink
            to={"/admin/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Dashboard
          </NavLink>{" "}
          /{" "}
          <NavLink
            to={"/admin/gallery/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Gallery
          </NavLink>
        </div>

        <h1>Gallery</h1>

        <Paper
          elevation={4}
          sx={{ width: "95%", margin: "0 auto", padding: "20px" }}
        >
          {loadContent.status === "error" ? (
            <>
              <h3>{loadContent.response}</h3>
            </>
          ) : loadContent.length === 0 ? (
            <>
              <h3>Gallery is empty</h3>
            </>
          ) : (
            <>
              <ImageList
                sx={{ width: "100%", height: "100%" }}
                cols={3}
                gap={8}
              >
                {loadContent.map((item) => {
                  return (
                    <ImageListItem key={item.ref}>
                      <img src={"/images/events/" + item.name} loading='lazy' />
                      <ImageListItemBar
                        actionIcon={
                          <IconButton onClick={() => openModal(item.ref)}>
                            <CancelIcon color='error' />
                          </IconButton>
                        }
                      />
                    </ImageListItem>
                  );
                })}
              </ImageList>
            </>
          )}
        </Paper>
      </div>

      {modal ? (
        <DeleteImage
          imageRef={imageRef}
          setModal={setModal}
          modal={modal}
          rootLink={rootLink}
          setResponse={setResponse}
          setAlert={setAlert}
          loadGallery={loadGallery}
        />
      ) : null}

      <Loading1 loading={isLoading} />
    </>
  );
}

export default GalleryIndex;

import Nav from "../../../components/layout/Nav";
import { useEffect, useState } from "react";
import Loading1 from "../../../components/action/Loading1";
import EditIcon from "@mui/icons-material/Edit";
import { Stack, Paper, IconButton, Button, ButtonGroup } from "@mui/material";
import EditAbout from "../../../components/action/modals/about/EditAbout";
import HTMLReactParser from "html-react-parser";
import ChangePhoto from "../../../components/action/modals/about/ChangePhoto";
import EditName from "../../../components/action/modals/about/EditName";
import { NavLink } from "react-router-dom";

function AboutIndex({ rootLink }) {
  const [alert, setAlert] = useState(false);
  const [response, setResponse] = useState({ status: "", response: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [editModal, setEditModal] = useState(false);
  const [editNameModal, setEditNameModal] = useState(false);
  const [loadContent, setLoadContent] = useState([]);
  const [changePhotoModal, setChangePhotoModal] = useState(false);

  useEffect(() => {
    loadAbout();
  }, []);

  //LOAD REPLY
  function loadAbout() {
    const formData = new FormData();
    formData.append("fetchAbout", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/about.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setLoadContent(jsonData);

      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD REPLY

  //OPEN EDIT MODAL
  const openEditModal = () => {
    setEditModal(true);
  };
  //END OF OPEN EDIT MODAL

  //OPEN EDIT MODAL
  const photoChanger = () => {
    setChangePhotoModal(true);
  };
  //END OF OPEN EDIT MODAL

  //OPEN EDIT NAME MODAL
  const editName = () => {
    setEditNameModal(true);
  };
  //END OF OPEN EDIT NAME MODAL

  return (
    <>
      <Nav
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <div className='content'>
        <div className='breadCrumb'>
          <NavLink
            to={"/admin/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Dashboard
          </NavLink>{" "}
          /{" "}
          <NavLink
            to={"/admin/about/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            About Us
          </NavLink>
        </div>

        <h1>About Us</h1>
        <Paper
          elevation={4}
          sx={{ width: "95%", margin: "0 auto", padding: "20px" }}
        >
          <>
            <>
              <center>
                <ButtonGroup variant='contained'>
                  <Button onClick={photoChanger}>Change Photo</Button>
                  <Button onClick={editName}>Edit Name And Title</Button>
                </ButtonGroup>
              </center>
              <div>
                {loadContent.about ? HTMLReactParser(loadContent.about) : null}
              </div>
              <br />
              <Stack
                sx={{ justifyContent: "center", margin: "10px" }}
                spacing={2}
                direction='row'
              >
                <Button
                  onClick={openEditModal}
                  color='primary'
                  variant='contained'
                  startIcon={<EditIcon />}
                >
                  Edit About
                </Button>
              </Stack>
            </>

            {editModal ? (
              <EditAbout
                setModal={setEditModal}
                modal={editModal}
                rootLink={rootLink}
                setResponse={setResponse}
                setAlert={setAlert}
                loadAbout={loadAbout}
              />
            ) : null}

            {editNameModal ? (
              <EditName
                setModal={setEditNameModal}
                modal={editNameModal}
                rootLink={rootLink}
                setResponse={setResponse}
                setAlert={setAlert}
                loadAbout={loadAbout}
              />
            ) : null}

            {changePhotoModal ? (
              <ChangePhoto
                setModal={setChangePhotoModal}
                modal={changePhotoModal}
                rootLink={rootLink}
                setResponse={setResponse}
                setAlert={setAlert}
                loadAbout={loadAbout}
              />
            ) : null}
          </>

          <Loading1 loading={isLoading} />
        </Paper>
      </div>
    </>
  );
}

export default AboutIndex;

import React, { useState, useEffect } from "react";
import NavBar from "../../../components/layout/NavBar";
import Loading1 from "../../../components/action/Loading1";
import { TextField, MenuItem, Paper, IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function SponsorsIndex({ rootLink }) {
  const [response, setResponse] = useState({ status: "", response: "" });
  const [alert, setAlert] = useState(false);
  const [sponsors, setSponsors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    loadSponsors();
  }, []);

  //LOAD SPONSORS
  function loadSponsors() {
    const formData = new FormData();
    formData.append("fetchSponsors", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/sponsors.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setSponsors(jsonData);
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD SPONSORS

  return (
    <>
      <NavBar
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <Helmet>
        <title>HUMAN TELOS INITIATIVE - SPONSORS</title>
        <meta name='description' content='Human Telos Initiative - Sponsors' />
        <meta
          name='keywords'
          content='Human Telos Initiative Sponsors, Human Telos Sponsors, Human Telos, Human Telos Initiative, Donations, Charity, Help the less privileged, Fund raising,'
        />
      </Helmet>

      <div className='container'>
        <h2>Sponsors</h2>

        <Paper
          elevation={2}
          sx={{
            maxWidth: "1000px",
            width: "95%",
            margin: "0 auto",
            padding: "20px",
            borderRadius: "5px",
          }}
        >
          {sponsors.length > 0 ? (
            <div className='sponsorsContainer'>
              {sponsors.map((item) => {
                return (
                  <div className='sponsorsCard small' key={item.ref}>
                    {item.link === "" ? (
                      <>
                        <img src={"/images/assets/sponsors/" + item.logo} />
                        <div className='name'>{item.name}</div>
                      </>
                    ) : (
                      <a href={item.link}>
                        <img src={"/images/assets/sponsors/" + item.logo} />
                        <div className='name'>{item.name}</div>
                      </a>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              <h3>Sponsor unavailable</h3>
            </>
          )}

          <IconButton onClick={() => navigate("../../")}>
            <ArrowBack />
          </IconButton>
        </Paper>
      </div>
      <Loading1 loading={isLoading} />
    </>
  );
}

export default SponsorsIndex;

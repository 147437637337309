function Loading1({ loading }) {
  return (
    <>
      {loading ? (
        <>
          <div className='loaderBackdrop'>
            <img src='/images/assets/logo/logo1.png' alt='' />
          </div>
        </>
      ) : null}
    </>
  );
}

export default Loading1;

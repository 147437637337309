import { useState, useEffect } from "react";
import Nav from "../../../components/layout/Nav";
import {
  TextField,
  MenuItem,
  Button,
  IconButton,
  Checkbox,
  Stack,
} from "@mui/material";
import { Delete, Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import Loading1 from "../../../components/action/Loading1";
import { NavLink } from "react-router-dom";
import Editor from "../../../components/Editor/Editor";
import Sponsors from "../../../components/action/modals/events/Sponsors";

function CreateAnnouncement({ rootLink }) {
  const [alert, setAlert] = useState(false);
  const [response, setResponse] = useState({ status: "", response: "" });
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (title === "") {
      const msg = "Title is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (message === "") {
      const msg = "Message is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (title && message) {
      setLoadingBtn(true);
      const formData = new FormData();

      formData.append("title", title);
      formData.append("link", link);
      formData.append("message", message);
      formData.append("addAnnouncement", "Add");

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/server/announcement.php", true);
      http.onload = function () {
        const jsonData = JSON.parse(this.responseText);

        if (jsonData !== null) {
          setAlert(true);
          setResponse(jsonData);
        }
        setLoadingBtn(false);
      };
      http.send(formData);
      //END OF AJAX
    }
  };
  //END OF FORM SUBMIT

  return (
    <>
      <Nav
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <div className='content'>
        <div className='breadCrumb'>
          <NavLink
            to={"/admin/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Dashboard
          </NavLink>{" "}
          /{" "}
          <NavLink
            to={"/admin/announcement/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Announcements
          </NavLink>{" "}
          /{" "}
          <NavLink
            to={"/admin/announcement/create"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Create Announcement
          </NavLink>
        </div>

        <h1>Create Announcement</h1>

        <div className='formContainer'>
          <form onSubmit={formSubmit}>
            <TextField
              label='Title'
              onChange={(e) => setTitle(e.target.value)}
              required
            />

            <br />

            <TextField
              label='Link'
              type='url'
              onChange={(e) => setLink(e.target.value)}
            />

            <br />

            <label htmlFor='message'>Message</label>
            <Editor value='' setValue={setMessage} />
            <br />

            <LoadingButton
              variant='contained'
              onClick={formSubmit}
              loading={loadingBtn ? true : false}
              startIcon={<Save />}
            >
              Add
            </LoadingButton>
          </form>
        </div>

        <br />
      </div>

      <Loading1 loading={isLoading} />
    </>
  );
}

export default CreateAnnouncement;

import { useState, createContext, useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Admin from "./index";
import AboutRouter from "./about/router";
import AnnouncementRouter from "./announcement/router";
import CausesRouter from "./causes/router";
import EventsRouter from "./events/router";
import GalleryRouter from "./gallery/router";
import MessagesRouter from "./messages/router";
import RepliesRouter from "./replies/router";
import SettingsRouter from "./settings/router";
import SocialsRouter from "./socials/router";
import VolunteersRouter from "./volunteers/router";
import DonationsRouter from "./donations/router";
import TestimoniesRouter from "./testimony/router";
import WebsiteRouter from "./website/router";
import ParticipantsRouter from "./participants/router";
import SponsorsRouter from "./sponsors/router";
import QualificationRouter from "./qualification/router";
import ProjectsRouter from "./projects/router";
import ErrorPage from "./404";
import LoginPage from "./login";
export const LoggedIn = createContext(null);
export const RootLink = createContext(null);

function AdminRouter({ rootLink }) {
  const [loggedin, setLoggedin] = useState(false);

  const { pathname } = useLocation();

  useLayoutEffect(
    function () {
      window.scrollTo(0, 0);
    },
    [pathname]
  );
  return (
    <>
      {loggedin === true ? (
        <>
          <RootLink.Provider value={rootLink}>
            <LoggedIn.Provider value={setLoggedin}>
              <Routes>
                <Route path={"/"} element={<Admin rootLink={rootLink} />} />

                <Route
                  path={"/about/*"}
                  element={<AboutRouter rootLink={rootLink} />}
                />
                <Route
                  path={"/announcement/*"}
                  element={<AnnouncementRouter rootLink={rootLink} />}
                />
                <Route
                  path={"/courses/*"}
                  element={<CausesRouter rootLink={rootLink} />}
                />

                <Route
                  path={"/projects/*"}
                  element={<ProjectsRouter rootLink={rootLink} />}
                />
                <Route
                  path={"/events/*"}
                  element={<EventsRouter rootLink={rootLink} />}
                />
                <Route
                  path={"/gallery/*"}
                  element={<GalleryRouter rootLink={rootLink} />}
                />
                <Route
                  path={"/messages/*"}
                  element={<MessagesRouter rootLink={rootLink} />}
                />
                <Route
                  path={"/participants/*"}
                  element={<ParticipantsRouter rootLink={rootLink} />}
                />

                <Route
                  path={"/sponsors/*"}
                  element={<SponsorsRouter rootLink={rootLink} />}
                />

                <Route
                  path={"/replies"}
                  element={<RepliesRouter rootLink={rootLink} />}
                />
                <Route
                  path={"/settings"}
                  element={<SettingsRouter rootLink={rootLink} />}
                />
                <Route
                  path={"/socials"}
                  element={<SocialsRouter rootLink={rootLink} />}
                />
                <Route
                  path={"/website"}
                  element={<WebsiteRouter rootLink={rootLink} />}
                />

                <Route
                  path={"/volunteers/*"}
                  element={<VolunteersRouter rootLink={rootLink} />}
                />
                <Route
                  path={"/donations/*"}
                  element={<DonationsRouter rootLink={rootLink} />}
                />
                <Route
                  path={"/testimony/*"}
                  element={<TestimoniesRouter rootLink={rootLink} />}
                />

                <Route
                  path={"/qualifications/*"}
                  element={<QualificationRouter rootLink={rootLink} />}
                />

                <Route path={"*"} element={<ErrorPage rootLink={rootLink} />} />
              </Routes>
            </LoggedIn.Provider>
          </RootLink.Provider>
        </>
      ) : (
        <LoginPage
          rootLink={rootLink}
          setLoggedin={setLoggedin}
          loggedin={loggedin}
        />
      )}
    </>
  );
}

export default AdminRouter;

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState, useEffect } from "react";

function EditSponsor({
  sponsorId,
  modal,
  rootLink,
  setResponse,
  loadSponsors,
  setModal,
  setAlert,
}) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [content, setContent] = useState([]);
  const [name, setName] = useState("");
  const [link, setLink] = useState("");

  useEffect(() => {
    loadContent();
  }, []);

  //LOAD CONTENT
  const loadContent = () => {
    const formData = new FormData();
    formData.append("fetchThisSponsor", sponsorId);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/sponsor.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setName(jsonData.name);
      setLink(jsonData.link);
    };
    http.send(formData);
  };
  //LOAD CONTENT

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (name === "") {
      const msg = "Name is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (name) {
      setLoadingBtn(true);

      const formData = new FormData();
      formData.append("editSponsor", sponsorId);
      formData.append("name", name);
      formData.append("link", link);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/server/sponsor.php", true);
      http.onload = function () {
        setResponse(JSON.parse(this.responseText));
        setModal(false);
        setAlert(true);
        setLoadingBtn(false);
        loadSponsors();
      };
      http.send(formData);
    }
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal}>
      <DialogTitle>Edit Sponsor Details</DialogTitle>

      <DialogContent>
        <div className='formContainer' style={{ width: "100%" }}>
          <form onSubmit={formSubmit}>
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              type='text'
              label='Name'
            />

            <TextField
              value={link}
              onChange={(e) => setLink(e.target.value)}
              type='text'
              label='Link'
            />

            <DialogActions>
              <Button
                color='error'
                variant='contained'
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={loadingBtn ? true : false}
                onClick={formSubmit}
                variant='contained'
              >
                Edit
              </LoadingButton>
            </DialogActions>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default EditSponsor;

import React, { useState, useEffect } from "react";
import NavBar from "../../../components/layout/NavBar";
import Loading1 from "../../../components/action/Loading1";
import { TextField, MenuItem, Paper, IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function DonationIndex({ rootLink }) {
  const [response, setResponse] = useState({ status: "", response: "" });
  const [alert, setAlert] = useState(false);
  const [causes, setCauses] = useState([]);
  const [cause, setCause] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadCauses();
  }, []);

  //LOAD CAUSES
  function loadCauses() {
    const formData = new FormData();
    formData.append("fetchCauses", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/donation.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setCauses(jsonData);
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD CAUSES

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (fname === "") {
      const msg = "First Name is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }
    if (email === "") {
      const msg = "Email is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }
    if (!isEmail(email)) {
      const msg = "Email is invalid";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (phone === "") {
      const msg = "Phone number is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (phone.length < 8) {
      const msg = "Phone number is invalid";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (isNaN(phone)) {
      const msg = "Phone number is invalid";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (amount === "") {
      const msg = "Amount is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (isNaN(amount)) {
      const msg = "Amount is invalid";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (lname === "") {
      const msg = "Last name is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (cause === "") {
      const msg = "Course is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (address === "") {
      const msg = "Address is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (
      fname &&
      lname &&
      email &&
      isEmail(email) &&
      phone &&
      !isNaN(phone) &&
      phone.length > 8 &&
      amount &&
      !isNaN(amount) &&
      address &&
      cause
    ) {
      setLoadingBtn(true);

      const formData = new FormData();
      formData.append("fname", fname);
      formData.append("lname", lname);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("cause", cause);
      formData.append("amount", amount);
      formData.append("address", address);
      formData.append("addDonor", "Add");

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/client/donation.php", true);
      http.onload = function () {
        setLoadingBtn(false);
        navigate("/donate/account");
      };
      http.send(formData);
      //END OF AJAX
    }
  };
  //END OF FORM SUBMIT

  function isEmail(email) {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  }

  return (
    <>
      <NavBar
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <Helmet>
        <title>HUMAN TELOS INITIATIVE - DONATION</title>
        <meta name='description' content='Human Telos Initiative - Donation' />
        <meta
          name='keywords'
          content='Human Telos Initiative Donation, Human Telos Donation, Human Telos, Human Telos Initiative, Donations, Charity, Help the less privileged, Fund raising,'
        />
      </Helmet>

      <div className='container'>
        <h2>Donation</h2>

        <Paper
          elevation={2}
          sx={{
            maxWidth: "700px",
            width: "95%",
            margin: "0 auto",
            padding: "20px",
            borderRadius: "5px",
          }}
        >
          <form onSubmit={formSubmit}>
            <br />
            <TextField
              onChange={(e) => setFname(e.target.value)}
              label='First Name'
              required
              fullWidth
            />
            <br />
            <TextField
              onChange={(e) => setLname(e.target.value)}
              label='Last Name'
              required
              fullWidth
            />
            <br />
            <TextField
              onChange={(e) => setEmail(e.target.value)}
              label='Email'
              type='email'
              required
              fullWidth
            />
            <br />
            <TextField
              onChange={(e) => setPhone(e.target.value)}
              label='Phone Number'
              type='phone'
              required
              fullWidth
            />

            <br />
            <TextField
              onChange={(e) => setCause(e.target.value)}
              label='Course'
              select
              fullWidth
              required
            >
              {causes.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.name}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </TextField>

            <br />
            <TextField
              onChange={(e) => setAddress(e.target.value)}
              label='Address'
              required
              fullWidth
            />
            <br />

            <TextField
              onChange={(e) => setAmount(e.target.value)}
              label='Amount'
              type='number'
              required
              fullWidth
            />
            <br />

            <LoadingButton
              loading={loadingBtn ? true : false}
              onClick={formSubmit}
              variant='contained'
            >
              Submit
            </LoadingButton>
          </form>

          <IconButton onClick={() => navigate("../../")}>
            <ArrowBack />
          </IconButton>
        </Paper>
      </div>
      <Loading1 loading={isLoading} />
    </>
  );
}

export default DonationIndex;

import JoditEditor from "jodit-react";
import { useEffect, useMemo, useRef, useContext } from "react";
import { RootLink } from "../../pages/admin/router";

function Editor({ setValue, value }) {
  const rootLink = useContext(RootLink);
  // const [isReady, setIsReady] = useState(false);

  const editor = useRef(null);
  const relativePathURL = rootLink + "images/uploads/";

  const config = {
    uploader: {
      url: rootLink + "backend/server/editor.php",
      isSuccess: function (resp) {
        return !resp.error;
      },

      baseurl: relativePathURL,
      process: function (resp) {
        let files = [];
        resp.list.map((file) => {
          files.push(file.name);
        });

        return {
          files: resp.list,
          path: relativePathURL,
          baseurl: relativePathURL,
          error: resp.success ? 0 : 1,
          msg: resp.message,
        };
      },
      error: function (e) {
        console.log(e);
      },
      defaultHandlerSuccess: function (resp) {
        if (resp.files && resp.files.length) {
          for (let i = 0; i < resp.files.length; i++) {
            let full_file_path = resp.path + resp.files[i];
            this.selection.insertImage(full_file_path, null, 250);
          }
        }
      },
      defaultHandlerError: function (resp) {
        this.events.fire("errorPopap", [this.options.uploader.getMsg(resp)]);
      },
    },
  };

  return useMemo(
    () => (
      <JoditEditor
        config={config}
        ref={editor}
        value={value}
        onChange={(content) => setValue(content)}
      />
    ),
    [value]
  );
}

export default Editor;

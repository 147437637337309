import { Link, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import Nav from "../../../components/layout/Nav";
import Loading1 from "../../../components/action/Loading1";
import ReactPaginate from "react-paginate";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, TextField, MenuItem, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";

function ParticipantsIndex({ rootLink }) {
  const [alert, setAlert] = useState(false);
  const [response, setResponse] = useState({ status: "", response: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [loadContent, setLoadContent] = useState([]);
  const [stat, setStat] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [cause, setCause] = useState("");
  const [causes, setCauses] = useState([]);
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState("");
  const [emptyContent, setEmptyContent] = useState({
    status: "",
    response: "",
  });
  const [loadingSelect, setLoadingSelect] = useState(false);

  useEffect(() => {
    loadParticipants();
    loadCauses();
    loadStatistics();
  }, [project, cause]);

  //PAGINATION
  const itemsPerPage = 20;
  const pagesVisited = pageNumber * itemsPerPage;

  const displayItems = loadContent
    .slice(pagesVisited, pagesVisited + itemsPerPage)
    .map((item) => {
      return (
        <div className='simpleCard' key={item.ref}>
          <Link className='head' to={"../../../admin/participants/" + item.ref}>
            <div className='name'>{item.first_name + " " + item.last_name}</div>
            <div className='others'>
              {item.project}{" "}
              {item.status === "closed" ? (
                <div style={{ color: "red" }}>Closed</div>
              ) : null}
            </div>
          </Link>

          {item.status === "closed" ? (
            item.selected === "yes" ? (
              "Selected"
            ) : (
              "Not Selected"
            )
          ) : (
            <div className='detail'>
              <LoadingButton
                loading={loadingSelect ? true : false}
                onClick={() => handleSelect(item.ref)}
              >
                {item.selected === "yes" ? "Selected" : "Not Selected"}
              </LoadingButton>
            </div>
          )}
        </div>
      );
    });

  const pageCount = Math.ceil(loadContent.length / itemsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  //END OF PAGINATION

  //LOAD PARTICIPANTS
  function loadParticipants() {
    const formData = new FormData();
    formData.append("fetchParticipants", cause);
    formData.append("project", project);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/participate.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      if (jsonData.status === "error") {
        setEmptyContent(jsonData);
      } else {
        setLoadContent(jsonData);
      }
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD PARTICIPANTS

  //LOAD PROJECTS
  function loadProjects(fetch) {
    const formData = new FormData();
    formData.append("fetchThisProjects", fetch);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/participate.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setProjects(jsonData);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD PROJECTS

  //LOAD CAUSES
  function loadCauses() {
    const formData = new FormData();
    formData.append("fetchCauses", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/causes.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setCauses(jsonData);
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD CAUSES

  //HANDLE SELECTED PARTICIPANTS
  const handleSelect = (id) => {
    setLoadingSelect(true);

    const formData = new FormData();
    formData.append("participantSelect", id);

    //AJAX
    let https = new XMLHttpRequest();
    https.open("POST", rootLink + "backend/server/participate.php", true);
    https.onload = function () {
      setAlert(true);
      setResponse(JSON.parse(this.responseText));
      loadParticipants();
      loadStatistics();
      setLoadingSelect(false);
    };
    https.send(formData);
    //END OF AJAX
  };
  //END OF HANDLE SELECTED PARTICIPANTS

  //LOAD STATISTICS
  const loadStatistics = () => {
    const formData = new FormData();
    formData.append("fetchStatistics", cause);
    formData.append("project", project);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/participate.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setStat(jsonData);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF LOAD STATISTICS

  //SHOW DIFFERENT CATEGORY
  const showView = (item) => {
    const formData = new FormData();
    formData.append("fetchSelectedParticipants", cause);
    formData.append("view", item);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/participate.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      if (jsonData.status === "error") {
        setEmptyContent(jsonData);
      } else {
        setLoadContent(jsonData);
      }
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF SHOW DIFFERENT CATEGORY

  return (
    <>
      <Nav
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <div className='content'>
        <div className='breadCrumb'>
          <NavLink
            to={"/admin/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Dashboard
          </NavLink>{" "}
          /{" "}
          <NavLink
            to={"/admin/participants/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Participants
          </NavLink>
        </div>

        <h1>Participants</h1>

        <div className='homeContent'>
          <br />

          <TextField
            onChange={(e) => {
              setCause(e.target.value);
              loadProjects(e.target.value);
            }}
            label='Course'
            select
            fullWidth
            required
          >
            {causes.map((item) => {
              return (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              );
            })}
          </TextField>

          <br />
          <br />

          <TextField
            onChange={(e) => setProject(e.target.value)}
            label='Project'
            select
            fullWidth
            required
          >
            {projects.map((item) => {
              return (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              );
            })}
          </TextField>

          <br />
          <br />

          {emptyContent.status === "error" ? (
            <>
              <h3>{emptyContent.response}</h3>
            </>
          ) : cause === "" ? null : loadContent.length === 0 ? (
            <h3>No Participant</h3>
          ) : (
            <>
              <div className='dashConatainer'>
                <div className='dashCard'>
                  <div className='cardChildren'>
                    <div onClick={loadParticipants} className='childItem'>
                      <div className='childHead'>Total</div>
                      <div className='childNumber'>{stat.total}</div>
                    </div>
                    <div
                      onClick={() => showView("selected")}
                      className='childItem'
                    >
                      <div className='childHead'>Selected </div>
                      <div className='childNumber'>{stat.selected} </div>
                    </div>
                    <div
                      onClick={() => showView("unselected")}
                      className='childItem'
                    >
                      <div className='childHead'>Not Selected </div>
                      <div className='childNumber'>{stat.unselected} </div>
                    </div>
                  </div>
                </div>
              </div>

              <center>
                <div className='simpleCardContainer'>
                  <Stack
                    direction={"row"}
                    sx={{ flexWrap: "wrap", justifyContent: "center" }}
                  >
                    {displayItems}
                  </Stack>
                </div>
              </center>

              {loadContent.length < itemsPerPage + 1 ? null : (
                <div className='pagination'>
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationContainer"}
                    previousLinkClassName={"prevBtn"}
                    nextLinkClassName={"nextBtn"}
                    activeClassName={"activePagination"}
                  />
                </div>
              )}
            </>
          )}
          <Loading1 loading={isLoading} />
        </div>
      </div>

      <br />
      <br />
    </>
  );
}

export default ParticipantsIndex;

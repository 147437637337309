import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  ButtonGroup,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function UpdateDonation({
  donateRef,
  modal,
  rootLink,
  setResponse,
  setModal,
  setAlert,
  loadThisDonation,
}) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const navigate = useNavigate();

  const btnHandler = (action) => {
    setLoadingBtn(true);

    const formData = new FormData();
    formData.append("updateDonation", donateRef);
    formData.append("update", action);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/donations.php", true);
    http.onload = function () {
      setResponse(JSON.parse(this.responseText));
      setModal(false);
      setAlert(true);
      setLoadingBtn(false);
      loadThisDonation();
    };
    http.send(formData);
    //END OF AJAX
  };

  return (
    <Dialog open={modal}>
      <DialogTitle>Update Donation Status</DialogTitle>

      <DialogContent>
        <DialogActions>
          <ButtonGroup>
            <LoadingButton
              loading={loadingBtn ? true : false}
              onClick={() => btnHandler("Failed")}
              variant='contained'
              color='error'
            >
              Failed
            </LoadingButton>
            <LoadingButton
              loading={loadingBtn ? true : false}
              onClick={() => btnHandler("Successful")}
              variant='contained'
            >
              Successful
            </LoadingButton>
          </ButtonGroup>

          <br />
        </DialogActions>
        <br />
        <center>
          <Button
            variant='contained'
            onClick={() => {
              setModal(false);
            }}
          >
            Close
          </Button>
        </center>
      </DialogContent>
    </Dialog>
  );
}

export default UpdateDonation;

import { useEffect, useState } from "react";
import Nav from "../../../components/layout/Nav";
import Loading1 from "../../../components/action/Loading1";
import DeleteMessage from "../../../components/action/modals/messages/DeleteMessage";
import ReplyModal from "../../../components/action/modals/messages/ReplyModal";
import ShowReply from "../../../components/action/modals/messages/ShowReply";
import { Stack, Button, ButtonGroup, Paper } from "@mui/material";
import HTMLReactParser from "html-react-parser";
import { useParams, NavLink } from "react-router-dom";

function ViewMessage({ rootLink }) {
  const { messageRef } = useParams();
  const [response, setResponse] = useState({ status: "", response: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [loadContent, setLoadContent] = useState({});
  const [modal, setModal] = useState(false);
  const [replyModal, setReplyModal] = useState(false);
  const [displayReply, setDisplayReply] = useState(false);
  const [alert, setAlert] = useState(false);
  const [loadReply, setLoadReply] = useState([]);

  useEffect(() => {
    loadThisMessage();
  }, [messageRef]);

  //LOAD THIS MESSAGE
  function loadThisMessage() {
    const formData = new FormData();
    formData.append("fetchThisMessage", "Fetch");
    formData.append("messageRef", messageRef);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/messages.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setLoadContent(jsonData);
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD THIS MESSAGE

  //OPEN MODAL
  const openModal = () => {
    setModal(true);
  };
  //END OF OPEN MODAL

  //OPEN REPLY
  const openReply = () => {
    setReplyModal(true);
  };
  //END OF OPEN REPLY

  //SHOW REPLY
  const showReply = () => {
    if (displayReply === false) {
      setDisplayReply(true);
    } else {
      setDisplayReply(false);
    }
  };
  //END OF SHOW REPLY

  return (
    <>
      <Nav
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <div className='content'>
        <div className='breadCrumb'>
          <NavLink
            to={"/admin/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Dashboard
          </NavLink>{" "}
          /{" "}
          <NavLink
            to={"/admin/messages/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Messages
          </NavLink>{" "}
          /{" "}
          <NavLink
            to={"/admin/messages/" + messageRef}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            View Message
          </NavLink>
        </div>

        {loadContent.status === "error" ? (
          <Paper
            elevation={4}
            sx={{ width: "95%", margin: "0 auto", padding: "20px" }}
          >
            <h2>{loadContent.response}</h2>
          </Paper>
        ) : (
          <>
            <h1>View Message</h1>

            <Paper
              elevation={4}
              sx={{
                width: "95%",
                maxWidth: "1200px",
                margin: "0 auto",
                padding: "20px",
              }}
            >
              <div
                className='detailContainer'
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "20px",
                  padding: "0 10px",
                }}
              >
                <div className='detail'>
                  <div className='title'>From</div>
                  <div className='body'>{loadContent.name}</div>
                </div>

                <div className='detail'>
                  <div className='title'>Email</div>
                  <div className='body'>{loadContent.email}</div>
                </div>

                <div className='detail'>
                  <div className='title'>Phone Number</div>
                  <div className='body'>{loadContent.phone}</div>
                </div>
              </div>

              <h1>{loadContent.subject}</h1>

              <div className='viewDescription'>
                {loadContent.message
                  ? HTMLReactParser(loadContent.message)
                  : null}
              </div>

              <br />

              <center>
                <ButtonGroup
                  size='small'
                  sx={{
                    flexWrap: "wrap",
                    justifyContent: "center",
                    rowGap: "5px",
                  }}
                >
                  <Button onClick={() => openReply()}>Reply</Button>
                  {loadContent.reply === "yes" ? (
                    <Button onClick={() => showReply()}>Show Reply</Button>
                  ) : null}
                  <Button color='error' onClick={() => openModal()}>
                    Delete
                  </Button>
                </ButtonGroup>
              </center>

              <br />

              {displayReply ? (
                <ShowReply
                  messageRef={messageRef}
                  setModal={setModal}
                  modal={modal}
                  setAlert={setAlert}
                  rootLink={rootLink}
                  setResponse={setResponse}
                  loadReply={setLoadReply}
                />
              ) : null}
            </Paper>
          </>
        )}
      </div>

      {replyModal ? (
        <ReplyModal
          messageRef={messageRef}
          setModal={setReplyModal}
          modal={replyModal}
          setAlert={setAlert}
          rootLink={rootLink}
          setResponse={setResponse}
          loadMessage={loadThisMessage}
        />
      ) : null}

      {modal ? (
        <DeleteMessage
          messageRef={messageRef}
          setModal={setModal}
          modal={modal}
          setAlert={setAlert}
          rootLink={rootLink}
          setResponse={setResponse}
        />
      ) : null}

      <Loading1 loading={isLoading} />
    </>
  );
}

export default ViewMessage;

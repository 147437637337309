import { Routes, Route } from "react-router-dom";
import ParticipantsIndex from ".";
import ViewParticipant from "./view";

function ParticipantsRouter({ rootLink }) {
  return (
    <>
      <Routes>
        <Route path={"/"} element={<ParticipantsIndex rootLink={rootLink} />} />
        <Route
          path={"/:participantRef"}
          element={<ViewParticipant rootLink={rootLink} />}
        />
      </Routes>
    </>
  );
}

export default ParticipantsRouter;

import React from "react";
import { Routes, Route } from "react-router-dom";
import DonationIndex from ".";
import AccountDetails from "./details";
import ErrorPage from "../404";

function DonationRouter({ rootLink }) {
  return (
    <Routes>
      <Route path='/' element={<DonationIndex rootLink={rootLink} />} />
      <Route path='/account' element={<AccountDetails rootLink={rootLink} />} />
      <Route path='*' element={<ErrorPage rootLink={rootLink} />} />
    </Routes>
  );
}

export default DonationRouter;

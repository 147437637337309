import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState, useEffect } from "react";
import Loading1 from "../../Loading1";
import Editor from "../../../Editor/Editor";

function EditAnnouncement({
  modal,
  rootLink,
  setResponse,
  setModal,
  setAlert,
  loadAnnouncement,
  viewRef,
}) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [link, setLink] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadContent();
  }, []);

  //LOAD CONTENT
  const loadContent = () => {
    const formData = new FormData();
    formData.append("fetchThisAnnouncement", viewRef);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/announcement.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setTitle(jsonData.title);
      setMessage(jsonData.message);
      setLink(jsonData.link);
      setIsLoading(false);
    };
    http.send(formData);
  };
  //LOAD CONTENT

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (title === "") {
      const msg = "Subject is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (message === "") {
      const msg = "Message is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (title && message) {
      setLoadingBtn(true);

      const formData = new FormData();
      formData.append("editAnnouncement", viewRef);
      formData.append("title", title);
      formData.append("link", link);
      formData.append("message", message);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/server/announcement.php", true);
      http.onload = function () {
        setResponse(JSON.parse(this.responseText));
        setModal(false);
        setAlert(true);
        setLoadingBtn(false);
        loadAnnouncement();
      };
      http.send(formData);
    }
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal} disableEnforceFocus>
      <DialogTitle>Edit Announcement</DialogTitle>

      <DialogContent>
        <form onSubmit={formSubmit}>
          <br />
          <TextField
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            type='text'
            label='Title'
            fullWidth
          />

          <br />

          <TextField
            value={link}
            onChange={(e) => setLink(e.target.value)}
            type='url'
            label='Link'
            fullWidth
          />

          <br />

          <label htmlFor='description'>Message</label>
          <Editor value={message} setValue={setMessage} />

          <DialogActions>
            <Button
              color='error'
              variant='contained'
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loadingBtn ? true : false}
              onClick={formSubmit}
              variant='contained'
            >
              Edit
            </LoadingButton>
          </DialogActions>
        </form>

        <Loading1 loading={isLoading} />
      </DialogContent>
    </Dialog>
  );
}

export default EditAnnouncement;

import Nav from "../../../components/layout/Nav";
import { useEffect, useState } from "react";
import Loading1 from "../../../components/action/Loading1";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Paper, TextField, MenuItem, IconButton } from "@mui/material";
import EditReply from "../../../components/action/modals/replies/EditReply";
import HTMLReactParser from "html-react-parser";
import { NavLink } from "react-router-dom";

function RepliesIndex({ rootLink }) {
  const [alert, setAlert] = useState(false);
  const [response, setResponse] = useState({ status: "", response: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [causeRef, setCauseRef] = useState("");
  const [loadContent, setLoadContent] = useState([]);
  const [category, setCategory] = useState("");

  useEffect(() => {
    loadReply();
    setTimeout(() => setIsLoading(false), 2000);
  }, [category]);

  //LOAD REPLY
  function loadReply() {
    const formData = new FormData();
    formData.append("fetchReply", category);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/replies.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setLoadContent(jsonData);
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD REPLY

  //OPEN EDIT MODAL
  const openEditModal = () => {
    setEditModal(true);
  };
  //END OF OPEN EDIT MODAL

  return (
    <>
      <Nav
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <div className='content'>
        <div className='breadCrumb'>
          <NavLink
            to={"/admin/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Dashboard
          </NavLink>{" "}
          /{" "}
          <NavLink
            to={"/admin/replies/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Auto Response
          </NavLink>
        </div>

        <h1>Auto Response</h1>
        <Paper
          elevation={4}
          sx={{ width: "95%", margin: "0 auto", padding: "20px" }}
        >
          <>
            <TextField
              label='Select Category'
              onChange={(e) => {
                setCategory(e.target.value);
              }}
              select
              fullWidth
            >
              <MenuItem value='message'>Sent Message</MenuItem>
              <MenuItem value='donation'>Donation</MenuItem>
              <MenuItem value='participation'>Participation</MenuItem>
              <MenuItem value='volunteer'>Volunteer</MenuItem>
              <MenuItem value='selected_participant'>
                Selected Participant
              </MenuItem>
            </TextField>

            <br />
            {loadContent.category === "none" ? null : (
              <>
                <div>
                  <h3>{loadContent.subject}</h3>

                  {loadContent.message
                    ? HTMLReactParser(loadContent.message)
                    : null}
                </div>

                <br />

                <center>
                  <IconButton
                    onClick={openEditModal}
                    color='primary'
                    variant='contained'
                  >
                    <EditIcon />
                  </IconButton>
                </center>
              </>
            )}

            {editModal ? (
              <EditReply
                category={category}
                setModal={setEditModal}
                modal={editModal}
                rootLink={rootLink}
                setResponse={setResponse}
                setAlert={setAlert}
                loadReply={loadReply}
              />
            ) : null}
          </>

          <Loading1 loading={isLoading} />
        </Paper>
      </div>
    </>
  );
}

export default RepliesIndex;

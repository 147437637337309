import Nav from "../../../components/layout/Nav";
import { useEffect, useState } from "react";
import Loading1 from "../../../components/action/Loading1";
import { LoadingButton } from "@mui/lab";
import { TextField, Paper, Stack } from "@mui/material";
import { NavLink } from "react-router-dom";

function SocialsIndex({ rootLink }) {
  const [alert, setAlert] = useState(false);
  const [response, setResponse] = useState({ status: "", response: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [emptyContent, setEmptyContent] = useState({
    status: "",
    response: "",
  });
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [facebook, setFacebook] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [address, setAddress] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [facebookUsername, setFacebookUsername] = useState("");
  const [twitterUsername, setTwitterUsername] = useState("");
  const [instagramUsername, setInstagramUsername] = useState("");

  useEffect(() => {
    loadSocials();
  }, []);

  //LOAD CONTENTS
  function loadSocials() {
    const formData = new FormData();
    formData.append("fetchSocials", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/socials.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      if (jsonData.status === "error") {
        setEmptyContent(jsonData);
      } else {
        setPhone(jsonData[0].name);
        setEmail(jsonData[1].name);
        setFacebook(jsonData[2].link);
        setWhatsapp(jsonData[3].link);
        setTwitter(jsonData[4].link);
        setInstagram(jsonData[5].link);
        setAddress(jsonData[6].name);
        setWhatsappNumber(jsonData[3].name);
        setFacebookUsername(jsonData[2].name);
        setInstagramUsername(jsonData[5].name);
        setTwitterUsername(jsonData[4].name);
      }
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD CONTENTS

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();
    setLoadingBtn(true);

    const formData = new FormData();
    formData.append("saveSocials", "Save");
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("whatsapp", whatsapp);
    formData.append("whatsappNumber", whatsappNumber);
    formData.append("facebook", facebook);
    formData.append("facebookUsername", facebookUsername);
    formData.append("twitter", twitter);
    formData.append("twitterUsername", twitterUsername);
    formData.append("instagram", instagram);
    formData.append("instagramUsername", instagramUsername);
    formData.append("address", address);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/socials.php", true);
    http.onload = function () {
      setResponse(JSON.parse(this.responseText));
      setAlert(true);
      setLoadingBtn(false);
    };
    http.send(formData);
  };
  //END OF FORM SUBMIT

  return (
    <>
      <Nav
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <div className='content'>
        <div className='breadCrumb'>
          <NavLink
            to={"/admin/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Dashboard
          </NavLink>{" "}
          /{" "}
          <NavLink
            to={"/admin/socials/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Socials
          </NavLink>
        </div>

        <h1>Socials</h1>
        <Paper
          elevation={4}
          sx={{
            width: "95%",
            maxWidth: "500px",
            margin: "0 auto",
            padding: "20px",
          }}
        >
          {emptyContent.status === "error" ? (
            <>
              <h3>{emptyContent.response}</h3>
            </>
          ) : (
            <>
              <form onSubmit={formSubmit}>
                <TextField
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  value={phone}
                  type='number'
                  label='Phone Number'
                  fullWidth
                />

                <br />

                <TextField
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  type='email'
                  label='Email'
                  fullWidth
                />

                <br />

                <Stack direction='row' spacing={2}>
                  <TextField
                    onChange={(e) => {
                      setWhatsapp(e.target.value);
                    }}
                    value={whatsapp}
                    label='WhatsApp Link'
                  />
                  <TextField
                    onChange={(e) => {
                      setWhatsappNumber(e.target.value);
                    }}
                    value={whatsappNumber}
                    label='WhatsApp Number'
                  />
                </Stack>

                <br />

                <Stack direction='row' spacing={2}>
                  <TextField
                    onChange={(e) => {
                      setFacebook(e.target.value);
                    }}
                    value={facebook}
                    label='Facebook Link'
                  />
                  <TextField
                    onChange={(e) => {
                      setFacebookUsername(e.target.value);
                    }}
                    value={facebookUsername}
                    label='Facebook Username'
                  />
                </Stack>

                <br />

                <Stack direction='row' spacing={2}>
                  <TextField
                    onChange={(e) => {
                      setTwitter(e.target.value);
                    }}
                    value={twitter}
                    label='Twitter Link'
                  />
                  <TextField
                    onChange={(e) => {
                      setTwitterUsername(e.target.value);
                    }}
                    value={twitterUsername}
                    label='Twitter Username'
                  />
                </Stack>

                <br />

                <Stack direction='row' spacing={2}>
                  <TextField
                    onChange={(e) => {
                      setInstagram(e.target.value);
                    }}
                    value={instagram}
                    label='Instagram Link'
                  />
                  <TextField
                    onChange={(e) => {
                      setInstagramUsername(e.target.value);
                    }}
                    value={instagramUsername}
                    label='Instagram Username'
                  />
                </Stack>

                <br />

                <TextField
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  value={address}
                  label='Address'
                  fullWidth
                />

                <br />

                <LoadingButton
                  loading={loadingBtn ? true : false}
                  onClick={formSubmit}
                  variant='contained'
                >
                  Save
                </LoadingButton>
              </form>
            </>
          )}

          <Loading1 loading={isLoading} />
        </Paper>
      </div>
    </>
  );
}

export default SocialsIndex;

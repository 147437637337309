import { useEffect, useState, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Loading1 from "../../Loading1";
import Editor from "../../../Editor/Editor";

function EditWebsite({
  edit,
  modal,
  rootLink,
  setResponse,
  setModal,
  setAlert,
}) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const inputRef = useRef();

  useEffect(() => {
    loadWebsite();
  }, []);

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (edit === "banner_picture") {
      if (inputRef.current.files.length === 0) {
        const msg = "Photo is required";

        const arr = { status: "error", response: msg };
        setResponse(arr);
        setAlert(true);
      }
      if (inputRef.current.files.length > 0) {
        //Uploading of Image
        setLoadingBtn(true);
        const formData = new FormData();
        formData.append("uploadBanner", "Edit");
        formData.append("photo", inputRef.current.files[0]);

        //AJAX
        let http = new XMLHttpRequest();
        http.open("POST", rootLink + "backend/server/website.php", true);
        http.onload = function () {
          setResponse(JSON.parse(this.responseText));
          setModal(false);
          setAlert(true);
          setLoadingBtn(false);
        };
        http.send(formData);
        //END OF AJAX
      }
    }

    if (
      edit === "banner" ||
      edit === "volunteer" ||
      edit === "donation" ||
      edit === "participate"
    ) {
      //Edit Of Write Up
      setLoadingBtn(true);

      const formData = new FormData();
      formData.append("editWriteup", "Edit");
      formData.append("data", data);
      formData.append("edit", edit);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/server/website.php", true);
      http.onload = function () {
        setResponse(JSON.parse(this.responseText));
        setModal(false);
        setAlert(true);
        setLoadingBtn(false);
      };
      http.send(formData);
      //END OF AJAX
    }
  };
  //END OF FORM SUBMIT

  //LOAD WEBSITE
  function loadWebsite() {
    const formData = new FormData();
    formData.append("fetchWebsite", "Fetch");
    formData.append("edit", edit);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/website.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);

      if (edit === "banner") {
        setData(jsonData.banner);
      }
      if (edit === "volunteer") {
        setData(jsonData.volunteer);
      }
      if (edit === "donation") {
        setData(jsonData.donation);
      }
      if (edit === "participate") {
        setData(jsonData.participate);
      }

      setIsLoading(false);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD WEBSITE

  return (
    <Dialog open={modal} fullWidth>
      <DialogTitle>Edit Content</DialogTitle>

      <DialogContent>
        <form>
          <br />
          {edit === "banner_picture" ? (
            <>
              <input
                className='inputStyle'
                ref={inputRef}
                allowed='jpg, jpeg, png'
                type='file'
              />
            </>
          ) : (
            <>
              <Editor value={data} setValue={setData} />
            </>
          )}

          <br />

          <DialogActions>
            <Stack spacing={2} direction={"row"}>
              <Button
                variant='contained'
                onClick={(e) => {
                  e.preventDefault();
                  setModal(false);
                }}
                color='error'
              >
                Cancel
              </Button>
              <LoadingButton
                loading={loadingBtn ? true : false}
                variant='contained'
                onClick={formSubmit}
              >
                Edit
              </LoadingButton>
            </Stack>
          </DialogActions>
        </form>

        <Loading1 loading={isLoading} />
      </DialogContent>
    </Dialog>
  );
}

export default EditWebsite;

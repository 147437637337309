import { Link, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import Nav from "../../../components/layout/Nav";
import Loading1 from "../../../components/action/Loading1";
import ReactPaginate from "react-paginate";
import DeleteVolunteer from "../../../components/action/modals/volunteers/DeleteVolunteer";
import SearchIcon from "@mui/icons-material/Search";
import { TextField, MenuItem, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";

function VolunteerIndex({ rootLink }) {
  const [alert, setAlert] = useState(false);
  const [response, setResponse] = useState({ status: "", response: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [loadContent, setLoadContent] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [modal, setModal] = useState(false);
  const [volunteerRef, setVolunteerRef] = useState("");
  const [search, setSearch] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [cause, setCause] = useState("all");
  const [causes, setCauses] = useState([]);
  const [emptyContent, setEmptyContent] = useState({
    status: "",
    response: "",
  });

  useEffect(() => {
    loadVolunteers();
    loadCauses();
  }, [cause]);

  //PAGINATION
  const itemsPerPage = 20;
  const pagesVisited = pageNumber * itemsPerPage;

  const displayItems = loadContent
    .slice(pagesVisited, pagesVisited + itemsPerPage)
    .map((item) => {
      return (
        <div className='simpleCard' key={item.ref}>
          <Link className='head' to={"../../../admin/volunteers/" + item.ref}>
            <div>
              <div className='name'>
                {item.first_name + " " + item.last_name}
              </div>
            </div>
          </Link>
          <Link to={"../../../admin/volunteers/" + item.ref}>
            <div className='detail'>
              <div className='others'>{item.cause}</div>
            </div>
          </Link>
        </div>
      );
    });

  const pageCount = Math.ceil(loadContent.length / itemsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  //END OF PAGINATION

  //LOAD VOLUNTEERS
  function loadVolunteers() {
    const formData = new FormData();
    formData.append("fetchVolunteers", cause);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/volunteers.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      if (jsonData.status === "error") {
        setEmptyContent(jsonData);
      } else {
        setLoadContent(jsonData);
      }
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD VOLUNTEERS

  //LOAD CAUSES
  function loadCauses() {
    const formData = new FormData();
    formData.append("fetchCauses", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/causes.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setCauses(jsonData);
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD CAUSES

  //SEARCH FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();
    setEmptyContent({
      status: "",
      response: "",
    });

    if (search === "") {
      const msg = "Search field is empty";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (search) {
      setLoadingBtn(true);

      const formData = new FormData();
      formData.append("searchVolunteers", search);

      //AJAX
      let https = new XMLHttpRequest();
      https.open("POST", rootLink + "backend/server/volunteers.php", true);
      https.onload = function () {
        const jsonData = JSON.parse(this.responseText);
        if (jsonData.status === "error") {
          setEmptyContent(jsonData);
        } else {
          setLoadContent(jsonData);
        }
      };
      https.send(formData);
      //END OF AJAX
    }
  };
  //END OF SEARCH FORM SUBMIT

  //OPEN MODAL
  const openModal = (volunteer_ref) => {
    setVolunteerRef(volunteer_ref);
    setModal(true);
  };
  //END OF OPEN MODAL

  return (
    <>
      <Nav
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <div className='content'>
        <div className='breadCrumb'>
          <NavLink
            to={"/admin/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Dashboard
          </NavLink>{" "}
          /{" "}
          <NavLink
            to={"/admin/volunteers/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Volunteers
          </NavLink>
        </div>
        <h1>Volunteers</h1>

        <div className='homeContent'>
          <center>
            <div className='searchDonation'>
              <form onSubmit={formSubmit}>
                <Stack direction={"row"}>
                  <input
                    type='text'
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder='Search'
                  />
                  <LoadingButton
                    onClick={formSubmit}
                    loading={loadingBtn ? true : false}
                  >
                    <SearchIcon />
                  </LoadingButton>
                </Stack>
              </form>
            </div>
          </center>

          <br />

          <TextField
            onChange={(e) => setCause(e.target.value)}
            label='Course'
            select
            fullWidth
          >
            <MenuItem value='all'>All</MenuItem>
            {causes.map((item) => {
              return (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              );
            })}
          </TextField>

          <br />
          <br />

          {emptyContent.status === "error" ? (
            <>
              <h3>{emptyContent.response}</h3>
            </>
          ) : loadContent.length === 0 ? (
            <h3>No Volunteer</h3>
          ) : (
            <>
              <center>
                <div className='cardChildren'>
                  <div className='childItem'>
                    <div className='childHead'>Total Volunteers</div>
                    <div className='childNumber'>{loadContent.length} </div>
                  </div>
                </div>

                <div className='simpleCardContainer'>
                  <Stack
                    direction={"row"}
                    sx={{ flexWrap: "wrap", justifyContent: "center" }}
                  >
                    {displayItems}
                  </Stack>
                </div>
              </center>

              {loadContent.length < itemsPerPage + 1 ? null : (
                <div className='pagination'>
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationContainer"}
                    previousLinkClassName={"prevBtn"}
                    nextLinkClassName={"nextBtn"}
                    activeClassName={"activePagination"}
                  />
                </div>
              )}
            </>
          )}
          <Loading1 loading={isLoading} />
        </div>
      </div>
    </>
  );
}

export default VolunteerIndex;

import { useEffect, useState, forwardRef } from "react";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { TextField, Paper, Stack, Snackbar, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Loading1 from "../../components/action/Loading1";

const SnackbarAlert = forwardRef(function SnackbarAlert(props, ref) {
  return <Alert elevation={6} ref={ref} {...props} />;
});

function LoginPage({ rootLink, loggedin, setLoggedin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [saveLogin, setSaveLogin] = useState();
  const [response, setResponse] = useState({
    status: "",
    response: "",
    id: "",
    saved: "",
  });
  const [alert, setAlert] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [sideBar, setSideBar] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    checkLog();

    window.scrollTo(0, 0);
  }, [pathname]);

  //CHECK LOG STATUS
  const checkLog = () => {
    setIsLoading(true);
    const login = Cookies.get("logged");

    const data = new FormData();
    data.append("checkUserLog", login);

    const https = new XMLHttpRequest();
    https.open("POST", rootLink + "backend/server/login.php", true);
    https.onload = function () {
      const res = JSON.parse(this.responseText);
      if (res.status === "success") {
        setLoggedin(true);
      } else {
        setLoggedin(false);
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    };
    https.send(data);
  };
  //END OF CHECK LOG STAUS

  //LOG IN
  const login = (e) => {
    e.preventDefault();

    if (username === "") {
      const msg = "Username is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (password === "") {
      const msg = "Password is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (username && password) {
      setBtnLoader(true);

      let saveLogIn;

      if (saveLogin === true) {
        saveLogIn = "yes";
      } else {
        saveLogIn = "no";
      }

      const formData = new FormData();
      formData.append("logUser", "login");
      formData.append("username", username);
      formData.append("password", password);
      formData.append("save", saveLogIn);

      const ajax = new XMLHttpRequest();
      ajax.open("POST", rootLink + "backend/server/login.php", true);
      ajax.onload = function () {
        const jsonData = JSON.parse(this.responseText);

        if (jsonData.status === "success") {
          setResponse(JSON.parse(this.responseText));
          setAlert(true);
          if (jsonData.saved === "yes") {
            Cookies.set("logged", jsonData.id, { expires: 30 });
          }

          Cookies.set("logged", jsonData.id, { expires: 1 });
          setLoggedin(true);
        } else {
          setResponse(JSON.parse(this.responseText));
          setAlert(true);

          setLoggedin(false);
        }

        setBtnLoader(false);
      };
      ajax.send(formData);
    }
  };
  //END OF LOG IN

  if (response.response !== "") {
    setTimeout(() => {
      setResponse({
        status: "",
        response: "",
      });
    }, 5500);

    setTimeout(() => {
      setAlert(false);
    }, 5000);
  }

  return (
    <div style={{ marginTop: "10vh" }}>
      <Stack
        direction={"row"}
        style={{ justifyContent: "center", margin: "10px 0" }}
      >
        <div onClick={() => navigate("/")} className='adminLogo'>
          <img src='/images/assets/logo/logo1.png' />
          <div>
            <div className='siteName'>Human Telos</div>
            <div className='subName'>Initiative</div>
          </div>
        </div>
      </Stack>

      <Paper
        elevation={4}
        sx={{
          width: "95%",
          maxWidth: "600px",
          margin: "0 auto",
          padding: "20px",
        }}
      >
        <h2>Login</h2>

        <form onSubmit={login}>
          <TextField
            type='text'
            onChange={(e) => setUsername(e.target.value)}
            label='Username'
            fullWidth
          />

          <br />

          <TextField
            type='password'
            onChange={(e) => setPassword(e.target.value)}
            label='Password'
            fullWidth
          />
          <br />
          <Stack direction={"row"} spacing={3}>
            <TextField
              type='checkbox'
              onChange={(e) => setSaveLogin(e.target.checked)}
            />
            <div style={{ fontSize: "1.1rem" }}>Remember me </div>
          </Stack>
          <br />
          <LoadingButton
            loading={btnLoader ? true : false}
            variant='contained'
            onClick={login}
          >
            Log In
          </LoadingButton>
        </form>
      </Paper>
      <Snackbar
        open={alert}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <SnackbarAlert color={response.status}>
          {response.response}
        </SnackbarAlert>
      </Snackbar>

      <Loading1 loading={isLoading} />
    </div>
  );
}

export default LoginPage;

import Nav from "../../../components/layout/Nav";
import DeleteHomeMessage from "../../../components/action/modals/messages/DeleteHomeMessage";
import { useEffect, useState } from "react";
import Loading1 from "../../../components/action/Loading1";
import ReactPaginate from "react-paginate";
import DeleteIcon from "@mui/icons-material/Delete";
import { Paper, IconButton } from "@mui/material";
import { Link, NavLink } from "react-router-dom";

function MessagesIndex({ rootLink }) {
  const [alert, setAlert] = useState(false);
  const [response, setResponse] = useState({ status: "", response: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [modal, setModal] = useState(false);
  const [messageRef, setMessageRef] = useState("");
  const [loadContent, setLoadContent] = useState([]);
  const [emptyContent, setEmptyContent] = useState({
    status: "",
    response: "",
  });

  useEffect(() => {
    loadMessages();
  }, []);

  //LOAD CONTENTS
  function loadMessages() {
    const formData = new FormData();
    formData.append("fetchMessages", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/messages.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      if (jsonData.status === "error") {
        setEmptyContent(jsonData);
      } else {
        setLoadContent(jsonData);
      }
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD CONTENTS

  //PAGINATION
  const itemsPerPage = 5;
  const pagesVisited = pageNumber * itemsPerPage;

  const displayItems = loadContent
    .slice(pagesVisited, pagesVisited + itemsPerPage)
    .map((item) => {
      return (
        <div className='simpleCard message' key={item.ref}>
          <Link to={"../" + item.ref} className='head'>
            <div className={item.status === "read" ? "name unbold" : "name"}>
              {item.name}
            </div>
            <div
              className={item.status === "read" ? "subject unbold" : "subject"}
            >
              {item.subject}
            </div>

            <div className='message'>
              {item.message.length > 40
                ? item.message.slice(0, 40) + "..."
                : item.message}
            </div>
          </Link>

          <div className='detail'>
            <IconButton className='delete' onClick={() => openModal(item.ref)}>
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      );
    });

  const pageCount = Math.ceil(loadContent.length / itemsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  //END OF PAGINATION

  //OPEN MODAL
  const openModal = (messageRef) => {
    setMessageRef(messageRef);
    setModal(true);
  };
  //END OF OPEN MODAL

  return (
    <>
      <Nav
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <div className='content'>
        <div className='breadCrumb'>
          <NavLink
            to={"/admin/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Dashboard
          </NavLink>{" "}
          /{" "}
          <NavLink
            to={"/admin/messages/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Messages
          </NavLink>
        </div>
        <h1>Messages</h1>
        <Paper
          elevation={4}
          sx={{
            maxWidth: "1000px",
            width: "90%",
            margin: "0 auto",
            padding: "20px",
          }}
        >
          {emptyContent.status === "error" ? (
            <>
              <h3>{emptyContent.response}</h3>
            </>
          ) : loadContent.length === 0 ? (
            <h3>No message found</h3>
          ) : (
            <>
              <div>{displayItems}</div>

              {loadContent.length < itemsPerPage + 1 ? null : (
                <div className='pagination'>
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationContainer"}
                    previousLinkClassName={"prevBtn"}
                    nextLinkClassName={"nextBtn"}
                    activeClassName={"activePagination"}
                  />
                </div>
              )}

              {modal ? (
                <DeleteHomeMessage
                  messageRef={messageRef}
                  setModal={setModal}
                  modal={modal}
                  rootLink={rootLink}
                  setResponse={setResponse}
                  setAlert={setAlert}
                  loadMessages={loadMessages}
                />
              ) : null}
            </>
          )}

          <Loading1 loading={isLoading} />
        </Paper>
      </div>
    </>
  );
}

export default MessagesIndex;

import { Routes, Route } from "react-router-dom";
import AboutIndex from "./index";

function AboutRouter({ rootLink }) {
  return (
    <>
      <Routes>
        <Route path={"/"} element={<AboutIndex rootLink={rootLink} />} />
      </Routes>
    </>
  );
}

export default AboutRouter;

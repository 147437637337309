import { Routes, Route } from "react-router-dom";
import MessagesIndex from "./index";
import ViewMessage from "./view";

function MessagesRouter({ rootLink }) {
  return (
    <>
      <Routes>
        <Route path={"/"} element={<MessagesIndex rootLink={rootLink} />} />
        <Route
          path={"/:messageRef"}
          element={<ViewMessage rootLink={rootLink} />}
        />
      </Routes>
    </>
  );
}

export default MessagesRouter;

import React, { useState, useEffect } from "react";
import NavBar from "../../components/layout/NavBar";
import { IconButton } from "@mui/material";
import { Home } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function ErrorPage({ rootLink }) {
  const [response, setResponse] = useState({ status: "", response: "" });
  const [alert, setAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      <NavBar
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <Helmet>
        <title>HUMAN TELOS INITIATIVE</title>
        <meta name='description' content='Human Telos Initiative - Donation' />
        <meta
          name='keywords'
          content='Human Telos Initiative Donation, Human Telos Donation, Human Telos, Human Telos Initiative, Donations, Charity, Help the less privileged, Fund raising,'
        />
      </Helmet>

      <div className='container'>
        <br />
        <h1>Page not found</h1>

        <br />

        <center>
          <IconButton onClick={() => navigate("/")}>
            <Home />
          </IconButton>
        </center>
      </div>
    </>
  );
}

export default ErrorPage;

import { Routes, Route } from "react-router-dom";
import VolunteerIndex from "./index";
import ViewVolunteer from "./view";

function VolunteersRouter({ rootLink }) {
  return (
    <>
      <Routes>
        <Route path={"/"} element={<VolunteerIndex rootLink={rootLink} />} />
        <Route
          path={"/:volunteerRef"}
          element={<ViewVolunteer rootLink={rootLink} />}
        />
      </Routes>
    </>
  );
}

export default VolunteersRouter;

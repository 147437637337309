import React, { useState, useEffect } from "react";
import NavBar from "../../../components/layout/NavBar";
import Loading1 from "../../../components/action/Loading1";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Paper, IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import HTMLReactParser from "html-react-parser";
import { Helmet } from "react-helmet";

function AboutIndex({ rootLink }) {
  const [response, setResponse] = useState({ status: "", response: "" });
  const [alert, setAlert] = useState(false);
  const [about, setAbout] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    LoadAbout();
  }, []);

  //FETCH ABOUT
  const LoadAbout = () => {
    const formData = new FormData();
    formData.append("fetchAbout", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/homepage.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setAbout(jsonData);

      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF FETCH ABOUT

  return (
    <>
      <NavBar
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <Helmet>
        <title>HUMAN TELOS INITIATIVE - ABOUT US</title>
        <meta name='description' content='Human Telos Initiative - About Us' />
        <meta
          name='keywords'
          content='Human Telos Initiative About Us, Human Telos About Us, Human Telos, Human Telos Initiative, Donations, Charity, Help the less privileged, Fund raising,'
        />
      </Helmet>

      <div className='container'>
        <div className='events'>
          <h2 className='title'>About Us</h2>

          <Paper
            elevation={2}
            sx={{
              maxWidth: "1000px",
              width: "95%",
              margin: "0 auto",
              padding: "20px",
              borderRadius: "5px",
            }}
          >
            <div style={{ padding: "0 2%" }} className='aboutContent'>
              {about.about ? HTMLReactParser(about.about) : null}
            </div>

            <br />
            <IconButton onClick={() => navigate("../../")}>
              <ArrowBack />
            </IconButton>
          </Paper>
        </div>
      </div>

      <Loading1 loading={isLoading} />
    </>
  );
}

export default AboutIndex;

import { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import HTMLReactParser from "html-react-parser";

function ShowReply({
  messageRef,
  setAlert,
  modal,
  setModal,
  loadReply,
  rootLink,
  setResponse,
}) {
  const [loadContent, setLoadContent] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadReply();
  }, []);

  //LOAD THIS REPLY
  function loadReply() {
    const formData = new FormData();
    formData.append("fetchReply", "Fetch");
    formData.append("messageRef", messageRef);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/messages.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setLoadContent(jsonData);

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD REPLY

  return (
    <>
      {loading ? (
        <center>
          <Skeleton
            variant='rectangular'
            width={"80%"}
            height={"100px"}
            animation='wave'
          />
        </center>
      ) : (
        <>
          <h2>{loadContent.length > 1 ? "Replies" : "Reply"}</h2>
          <div className='replyContainer'>
            {loadContent.map((item) => {
              return (
                <div className='reply' key={item.ref}>
                  <h3>{item.subject}</h3>
                  <div className='replyBody'>
                    {item.message ? HTMLReactParser(item.message) : null}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
}

export default ShowReply;

import { useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";

function AddMedia({
  testRef,
  modal,
  rootLink,
  setResponse,
  loadTestimony,
  setModal,
  setAlert,
}) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const filesRef = useRef();

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (filesRef.current.files.length === 0) {
      const msg = "Atleast one file is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }
    if (filesRef.current.files.length > 0) {
      setLoadingBtn(true);
      const formData = new FormData();

      //load Inputs
      const files = filesRef.current.files;
      async function loadForm() {
        for (let i in files) {
          formData.append("files" + i, files[i]);
          formData.append("filesNumber", filesRef.current.files.length);
        }
      }

      loadForm();
      formData.append("addTestimonyMedia", "Add");
      formData.append("testRef", testRef);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/server/testimony.php", true);
      http.onload = function () {
        const jsonData = JSON.parse(this.responseText);
        setResponse(jsonData);
        loadTestimony();
        setModal(false);
        setAlert(true);
        setLoadingBtn(false);
      };
      http.send(formData);
      //END OF AJAX
    }
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal}>
      <DialogTitle>Add Media</DialogTitle>

      <DialogContent>
        <div className='formContainer' style={{ width: "100%" }}>
          <form>
            <label htmlFor='files'>Files</label>
            <input
              ref={filesRef}
              allowed='jpg, jpeg, png, mp4'
              type='file'
              multiple
            />
            <br />

            <Stack spacing={2} direction={"row"}>
              <Button
                variant='contained'
                color='error'
                onClick={(e) => {
                  e.preventDefault();
                  setModal(false);
                }}
              >
                Cancel
              </Button>

              <LoadingButton
                variant='contained'
                loading={loadingBtn ? true : false}
                onClick={formSubmit}
              >
                Add
              </LoadingButton>
            </Stack>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default AddMedia;

import React, { useState, useEffect } from "react";
import NavBar from "../../../components/layout/NavBar";
import Loading1 from "../../../components/action/Loading1";
import { TextField, MenuItem, Paper, IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function ParticipateIndex({ rootLink }) {
  const [response, setResponse] = useState({ status: "", response: "" });
  const [alert, setAlert] = useState(false);
  const [causes, setCauses] = useState([]);
  const [cause, setCause] = useState("");
  const [allProjects, setAllProjects] = useState([]);
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [qualification, setQualification] = useState("");
  const [qualifications, setQualifications] = useState([]);
  const [school, setSchool] = useState("");
  const [course, setCourse] = useState("");
  const [address, setAddress] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadCauses();
    loadQualifications();
    loadAllProjects();
  }, []);

  //LOAD QUALIFICATIONS
  function loadQualifications() {
    const formData = new FormData();
    formData.append("fetchQualifications", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/participate.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setQualifications(jsonData);
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD QUALIFICATIONS

  //LOAD CAUSES
  function loadCauses() {
    const formData = new FormData();
    formData.append("fetchCauses", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/participate.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setCauses(jsonData);
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD CAUSES

  //LOAD ALL PROJECTS
  function loadAllProjects() {
    const formData = new FormData();
    formData.append("fetchProjects", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/participate.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setAllProjects(jsonData);
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD ALL PROJECTS

  //LOAD PROJECTS
  function loadProjects(fetch) {
    const formData = new FormData();
    formData.append("fetchThisProjects", fetch);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/participate.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setProjects(jsonData);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD PROJECTS

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (fname === "") {
      const msg = "First Name is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }
    if (email === "") {
      const msg = "Email is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }
    if (!isEmail(email)) {
      const msg = "Email is invalid";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (phone === "") {
      const msg = "Phone number is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (phone.length < 8) {
      const msg = "Phone number is invalid";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (isNaN(phone)) {
      const msg = "Phone number is invalid";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (lname === "") {
      const msg = "Last name is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (cause === "") {
      const msg = "Course is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (project === "") {
      const msg = "Project is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (qualification === "") {
      const msg = "Qualification is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (school === "") {
      const msg = "School attended is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (course === "") {
      const msg = "Course of study is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (address === "") {
      const msg = "Address is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (
      fname &&
      lname &&
      email &&
      isEmail(email) &&
      phone &&
      !isNaN(phone) &&
      phone.length > 8 &&
      address &&
      qualification &&
      school &&
      course &&
      cause &&
      project
    ) {
      setLoadingBtn(true);

      const formData = new FormData();
      formData.append("fname", fname);
      formData.append("lname", lname);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("cause", cause);
      formData.append("project", project);
      formData.append("address", address);
      formData.append("qualification", qualification);
      formData.append("school", school);
      formData.append("course", course);
      formData.append("addParticipant", "Add");

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/client/participate.php", true);
      http.onload = function () {
        setAlert(true);
        setResponse(JSON.parse(this.responseText));
        setLoadingBtn(false);
      };
      http.send(formData);
      //END OF AJAX
    }
  };
  //END OF FORM SUBMIT

  function isEmail(email) {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  }

  return (
    <>
      <NavBar
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <Helmet>
        <title>HUMAN TELOS INITIATIVE - PARTICIPATE</title>
        <meta
          name='description'
          content='Human Telos Initiative - Participate'
        />
        <meta
          name='keywords'
          content='Human Telos Initiative Participate, Human Telos Participate, Human Telos, Human Telos Initiative, Donations, Charity, Help the less privileged, Fund raising,'
        />
      </Helmet>

      <div className='container'>
        <h2>Participate</h2>

        <Paper
          elevation={2}
          sx={{
            maxWidth: "700px",
            width: "95%",
            margin: "0 auto",
            padding: "20px",
            borderRadius: "5px",
          }}
        >
          {allProjects.length > 0 ? (
            <form onSubmit={formSubmit}>
              <br />
              <TextField
                onChange={(e) => setFname(e.target.value)}
                label='First Name'
                required
                fullWidth
              />
              <br />
              <TextField
                onChange={(e) => setLname(e.target.value)}
                label='Last Name'
                required
                fullWidth
              />
              <br />
              <TextField
                onChange={(e) => setEmail(e.target.value)}
                label='Email'
                type='email'
                required
                fullWidth
              />
              <br />
              <TextField
                onChange={(e) => setPhone(e.target.value)}
                label='Phone Number'
                type='phone'
                required
                fullWidth
              />

              <br />
              <TextField
                onChange={(e) => {
                  setCause(e.target.value);
                  loadProjects(e.target.value);
                }}
                label='Course'
                select
                fullWidth
                required
              >
                {causes.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.name}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </TextField>

              <br />
              <TextField
                onChange={(e) => {
                  setProject(e.target.value);
                }}
                label='Project'
                select
                fullWidth
                required
              >
                {projects.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.name}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </TextField>

              <br />
              <TextField
                onChange={(e) => setQualification(e.target.value)}
                label='Qualification'
                required
                fullWidth
                select
              >
                {qualifications.map((item) => {
                  return (
                    <MenuItem key={item.name} value={item.name}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </TextField>

              <br />
              <TextField
                onChange={(e) => setSchool(e.target.value)}
                label='School Attended'
                required
                fullWidth
              />

              <br />
              <TextField
                onChange={(e) => setCourse(e.target.value)}
                label='Course of Study'
                required
                fullWidth
              />

              <br />
              <TextField
                onChange={(e) => setAddress(e.target.value)}
                label='Address'
                required
                fullWidth
              />
              <br />

              <LoadingButton
                variant='contained'
                loading={loadingBtn ? true : false}
                onClick={formSubmit}
              >
                Submit
              </LoadingButton>
            </form>
          ) : (
            <h3>No Project Is Available For Participation</h3>
          )}

          <IconButton onClick={() => navigate("../../")}>
            <ArrowBack />
          </IconButton>
        </Paper>
      </div>
      <Loading1 loading={isLoading} />
    </>
  );
}

export default ParticipateIndex;

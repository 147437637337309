import { useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";

function SetAsMain({
  eventRef,
  mediaRef,
  modal,
  rootLink,
  setResponse,
  loadEvent,
  setModal,
  setAlert,
}) {
  const [loadingBtn, setLoadingBtn] = useState(false);

  //FORM SUBMIT
  const setNow = (e) => {
    e.preventDefault();
    setLoadingBtn(true);

    const formData = new FormData();
    formData.append("changeEventPhoto", "Change");
    formData.append("eventRef", eventRef);
    formData.append("mediaRef", mediaRef);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/event.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setResponse(jsonData);
      loadEvent();
      setModal(false);
      setAlert(true);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal}>
      <DialogTitle>Set As Main Picture</DialogTitle>

      <DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setModal(false);
            }}
            variant='contained'
            color='error'
          >
            Cancel
          </Button>

          <LoadingButton
            loading={loadingBtn ? true : false}
            variant='contained'
            onClick={setNow}
          >
            Set
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default SetAsMain;

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Mail,
  Phone,
  Facebook,
  WhatsApp,
  Twitter,
  Instagram,
  LocationOn,
  NotificationsActive,
  KeyboardArrowDown,
} from "@mui/icons-material";
import { Stack } from "@mui/material";

function Footer({ rootLink }) {
  const [socials, setSocials] = useState([]);
  const year = new Date();

  useEffect(() => {
    LoadSocials();
  }, []);

  //FETCH SOCIALS
  const LoadSocials = () => {
    const formData = new FormData();
    formData.append("fetchSocials", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/homepage.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setSocials(jsonData);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF FETCH SOCIALS

  return (
    <>
      <div className='footer'>
        <h4>HUMAN TELOS INITIATIVE</h4>
        <div className='footerContainer'>
          {socials.length > 0 ? (
            <>
              <div className='contactsContainer'>
                <h4>Contacts</h4>
                <div className='contacts'>
                  {socials[0].name === "" ? null : (
                    <div className='footerContacts'>
                      <div className='contactLogo'>
                        <Phone />
                      </div>
                      <div className='contactName'>
                        <a href={"tel:" + socials[0].name}>{socials[0].name}</a>
                      </div>
                    </div>
                  )}

                  {socials[1].name === "" ? null : (
                    <div className='footerContacts'>
                      <div className='contactLogo'>
                        <Mail />
                      </div>
                      <div className='contactName'>
                        <a href={"mailto:" + socials[1].name}>
                          {socials[1].name}
                        </a>
                      </div>
                    </div>
                  )}

                  {socials[3].name === "" ? null : (
                    <div className='footerContacts'>
                      <div className='contactLogo'>
                        <WhatsApp />
                      </div>
                      <div className='contactName'>
                        <a href={socials[3].link}>{socials[3].name}</a>
                      </div>
                    </div>
                  )}

                  {socials[6].name === "" ? null : (
                    <div className='footerContacts'>
                      <div className='contactLogo'>
                        <LocationOn />
                      </div>
                      <div className='contactName'>{socials[6].name}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='socialsContainer'>
                <h4>Socials</h4>
                <Stack
                  sx={{ justifyContent: "center" }}
                  direction={"row"}
                  spacing={3}
                >
                  {socials[2].name === "" ? null : (
                    <div className='footerSocial'>
                      <a href={socials[2].link}>
                        <Facebook />
                      </a>
                    </div>
                  )}

                  {socials[4].name === "" ? null : (
                    <div className='footerSocial'>
                      <a href={socials[4].link}>
                        <Twitter />
                      </a>
                    </div>
                  )}

                  {socials[5].name === "" ? null : (
                    <div className='footerSocial'>
                      <a href={socials[5].link}>
                        <Instagram />
                      </a>
                    </div>
                  )}
                </Stack>
              </div>
            </>
          ) : null}

          <div className='linksContainer'>
            <h4>Links</h4>
            <div className='links'>
              <div className='footerLink'>
                <Link to={"/events"}>Events</Link>
              </div>
              <div className='footerLink'>
                <Link to={"/donate"}>Donation</Link>
              </div>
              <div className='footerLink'>
                <Link to={"/participate"}>Participation</Link>
              </div>
              <div className='footerLink'>
                <Link to={"/volunteer"}>Volunteer</Link>
              </div>
              <div className='footerLink'>
                <Link to={"/testimonials"}>Testimonials</Link>
              </div>
              <div className='footerLink'>
                <Link to={"/sponsors"}>Sponsors</Link>
              </div>
              <div className='footerLink'>
                <Link to={"/about"}>About Us</Link>
              </div>
              <div className='footerLink'>
                <Link to={"/announcement"}>Announcement</Link>
              </div>{" "}
              <div className='footerLink'>
                <Link to={"/contact"}>Contact Us</Link>
              </div>
            </div>
          </div>
        </div>

        <br />
        <div className='footerEnd'>
          &copy; {year.getFullYear()} Human Telos Initiative || Developed by{" "}
          <a style={{ color: "blue" }} href='tel:09034339793'>
            AgeeeDev
          </a>
        </div>
        <br />
      </div>
    </>
  );
}

export default Footer;

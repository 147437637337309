import Nav from "../../../components/layout/Nav";
import { useEffect, useState } from "react";
import Loading1 from "../../../components/action/Loading1";
import { Stack, Paper } from "@mui/material";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { Add } from "@mui/icons-material";
import ReactPaginate from "react-paginate";
import { Button } from "@mui/material";

function AnnouncementIndex({ rootLink }) {
  const [alert, setAlert] = useState(false);
  const [response, setResponse] = useState({ status: "", response: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [loadContent, setLoadContent] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    loadAnnouncement();
  }, []);

  //LOAD CONTENT
  function loadAnnouncement() {
    const formData = new FormData();
    formData.append("fetchAnnouncement", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/announcement.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setLoadContent(jsonData);
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD CONTENT

  //PAGINATION
  const itemsPerPage = 10;
  const pagesVisited = pageNumber * itemsPerPage;

  const displayItems = loadContent
    .slice(pagesVisited, pagesVisited + itemsPerPage)
    .map((item) => {
      return (
        <div className='cleanList' key={item.ref}>
          <Link to={"../" + item.ref}>
            <div className='cleanCard'>{item.title}</div>
          </Link>
        </div>
      );
    });

  const pageCount = Math.ceil(loadContent.length / itemsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  //END OF PAGINATION

  return (
    <>
      <Nav
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <div className='content'>
        <div className='breadCrumb'>
          <NavLink
            to={"/admin/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Dashboard
          </NavLink>{" "}
          /{" "}
          <NavLink
            to={"/admin/announcement/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Announcements
          </NavLink>
        </div>

        <h1>Announcements</h1>
        <center>
          <Button
            startIcon={<Add />}
            variant='contained'
            onClick={() => navigate("../create")}
          >
            Add
          </Button>
        </center>
        <br />
        <Paper
          elevation={4}
          sx={{ width: "95%", margin: "0 auto", padding: "20px" }}
        >
          {loadContent.length === 0 ? (
            <h3>No Announcement</h3>
          ) : (
            <>
              <div className='cleanListContainer'>{displayItems}</div>

              {loadContent.length < itemsPerPage + 1 ? null : (
                <div className='pagination'>
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationContainer"}
                    previousLinkClassName={"prevBtn"}
                    nextLinkClassName={"nextBtn"}
                    activeClassName={"activePagination"}
                  />
                </div>
              )}
            </>
          )}
        </Paper>

        <br />
      </div>
      <Loading1 loading={isLoading} />
    </>
  );
}

export default AnnouncementIndex;

import React from "react";
import { Routes, Route } from "react-router-dom";
import EventIndex from "./index";
import ViewEvent from "./view";

function EventRouter({ rootLink }) {
  return (
    <Routes>
      <Route path='/' element={<EventIndex rootLink={rootLink} />} />
      <Route path={"/:ref"} element={<ViewEvent rootLink={rootLink} />} />
    </Routes>
  );
}

export default EventRouter;

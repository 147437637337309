import React, { useState, useEffect } from "react";
import Nav from "../../components/layout/Nav";
import { Button } from "@mui/material";
import { Home } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function ErrorPage({ rootLink }) {
  const [response, setResponse] = useState({ status: "", response: "" });
  const [alert, setAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      <Nav
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <div className='container'>
        <br />
        <h1>Page not found</h1>

        <br />

        <center>
          <Button variant='contained' onClick={() => navigate("/admin/")}>
            Dashboard
          </Button>
        </center>
      </div>
    </>
  );
}

export default ErrorPage;

import Nav from "../../../components/layout/Nav";
import { useEffect, useState } from "react";
import Loading1 from "../../../components/action/Loading1";
import ReactPaginate from "react-paginate";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Paper, Stack, IconButton } from "@mui/material";
import EditQualification from "../../../components/action/modals/qualifications/EditQualification";
import DeleteQualification from "../../../components/action/modals/qualifications/DeleteQualification";
import AddQualification from "../../../components/action/modals/qualifications/AddQualification";
import { NavLink } from "react-router-dom";

function QualificationIndex({ rootLink }) {
  const [alert, setAlert] = useState(false);
  const [response, setResponse] = useState({ status: "", response: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [qRef, setCauseRef] = useState("");
  const [loadContent, setLoadContent] = useState([]);
  const [emptyContent, setEmptyContent] = useState({
    status: "",
    response: "",
  });

  useEffect(() => {
    loadQualifications();
  }, []);

  //LOAD QUALIFICATIONS
  function loadQualifications() {
    const formData = new FormData();
    formData.append("fetchQualifications", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/qualification.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      if (jsonData.status === "error") {
        setEmptyContent(jsonData);
      } else {
        setLoadContent(jsonData);
      }
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD QUALIFICATIONS

  //PAGINATION
  const itemsPerPage = 10;
  const pagesVisited = pageNumber * itemsPerPage;

  const displayItems = loadContent
    .slice(pagesVisited, pagesVisited + itemsPerPage)
    .map((item) => {
      return (
        <div className='simpleCard' key={item.ref}>
          <div className='head'>
            <div className='name'>{item.name}</div>
          </div>

          <div className='detail'>
            <IconButton onClick={() => openEditModal(item.ref)}>
              <EditIcon color='primary' />
            </IconButton>
            <IconButton onClick={() => openDeleteModal(item.ref)}>
              <DeleteIcon color='error' />
            </IconButton>
          </div>
        </div>
      );
    });

  const pageCount = Math.ceil(loadContent.length / itemsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  //END OF PAGINATION

  //OPEN EDIT MODAL
  const openEditModal = (qRef) => {
    setCauseRef(qRef);
    setEditModal(true);
  };
  //END OF OPEN EDIT MODAL

  //OPEN DELETE MODAL
  const openDeleteModal = (qRef) => {
    setCauseRef(qRef);
    setDeleteModal(true);
  };
  //END OF OPEN DELETE MODAL

  //OPEN MODAL
  const openOpenModal = (qRef) => {
    setCauseRef(qRef);
    setOpenModal(true);
  };
  //END OF OPEN MODAL

  //OPEN ADD MODAL
  const openAddModal = (qRef) => {
    setCauseRef(qRef);
    setAddModal(true);
  };
  //END OF OPEN ADD MODAL

  return (
    <>
      <Nav
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <div className='content'>
        <div className='breadCrumb'>
          <NavLink
            to={"/admin/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Dashboard
          </NavLink>{" "}
          /{" "}
          <NavLink
            to={"/admin/qualifications/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Qualifications
          </NavLink>
        </div>

        <h1>Qualifications</h1>
        <Paper
          elevation={4}
          sx={{
            width: "95%",
            maxWidth: "1000px",
            margin: "0 auto",
            padding: "20px",
          }}
        >
          {emptyContent.status === "error" ? (
            <>
              <h3>{emptyContent.response}</h3>
            </>
          ) : (
            <>
              <center>
                <Button
                  onClick={openAddModal}
                  variant='contained'
                  startIcon={<AddIcon />}
                >
                  Add
                </Button>
              </center>

              <center>
                <div className='simpleCardContainer'>
                  <Stack
                    direction={"row"}
                    sx={{ flexWrap: "wrap", justifyContent: "center" }}
                  >
                    {displayItems}
                  </Stack>
                </div>
              </center>

              {loadContent.length < itemsPerPage + 1 ? null : (
                <div className='pagination'>
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationContainer"}
                    previousLinkClassName={"prevBtn"}
                    nextLinkClassName={"nextBtn"}
                    activeClassName={"activePagination"}
                  />
                </div>
              )}

              {deleteModal ? (
                <DeleteQualification
                  qRef={qRef}
                  setModal={setDeleteModal}
                  modal={deleteModal}
                  rootLink={rootLink}
                  setResponse={setResponse}
                  setAlert={setAlert}
                  loadQualifications={loadQualifications}
                />
              ) : null}

              {addModal ? (
                <AddQualification
                  setModal={setAddModal}
                  modal={addModal}
                  rootLink={rootLink}
                  setResponse={setResponse}
                  setAlert={setAlert}
                  loadQualifications={loadQualifications}
                />
              ) : null}

              {editModal ? (
                <EditQualification
                  qRef={qRef}
                  setModal={setEditModal}
                  modal={editModal}
                  rootLink={rootLink}
                  setResponse={setResponse}
                  setAlert={setAlert}
                  loadQualifications={loadQualifications}
                />
              ) : null}
            </>
          )}

          <Loading1 loading={isLoading} />
        </Paper>
      </div>
    </>
  );
}

export default QualificationIndex;

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState, useEffect } from "react";

function EditProject({
  projectRef,
  modal,
  rootLink,
  setResponse,
  setModal,
  setAlert,
  loadProjects,
}) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [input, setInput] = useState("");
  const [content, setContent] = useState([]);
  const [causes, setCauses] = useState([]);
  const [course, setCourse] = useState("");

  useEffect(() => {
    loadContent();
    loadCauses();
  }, []);

  //LOAD CONTENT
  const loadContent = () => {
    const formData = new FormData();
    formData.append("fetchThisProject", projectRef);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/projects.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setInput(jsonData.name);
      setCourse(jsonData.course);
    };
    http.send(formData);
  };
  //LOAD CONTENT

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (input === "") {
      const msg = "Project is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (course === "") {
      const msg = "Course is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (input && course) {
      setLoadingBtn(true);

      const formData = new FormData();
      formData.append("editProject", projectRef);
      formData.append("input", input);
      formData.append("course", course);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/server/projects.php", true);
      http.onload = function () {
        setResponse(JSON.parse(this.responseText));
        setModal(false);
        setAlert(true);
        setLoadingBtn(false);
        loadProjects();
      };
      http.send(formData);
    }
  };
  //END OF FORM SUBMIT

  //LOAD CAUSES
  function loadCauses() {
    const formData = new FormData();
    formData.append("fetchCauses", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/causes.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setCauses(jsonData);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD CAUSES

  return (
    <Dialog open={modal}>
      <DialogTitle>Edit Project</DialogTitle>

      <DialogContent>
        <div className='formContainer' style={{ width: "100%" }}>
          <form onSubmit={formSubmit}>
            <TextField
              value={input}
              onChange={(e) => setInput(e.target.value)}
              type='text'
              label='Project'
            />

            <TextField
              value={course}
              onChange={(e) => setCourse(e.target.value)}
              label='Select Course'
              fullWidth
              select
            >
              {causes.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.name}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </TextField>

            <DialogActions>
              <Button
                color='error'
                variant='contained'
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={loadingBtn ? true : false}
                onClick={formSubmit}
                variant='contained'
              >
                Edit
              </LoadingButton>
            </DialogActions>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default EditProject;

import React, { useState, useEffect } from "react";
import NavBar from "../../../components/layout/NavBar";
import Loading1 from "../../../components/action/Loading1";
import { Paper, IconButton } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import HTMLReactParser from "html-react-parser";
import { ArrowBack } from "@mui/icons-material";
import ReactPaginate from "react-paginate";
import { Helmet } from "react-helmet";

function ViewEvent({ rootLink }) {
  const [response, setResponse] = useState({ status: "", response: "" });
  const [alert, setAlert] = useState(false);
  const [event, setEvent] = useState([]);
  const [media, setMedia] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [participants, setParticipants] = useState([]);
  const [statistics, setStatistics] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const { ref } = useParams();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    LoadEvent();
    LoadEventMedia();
    loadParticipants();
    loadParticipantStatistics();
    loadSponsors();
  }, [ref]);

  //FETCH EVENT
  const LoadEvent = () => {
    const formData = new FormData();
    formData.append("fetchThisEvent", ref);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/events.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setEvent(jsonData);

      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF FETCH EVENT

  //FETCH EVENT MEDIA
  const LoadEventMedia = () => {
    const formData = new FormData();
    formData.append("fetchThisEventMedia", ref);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/events.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setMedia(jsonData);

      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF FETCH EVENT MEDIA

  //LOAD PARTICIPANTS
  const loadParticipants = () => {
    const formData = new FormData();
    formData.append("fetchEventParticipants", ref);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/events.php", true);
    http.onload = function () {
      setParticipants(JSON.parse(this.responseText));
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF LOAD PARTICIPANTS

  //PAGINATION
  const itemsPerPage = 10;
  const pagesVisited = pageNumber * itemsPerPage;

  const displayParticipants = participants
    .slice(pagesVisited, pagesVisited + itemsPerPage)
    .map((item) => {
      return (
        <div className='participantsList' key={item.ref}>
          <div className='name'>{item.first_name + " " + item.last_name}</div>
        </div>
      );
    });

  const pageCount = Math.ceil(participants.length / itemsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  //END OF PAGINATION

  //LOAD PARTICIPANTS STATISTICS
  const loadParticipantStatistics = () => {
    const formData = new FormData();
    formData.append("fetchEventParticipantStatistics", ref);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/events.php", true);
    http.onload = function () {
      setStatistics(JSON.parse(this.responseText));
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF LOAD PARTICIPANTS STATISTICS

  //LOAD SPONSORS
  const loadSponsors = () => {
    const formData = new FormData();
    formData.append("fetchEventSponsor", ref);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/sponsor.php", true);
    http.onload = function () {
      setSponsors(JSON.parse(this.responseText));
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF LOAD SPONSORS

  return (
    <>
      <NavBar
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <Helmet>
        <title>HUMAN TELOS INITIATIVE - EVENTS</title>
        <meta name='description' content='Human Telos Initiative - Events' />
        <meta
          name='keywords'
          content='Human Telos Initiative Events, Human Telos Events, Human Telos, Human Telos Initiative, Donations, Charity, Help the less privileged, Fund raising,'
        />
      </Helmet>

      <div className='container'>
        <h2>View Event</h2>
        <Paper
          elevation={2}
          sx={{
            maxWidth: "1000px",
            width: "95%",
            margin: "0 auto",
            padding: "20px",
            borderRadius: "5px",
          }}
        >
          <IconButton onClick={() => navigate("../")}>
            <ArrowBack />
          </IconButton>

          {event.length === 0 ? (
            <h2>No Event Found</h2>
          ) : event.status === "error" ? (
            <h2>{event.response}</h2>
          ) : (
            <>
              <div className='head2'>{event.event}</div>
              <center>
                <div className='subTitle'>
                  <i>{event.category ? event.category : null}</i>
                </div>
              </center>

              <br />

              <div className='description'>
                {event.description ? HTMLReactParser(event.description) : null}
              </div>

              <br />

              {media.length === 0 ? null : (
                <div className='scroller'>
                  {media.map((item) => {
                    return (
                      <>
                        <div className='scrollItem'>
                          {item.file_type === "picture" ? (
                            <a
                              href={"/images/events/" + item.name}
                              target='_blank'
                            >
                              <img
                                width={"100%"}
                                height={"100%"}
                                src={"../../images/events/" + item.name}
                              />
                            </a>
                          ) : item.file_type === "video" ? (
                            <video
                              width={"100%"}
                              height={"100%"}
                              controls
                              loading='lazy'
                            >
                              <source
                                src={"../../images/events/" + item.name}
                              />
                            </video>
                          ) : null}
                        </div>
                      </>
                    );
                  })}
                </div>
              )}
            </>
          )}

          {participants.length > 0 ? (
            <>
              <h2>
                Participant{participants.length > 1 ? "s" : null} (
                {statistics.selected})
              </h2>
              <div>{displayParticipants}</div>

              {participants.length < itemsPerPage + 1 ? null : (
                <div className='pagination'>
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationContainer"}
                    previousLinkClassName={"prevBtn"}
                    nextLinkClassName={"nextBtn"}
                    activeClassName={"activePagination"}
                  />
                </div>
              )}
            </>
          ) : null}

          <br />

          {sponsors.length > 0 ? (
            <>
              <h2>Sponsor{sponsors.length > 1 ? "s" : null}</h2>
              <div className='sponsorsContainer'>
                {sponsors.map((item) => {
                  return (
                    <div className='sponsorsCard' key={item.ref}>
                      {item.link === "" ? (
                        <>
                          <img src={"/images/assets/sponsors/" + item.logo} />
                          <div className='name'>{item.name}</div>
                        </>
                      ) : (
                        <a href={item.link}>
                          <img src={"/images/assets/sponsors/" + item.logo} />
                          <div className='name'>{item.name}</div>
                        </a>
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          ) : null}
        </Paper>
      </div>
      <Loading1 loading={isLoading} />
    </>
  );
}

export default ViewEvent;

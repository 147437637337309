import React, { useState, useEffect } from "react";
import NavBar from "../../../components/layout/NavBar";
import { Skeleton, MenuItem, Paper, IconButton } from "@mui/material";
import { Home } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ContentCopy } from "@mui/icons-material";

function AccountDetails({ rootLink }) {
  const [response, setResponse] = useState({ status: "", response: "" });
  const [alert, setAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  //COPY
  const copyNumber = () => {
    if (navigator.clipboard.writeText("5600851000")) {
      const msg = "Copied";

      const arr = { status: "success", response: msg };
      setResponse(arr);
      setAlert(true);
    } else {
      var textArea = document.getElementById("accNumber");

      textArea.setAttribute("type", "text");
      textArea.select();
      document.execCommand("copy");
      textArea.setAttribute("type", "hidden");

      const msg = "Copied";

      const arr = { status: "success", response: msg };
      setResponse(arr);
      setAlert(true);
    }
  };
  //END OF COPY

  return (
    <>
      <NavBar
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <Helmet>
        <title>HUMAN TELOS INITIATIVE - DONATION</title>
        <meta name='description' content='Human Telos Initiative - Donation' />
        <meta
          name='keywords'
          content='Human Telos Initiative Donation, Human Telos Donation, Human Telos, Human Telos Initiative, Donations, Charity, Help the less privileged, Fund raising,'
        />
      </Helmet>

      <div className='container'>
        <h2>Account Details</h2>

        <Paper
          elevation={2}
          sx={{
            maxWidth: "700px",
            width: "95%",
            margin: "0 auto",
            padding: "20px",
            borderRadius: "5px",
          }}
        >
          {isLoading === false ? (
            <div
              style={{
                fontSize: "1.05rem",
                fontWeight: "bold",
                width: "fit-content",
                margin: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  margin: "10px",
                  borderBottom: "2px solid #ddd",
                  padding: "5px",
                }}
              >
                <div style={{ color: "#777" }}>Account Name:</div>
                <div style={{ fontSize: "1.3rem" }}>Human Telos Initiative</div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  margin: "10px",
                  borderBottom: "2px solid #ddd",
                  padding: "5px",
                }}
              >
                <div style={{ color: "#777" }}>Account Number:</div>
                <div style={{ fontSize: "1.3rem" }}>5600851000</div>
                <div
                  onClick={copyNumber}
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                >
                  <ContentCopy />
                </div>

                <input
                  style={{ position: "absolute", marginLeft: "-10000px" }}
                  type='hidden'
                  id='accNumber'
                  value='5600851000'
                  readOnly
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  margin: "10px",
                  borderBottom: "2px solid #ddd",
                  padding: "5px",
                }}
              >
                <div style={{ color: "#777" }}>Bank Name:</div>
                <div style={{ fontSize: "1.3rem" }}>Fidelity Bank</div>
              </div>
            </div>
          ) : (
            <>
              <center>
                <Skeleton variant='text' animation='wave' width={200} />
                <br />
                <Skeleton
                  sx={{ margin: "0 auto" }}
                  animation='wave'
                  variant='text'
                  width={200}
                />
                <br />
                <Skeleton
                  sx={{ margin: "0 auto" }}
                  animation='wave'
                  variant='text'
                  width={200}
                />
              </center>
            </>
          )}

          <br />

          <center>
            <IconButton onClick={() => navigate("/")}>
              <Home />
            </IconButton>
          </center>
        </Paper>
      </div>
    </>
  );
}

export default AccountDetails;

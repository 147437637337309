import { Routes, Route } from "react-router-dom";
import EventsIndex from "./index";
import AddEvent from "./create";
import ViewEvent from "./view";

function EventsRouter({ rootLink }) {
  return (
    <>
      <Routes>
        <Route path={"/*"} element={<EventsIndex rootLink={rootLink} />} />
        <Route path={"/add"} element={<AddEvent rootLink={rootLink} />} />
        <Route
          path={"/:eventRef"}
          element={<ViewEvent rootLink={rootLink} />}
        />
      </Routes>
    </>
  );
}

export default EventsRouter;

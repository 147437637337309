import { Routes, Route } from "react-router-dom";
import AnnouncementIndex from "./index";
import ViewAnnouncement from "./view";
import CreateAnnouncement from "./create";
//import AnnouncementMedia from "./media";

function AnnouncementRouter({ rootLink }) {
  return (
    <>
      <Routes>
        <Route path={"/"} element={<AnnouncementIndex rootLink={rootLink} />} />
        <Route
          path={"/create"}
          element={<CreateAnnouncement rootLink={rootLink} />}
        />
        {/* <Route
          path={"/media"}
          element={<AnnouncementMedia rootLink={rootLink} />}
        /> */}

        <Route
          path={"/:viewRef"}
          element={<ViewAnnouncement rootLink={rootLink} />}
        />
      </Routes>
    </>
  );
}

export default AnnouncementRouter;

import { Routes, Route } from "react-router-dom";
import CausesIndex from "./index";
import ProjectsIndex from "./projects";

function CausesRouter({ rootLink }) {
  return (
    <>
      <Routes>
        <Route path={"/"} element={<CausesIndex rootLink={rootLink} />} />
        <Route
          path={"/:cause"}
          element={<ProjectsIndex rootLink={rootLink} />}
        />
      </Routes>
    </>
  );
}

export default CausesRouter;

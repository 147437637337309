import { useState, useRef, useEffect } from "react";
import Nav from "../../../components/layout/Nav";
import {
  TextField,
  MenuItem,
  Button,
  IconButton,
  Checkbox,
  Stack,
} from "@mui/material";
import { Delete, Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import Loading1 from "../../../components/action/Loading1";
import Editor from "../../../components/Editor/Editor";
import Sponsors from "../../../components/action/modals/events/Sponsors";
import { NavLink } from "react-router-dom";

function AddEvent({ rootLink }) {
  const [alert, setAlert] = useState(false);
  const [event, setEvent] = useState("");
  const [eventId, setEventId] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState("");
  const filesRef = useRef();
  const [response, setResponse] = useState({ status: "", response: "" });
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [causes, setCauses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sponsorModal, setSponsorModal] = useState(false);
  const [sponsors, setSponsors] = useState([]);
  const [participants, setParticipants] = useState("");

  useEffect(() => {
    loadCauses();
    generateId();
  }, []);

  //GENERATE ID
  const generateId = () => {
    const formData = new FormData();
    formData.append("generateId", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/event.php", true);
    http.onload = function () {
      setEventId(this.responseText);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF GENERATE ID

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (event === "") {
      const msg = "Event is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (description === "") {
      const msg = "Detail is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (category === "") {
      const msg = "Course is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (project === "") {
      const msg = "Project is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (filesRef.current.files.length == 0) {
      const msg = "File is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (
      event &&
      description &&
      category &&
      project &&
      filesRef.current.files.length > 0
    ) {
      setLoadingBtn(true);
      const formData = new FormData();

      //load Inputs
      const files = filesRef.current.files;
      async function loadForm() {
        for (let i in files) {
          formData.append("files" + i, files[i]);
          formData.append("filesNumber", filesRef.current.files.length);
        }
      }

      var participate = "no";

      if (participants === true) {
        participate = "yes";
      }

      if (participants === false) {
        participate = "no";
      }

      loadForm();
      formData.append("event", event);
      formData.append("category", category);
      formData.append("project", project);
      formData.append("description", description);
      formData.append("addEvent", "Add");
      formData.append("eventId", eventId);
      formData.append("participants", participate);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/server/event.php", true);
      http.onload = function () {
        const jsonData = JSON.parse(this.responseText);

        if (jsonData !== null) {
          setAlert(true);
          setResponse(jsonData);
        }
        setLoadingBtn(false);
        generateId();
      };
      http.send(formData);
      //END OF AJAX
    }
  };
  //END OF FORM SUBMIT

  //LOAD CAUSES
  function loadCauses() {
    const formData = new FormData();
    formData.append("fetchCauses", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/causes.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setCauses(jsonData);
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD CAUSES

  //LOAD SPONSORS
  const loadSponsors = () => {
    const formData = new FormData();
    formData.append("fetchEventSponsor", eventId);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/sponsor.php", true);
    http.onload = function () {
      setSponsors(JSON.parse(this.responseText));
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF LOAD SPONSORS

  //REMOVE SPONSOR
  const removeSponsor = (sponsor) => {
    const formData = new FormData();
    formData.append("removeSponsor", sponsor);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/sponsor.php", true);
    http.onload = function () {
      loadSponsors();
      setAlert(true);
      setResponse(JSON.parse(this.responseText));
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF REMOVE SPONSOR

  //LOAD PROJECTS
  function loadProjects(category) {
    const formData = new FormData();
    formData.append("fetchThisProjects", category);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/participate.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setProjects(jsonData);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD PROJECTS

  //RESET PAGE
  if (loadingBtn) {
    setTimeout(() => {
      window.location.reload();
    }, 240000);

    setTimeout(() => {
      const msg = "Request is taking too long";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }, 235000);
  }
  //END OF RESET PAGE

  return (
    <>
      <Nav
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <div className='content'>
        <div className='breadCrumb'>
          <NavLink
            to={"/admin/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Dashboard
          </NavLink>{" "}
          /{" "}
          <NavLink
            to={"/admin/events/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Events
          </NavLink>{" "}
          /{" "}
          <NavLink
            to={"/admin/events/add"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Add Event
          </NavLink>
        </div>

        <h1>Add Event</h1>

        <div className='formContainer'>
          <form>
            <TextField
              label='Event'
              onChange={(e) => setEvent(e.target.value)}
              required
            />
            <TextField
              onChange={(e) => {
                setCategory(e.target.value);
                loadProjects(e.target.value);
              }}
              label='Course'
              fullWidth
              select
              required
            >
              {causes.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.name}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </TextField>

            <TextField
              onChange={(e) => setProject(e.target.value)}
              label='Project'
              fullWidth
              select
              required
            >
              {projects.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.name}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </TextField>

            <label htmlFor='files'>Files</label>
            <input
              ref={filesRef}
              allowed='jpg, jpeg, png, mp4'
              type='file'
              multiple
              required
            />
            <label htmlFor='description'>Details</label>
            <Editor value='' setValue={setDescription} />
            <br />
            {category ? (
              <Stack
                direction={"row"}
                sx={{ alignItems: "center", fontSize: "1.1rem" }}
              >
                <Checkbox onChange={(e) => setParticipants(e.target.checked)} />
                Show Participants
              </Stack>
            ) : null}

            <br />
            <br />
            <Button size='small' onClick={() => setSponsorModal(true)}>
              Add Sponsor
            </Button>
            {sponsors.length > 0 ? (
              <>
                <h2>Sponsor{sponsors.length > 1 ? "s" : null}</h2>
                <div className='sponsorContainer'>
                  {sponsors.map((item) => {
                    return (
                      <div className='sponsorCard' key={item.ref}>
                        <img
                          src={"/images/assets/sponsors/" + item.logo}
                          alt={item.name}
                        />
                        <div className='text'>
                          <div className='name'>{item.name}</div>
                          <IconButton onClick={() => removeSponsor(item.ref)}>
                            <Delete color='error' />
                          </IconButton>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : null}
            <br />
            <LoadingButton
              variant='contained'
              onClick={formSubmit}
              loading={loadingBtn ? true : false}
              startIcon={<Save />}
            >
              Add
            </LoadingButton>
          </form>
        </div>

        <br />
      </div>

      {sponsorModal ? (
        <Sponsors
          eventId={eventId}
          modal={sponsorModal}
          setModal={setSponsorModal}
          setResponse={setResponse}
          setAlert={setAlert}
          rootLink={rootLink}
          loadSponsorContent={loadSponsors}
        />
      ) : null}

      <Loading1 loading={isLoading} />
    </>
  );
}

export default AddEvent;

import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Loading1 from "../../Loading1";
import Editor from "../../../Editor/Editor";

function EditModal({
  eventRef,
  modal,
  rootLink,
  setResponse,
  loadEvent,
  setModal,
  setAlert,
}) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loadContent, setLoadContent] = useState({});
  const [event, setEvent] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [causes, setCauses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadThisEvent();
    loadCauses();
  }, []);

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (event === "") {
      const msg = "Event is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (description === "") {
      const msg = "Detail is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (event && description) {
      setLoadingBtn(true);
      const formData = new FormData();
      formData.append("event", event);
      formData.append("category", category);
      formData.append("description", description);
      formData.append("eventRef", eventRef);
      formData.append("editEventContent", "Edit");

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/server/event.php", true);
      http.onload = function () {
        setResponse(JSON.parse(this.responseText));
        loadEvent();
        setModal(false);
        setAlert(true);
        setLoadingBtn(false);
      };
      http.send(formData);
      //END OF AJAX
    }
  };
  //END OF FORM SUBMIT

  //LOAD CAUSES
  function loadCauses() {
    const formData = new FormData();
    formData.append("fetchCauses", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/causes.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setCauses(jsonData);
      setIsLoading(false);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD CAUSES

  //LOAD THIS TESTIMONY
  function loadThisEvent() {
    const formData = new FormData();
    formData.append("fetchThisEvent", "Fetch");
    formData.append("eventRef", eventRef);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/event.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setEvent(jsonData.event);
      setDescription(jsonData.description);
      setCategory(jsonData.category);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD THIS TESTIMONY

  return (
    <Dialog open={modal} fullWidth>
      <DialogTitle>Edit Event</DialogTitle>

      <DialogContent>
        <form>
          <br />
          <TextField
            label='Event'
            value={event}
            onChange={(e) => setEvent(e.target.value)}
            fullWidth
          />

          <br />

          <TextField
            label='Select Category'
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            select
            fullWidth
          >
            {causes.map((item) => {
              return (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              );
            })}
          </TextField>

          <label htmlFor='description'>Detail</label>

          <Editor value={description} setValue={setDescription} />

          <br />
          <DialogActions>
            <Stack spacing={2} direction={"row"}>
              <Button
                variant='contained'
                onClick={(e) => {
                  e.preventDefault();
                  setModal(false);
                }}
                color='error'
              >
                Cancel
              </Button>
              <LoadingButton
                loading={loadingBtn ? true : false}
                variant='contained'
                onClick={formSubmit}
              >
                Edit
              </LoadingButton>
            </Stack>
          </DialogActions>
        </form>
        <Loading1 loading={isLoading} />
      </DialogContent>
    </Dialog>
  );
}

export default EditModal;

import { Routes, Route } from "react-router-dom";
import DonationIndex from "./index";
import ViewDonation from "./view";
import PendingDonationIndex from "./pending";
import FailedDonationIndex from "./failed";
import SuccessfulDontationsIndex from "./successful";

function DonationsRouter({ rootLink }) {
  return (
    <>
      <Routes>
        <Route path={"/all"} element={<DonationIndex rootLink={rootLink} />} />
        <Route
          path={"/pending"}
          element={<PendingDonationIndex rootLink={rootLink} />}
        />
        <Route
          path={"/failed"}
          element={<FailedDonationIndex rootLink={rootLink} />}
        />
        <Route
          path={"/successful"}
          element={<SuccessfulDontationsIndex rootLink={rootLink} />}
        />
        <Route
          path={"/all/:donateRef"}
          element={<ViewDonation rootLink={rootLink} />}
        />
        <Route
          path={"/pending/:donateRef"}
          element={<ViewDonation rootLink={rootLink} />}
        />
        <Route
          path={"/failed/:donateRef"}
          element={<ViewDonation rootLink={rootLink} />}
        />
        <Route
          path={"/successful/:donateRef"}
          element={<ViewDonation rootLink={rootLink} />}
        />
      </Routes>
    </>
  );
}

export default DonationsRouter;

import { Routes, Route } from "react-router-dom";
import SponsorsIndex from "./index";

function SponsorsRouter({ rootLink }) {
  return (
    <>
      <Routes>
        <Route path={"/"} element={<SponsorsIndex rootLink={rootLink} />} />
      </Routes>
    </>
  );
}

export default SponsorsRouter;

import Nav from "../../../components/layout/Nav";
import { useEffect, useState } from "react";
import Loading1 from "../../../components/action/Loading1";
import EditIcon from "@mui/icons-material/Edit";
import { useParams, NavLink } from "react-router-dom";
import { Stack, Paper, IconButton } from "@mui/material";
import EditAnnouncement from "../../../components/action/modals/announcement/EditAnnouncement";
import ClearAnnouncement from "../../../components/action/modals/announcement/ClearAnnouncement";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import HTMLReactParser from "html-react-parser";

function ViewAnnouncement({ rootLink }) {
  const [alert, setAlert] = useState(false);
  const [response, setResponse] = useState({ status: "", response: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [clearModal, setClearModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [loadContent, setLoadContent] = useState([]);
  const { viewRef } = useParams();

  useEffect(() => {
    loadAnnouncement();
  }, [viewRef]);

  //LOAD REPLY
  function loadAnnouncement() {
    const formData = new FormData();
    formData.append("fetchThisAnnouncement", viewRef);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/announcement.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setLoadContent(jsonData);
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD REPLY

  //OPEN EDIT MODAL
  const openEditModal = () => {
    setEditModal(true);
  };
  //END OF OPEN EDIT MODAL

  //OPEN CLEAR MODAL
  const openClearModal = () => {
    setClearModal(true);
  };
  //END OF OPEN CLEAR MODAL

  return (
    <>
      <Nav
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <div className='content'>
        {loadContent.status === "error" ? (
          <>
            <div className='breadCrumb'>
              <NavLink
                to={"/admin/"}
                className={(navData) =>
                  navData.isActive ? "item active" : "item"
                }
              >
                Dashboard
              </NavLink>{" "}
              /{" "}
              <NavLink
                to={"/admin/announcement/"}
                className={(navData) =>
                  navData.isActive ? "item active" : "item"
                }
              >
                Announcements
              </NavLink>{" "}
              /{" "}
              <NavLink
                to={"/admin/announcement/" + viewRef}
                className={(navData) =>
                  navData.isActive ? "item active" : "item"
                }
              >
                View Announcement
              </NavLink>
            </div>
            <Paper
              elevation={4}
              sx={{ width: "95%", margin: "0 auto", padding: "20px" }}
            >
              <h2>{loadContent.response}</h2>
            </Paper>
          </>
        ) : (
          <>
            <div className='breadCrumb'>
              <NavLink
                to={"/admin/"}
                className={(navData) =>
                  navData.isActive ? "item active" : "item"
                }
              >
                Dashboard
              </NavLink>{" "}
              /{" "}
              <NavLink
                to={"/admin/announcement/"}
                className={(navData) =>
                  navData.isActive ? "item active" : "item"
                }
              >
                Announcements
              </NavLink>{" "}
              /{" "}
              <NavLink
                to={"/admin/announcement/" + loadContent.ref}
                className={(navData) =>
                  navData.isActive ? "item active" : "item"
                }
              >
                {loadContent.title}
              </NavLink>
            </div>
            <h1>{loadContent.title}</h1>

            <Paper
              elevation={4}
              sx={{ width: "95%", margin: "0 auto", padding: "20px" }}
            >
              <>
                {loadContent.category === "none" ? null : (
                  <>
                    <div>
                      {loadContent.message
                        ? HTMLReactParser(loadContent.message)
                        : null}
                    </div>

                    <br />
                    {loadContent.link === "" ? null : (
                      <div style={{ fontSize: "1.1rem" }}>
                        <b>Link:</b> {loadContent.link}
                      </div>
                    )}

                    <Stack
                      sx={{ justifyContent: "center", margin: "10px" }}
                      spacing={2}
                      direction='row'
                    >
                      <IconButton
                        onClick={openEditModal}
                        color='primary'
                        variant='contained'
                      >
                        <EditIcon />
                      </IconButton>

                      <IconButton
                        onClick={openClearModal}
                        color='error'
                        variant='contained'
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  </>
                )}

                {editModal ? (
                  <EditAnnouncement
                    viewRef={viewRef}
                    setModal={setEditModal}
                    modal={editModal}
                    rootLink={rootLink}
                    setResponse={setResponse}
                    setAlert={setAlert}
                    loadAnnouncement={loadAnnouncement}
                  />
                ) : null}

                {clearModal ? (
                  <ClearAnnouncement
                    viewRef={viewRef}
                    setModal={setClearModal}
                    modal={clearModal}
                    rootLink={rootLink}
                    setResponse={setResponse}
                    setAlert={setAlert}
                    loadAnnouncement={loadAnnouncement}
                  />
                ) : null}
              </>
            </Paper>
          </>
        )}
      </div>
      <Loading1 loading={isLoading} />
    </>
  );
}

export default ViewAnnouncement;

import { Routes, Route } from "react-router-dom";
import RepliesIndex from "./index";

function RepliesRouter({ rootLink }) {
  return (
    <>
      <Routes>
        <Route path={"/"} element={<RepliesIndex rootLink={rootLink} />} />
      </Routes>
    </>
  );
}

export default RepliesRouter;

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";

function AddCause({
  modal,
  rootLink,
  setResponse,
  setModal,
  setAlert,
  loadCauses,
}) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [input, setInput] = useState("");

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (input === "") {
      const msg = "Course can not be empty";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (input) {
      setLoadingBtn(true);

      const formData = new FormData();
      formData.append("addCause", "Add");
      formData.append("input", input);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/server/causes.php", true);
      http.onload = function () {
        setResponse(JSON.parse(this.responseText));
        setModal(false);
        setAlert(true);
        setLoadingBtn(false);
        loadCauses();
      };
      http.send(formData);
    }
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal}>
      <DialogTitle>Add Course</DialogTitle>

      <DialogContent>
        <div className='formContainer' style={{ width: "100%" }}>
          <form onSubmit={formSubmit}>
            <TextField
              onChange={(e) => setInput(e.target.value)}
              type='text'
              label='Course'
              required
            />

            <DialogActions>
              <Button
                color='error'
                variant='contained'
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={loadingBtn ? true : false}
                onClick={formSubmit}
                variant='contained'
              >
                Add
              </LoadingButton>
            </DialogActions>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default AddCause;

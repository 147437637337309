import { Button, TextField, Paper } from "@mui/material";
import {
  Mail,
  Phone,
  Facebook,
  WhatsApp,
  Twitter,
  Instagram,
  LocationOn,
  NotificationsActive,
  KeyboardArrowDown,
} from "@mui/icons-material";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loading1 from "../../components/action/Loading1";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectCoverflow } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import HTMLReactParser from "html-react-parser";
import SendMessage from "../../components/action/features/SendMessage";
import NavBar from "../../components/layout/NavBar";
import { Helmet } from "react-helmet";

function Home({ rootLink }) {
  const [writeup, setWriteup] = useState([]);
  const [bannerImg, setBannerImg] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [events, setEvents] = useState([]);
  const [testimonies, setTestimonies] = useState([]);
  const [about, setAbout] = useState([]);
  const [announcement, setAnnouncement] = useState([]);
  const [socials, setSocials] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [stat, setStat] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [response, setResponse] = useState({ status: "", response: "" });
  const [alert, setAlert] = useState(false);
  const [announce, setAnnounce] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    LoadWriteup();
    LoadBannerImage();
    LoadGallery();
    LoadEvents();
    LoadTestimonies();
    LoadAbout();
    LoadSocials();
    LoadStatistic();
    loadSponsors();
    loadAnnouncement();
  }, []);

  //FETCH WRITE UP
  const LoadWriteup = () => {
    const formData = new FormData();
    formData.append("fetchWriteUp", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/homepage.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setWriteup(jsonData);

      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF FETCH WRITE UP

  //FETCH BANNER IMAGE
  const LoadBannerImage = () => {
    const formData = new FormData();
    formData.append("fetchBannerImage", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/homepage.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setBannerImg(jsonData);

      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF FETCH BANNER IMAGE

  //FETCH GALLERY
  const LoadGallery = () => {
    const formData = new FormData();
    formData.append("fetchGallery", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/homepage.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setGallery(jsonData);

      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF FETCH GALLERY

  //FETCH EVENTS
  const LoadEvents = () => {
    const formData = new FormData();
    formData.append("fetchEvents", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/homepage.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setEvents(jsonData);

      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF FETCH EVENTS

  //FETCH TESTIMONIES
  const LoadTestimonies = () => {
    const formData = new FormData();
    formData.append("fetchTestimonies", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/homepage.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setTestimonies(jsonData);

      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF FETCH TESTIMONIES

  //FETCH ABOUT
  const LoadAbout = () => {
    const formData = new FormData();
    formData.append("fetchAbout", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/homepage.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setAbout(jsonData);

      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF FETCH ABOUT

  //FETCH ANNOUNCEMENT
  const loadAnnouncement = () => {
    const formData = new FormData();
    formData.append("fetchAnnouncement", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/homepage.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setAnnouncement(jsonData);

      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF FETCH ANNOUNCEMENT

  //FETCH SOCIALS
  const LoadSocials = () => {
    const formData = new FormData();
    formData.append("fetchSocials", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/homepage.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setSocials(jsonData);

      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF FETCH SOCIALS

  //FETCH STATISTICS
  const LoadStatistic = () => {
    const formData = new FormData();
    formData.append("fetchStatistic", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/homepage.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setStat(jsonData);

      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF FETCH STATISTICS

  //LOAD SPONSORS
  function loadSponsors() {
    const formData = new FormData();
    formData.append("fetchSponsors", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/sponsors.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setSponsors(jsonData);
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD SPONSORS

  //SCROLL TO GALLERY
  const goDown = () => {
    const serviceSection = document.getElementById("gallery");
    serviceSection.scrollIntoView();
  };
  //END OF SCROLL TO GALLERY

  return (
    <>
      <NavBar
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <Helmet>
        <meta name='description' content='Human Telos Initiative - Home Page' />
        <meta
          name='keywords'
          content='Human Telos Initiative Home Page, Human Telos Home Page, Human Telos, Human Telos Initiative, Donations, Charity, Help the less privileged, Fund raising,'
        />
      </Helmet>

      <div className='banner'>
        <div className='bannerHead'>
          {writeup.banner ? HTMLReactParser(writeup.banner) : null}
        </div>
        {writeup.banner ? (
          <div onClick={goDown} className='bannerBtn'>
            <KeyboardArrowDown size='large' />
          </div>
        ) : null}
        <div className='bannerImage'>
          {bannerImg !== null ? (
            <img
              src={"/images/assets/banner/" + writeup.banner_picture}
              alt=''
            />
          ) : null}
        </div>
      </div>

      {gallery.length === 0 ? null : (
        <div id='gallery' className='gallery'>
          <h2 className='title'>Gallery</h2>
          <div className='galleryContent'>
            <center>
              <Swiper
                modules={[Pagination, Navigation, Autoplay]}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                grabCursor={true}
                centeredSlides={true}
                speed={1000}
                slidesPerView={2}
                breakpoints={{
                  400: {
                    slidesPerView: 2,
                  },
                  680: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 1,
                  },
                  800: {
                    slidesPerView: 2,
                    spaceBetween: 1,
                  },
                  860: {
                    slidesPerView: 2,
                  },

                  920: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 2,
                  },
                  1100: {
                    slidesPerView: 2,
                  },
                  1240: {
                    slidesPerView: 4,
                  },
                }}
                loop={true}
              >
                {gallery.map((item) => {
                  return (
                    <SwiperSlide
                      className={({ isActive }) =>
                        isActive ? "galleryCard active" : "galleryCard"
                      }
                      key={item.ref}
                    >
                      <Link to={"/events/" + item.event_ref}>
                        <div>
                          <img src={"/images/events/" + item.name} />
                        </div>
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </center>
          </div>
        </div>
      )}

      <div className='volunteer'>
        <h2 className='title'>Volunteer</h2>
        <div className='volunteerText'>
          {writeup.volunteer ? HTMLReactParser(writeup.volunteer) : null}
        </div>
        <div className='vipBtn' onClick={() => navigate("/volunteer")}>
          Volunteer
        </div>
      </div>

      {events.length === 0 ? null : (
        <div className='events'>
          <h2 className='title'>Events</h2>
          <div className='eventSlider'>
            {events.map((item) => {
              return (
                <Link key={item.ref} to={"/events/" + item.ref}>
                  <div className='eventCard'>
                    <img src={"/images/events/" + item.picture} />
                    <div className='name space'>{item.event}</div>
                  </div>
                </Link>
              );
            })}
          </div>
          <center>
            <Button onClick={() => navigate("/events")}>View More</Button>
          </center>
        </div>
      )}

      <div className='donations'>
        <h2 className='title'>Donation</h2>
        <div className='donationText'>
          {writeup.donation ? HTMLReactParser(writeup.donation) : null}
        </div>
        <div className='vipBtn' onClick={() => navigate("/donate")}>
          Donate
        </div>
      </div>

      <div className='donations'>
        <h2 className='title'>Participate</h2>
        <div className='donationText'>
          {writeup.participate ? HTMLReactParser(writeup.participate) : null}
        </div>
        <div className='vipBtn' onClick={() => navigate("/participate")}>
          Participate
        </div>
      </div>

      {testimonies.length === 0 ? null : (
        <div className='testimonies'>
          <h2 className='title'>
            <Link to={"/testimonials"}>Testimonials</Link>
          </h2>
          <div className='testimonyCardContainer'>
            {testimonies.map((item) => {
              return (
                <Link key={item.test_ref} to={"/testimonials/" + item.test_ref}>
                  <div className='testimonyCard'>
                    <div className='testimonyBody'>
                      {item.description
                        ? HTMLReactParser(item.description.slice(0, 250))
                        : null}
                    </div>

                    <div className='testimonyHead'>
                      <img
                        src={"/images/testimony/photo/" + item.photo}
                        alt={item.photo}
                      />
                      <div className='name'>{item.name}</div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      )}

      {about === null ? null : (
        <div className='aboutUs'>
          <h2 className='title'>About Us</h2>
          <div className='aboutContent'>
            {about.about ? HTMLReactParser(about.about) : null}
          </div>
        </div>
      )}

      <div className='contact'>
        <h2 className='title'>Contact Us</h2>
        <Paper
          elevation={4}
          sx={{ width: "90%", margin: "auto", padding: "0 10px 20px 10px" }}
        >
          <div className='contactContent'>
            <div className='socials'>
              {socials.length > 0 ? (
                <>
                  <h3>Socials</h3>

                  {socials[0].name === "" ? null : (
                    <div className='social'>
                      <div className='platform'>
                        <Phone />
                      </div>
                      <div className='platformName'>
                        <a href={"tel:" + socials[0].name}>{socials[0].name}</a>
                      </div>
                    </div>
                  )}

                  {socials[1].name === "" ? null : (
                    <div className='social'>
                      <div className='platform'>
                        <Mail />
                      </div>
                      <div className='platformName'>
                        <a href={"mailto:" + socials[1].name}>
                          {socials[1].name}
                        </a>
                      </div>
                    </div>
                  )}

                  {socials[2].name === "" ? null : (
                    <div className='social'>
                      <div className='platform'>
                        <Facebook />
                      </div>
                      <div className='platformName'>
                        <a href={socials[2].link}>{socials[2].name}</a>
                      </div>
                    </div>
                  )}

                  {socials[3].name === "" ? null : (
                    <div className='social'>
                      <div className='platform'>
                        <WhatsApp />
                      </div>
                      <div className='platformName'>
                        <a href={socials[3].link}>{socials[3].name}</a>
                      </div>
                    </div>
                  )}

                  {socials[4].name === "" ? null : (
                    <div className='social'>
                      <div className='platform'>
                        <Twitter />
                      </div>
                      <div className='platformName'>
                        <a href={socials[4].link}>{socials[4].name}</a>
                      </div>
                    </div>
                  )}

                  {socials[5].name === "" ? null : (
                    <div className='social'>
                      <div className='platform'>
                        <Instagram />
                      </div>
                      <div className='platformName'>
                        <a href={socials[5].link}>{socials[5].name}</a>
                      </div>
                    </div>
                  )}

                  {socials[6].name === "" ? null : (
                    <div className='social'>
                      <div className='platform'>
                        <LocationOn />
                      </div>
                      <div className='platformName'>{socials[6].name}</div>
                    </div>
                  )}
                </>
              ) : null}
            </div>

            <div className='message'>
              <h3>Send Us A Message</h3>
              <SendMessage
                rootLink={rootLink}
                setAlert={setAlert}
                setResponse={setResponse}
              />
            </div>
          </div>
        </Paper>
      </div>

      {sponsors.length > 0 ? (
        <div className='sponsors'>
          <h2 className='title'>
            <Link to={"/sponsors"}>Sponsors</Link>
          </h2>
          <div className='sponsorsContainer'>
            {sponsors.map((item) => {
              return (
                <div className='sponsorsCard' key={item.ref}>
                  {item.link === "" ? (
                    <>
                      <img src={"/images/assets/sponsors/" + item.logo} />
                      <div className='name'>{item.name}</div>
                    </>
                  ) : (
                    <a href={item.link}>
                      <img src={"/images/assets/sponsors/" + item.logo} />
                      <div className='name'>{item.name}</div>
                    </a>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      {announcement.length === 0 ? null : (
        <div onClick={() => navigate("/announcement")} className='announce'>
          <NotificationsActive />
        </div>
      )}

      <Loading1 loading={isLoading} />
    </>
  );
}

export default Home;

import { Routes, Route } from "react-router-dom";
import AnnouncementIndex from "./index";
import ViewAnnouncement from "./view";
import ErrorPage from "../404";

function AnnouncementRouter({ rootLink }) {
  return (
    <>
      <Routes>
        <Route path={"/"} element={<AnnouncementIndex rootLink={rootLink} />} />

        <Route
          path={"/:viewRef"}
          element={<ViewAnnouncement rootLink={rootLink} />}
        />
        <Route path='*' element={<ErrorPage rootLink={rootLink} />} />
      </Routes>
    </>
  );
}

export default AnnouncementRouter;

import { Routes, Route } from "react-router-dom";
import Testimony from "./index";
import PendingTestimony from "./pending";
import DeclinedTestimony from "./declined";
import AddTestimony from "./create";
import ViewTestimony from "./view";

function TestimoniesRouter({ rootLink }) {
  return (
    <>
      <Routes>
        <Route
          path={"/create"}
          element={<AddTestimony rootLink={rootLink} />}
        />

        <Route
          path={"/published"}
          element={<Testimony rootLink={rootLink} />}
        />
        <Route
          path={"/pending"}
          element={<PendingTestimony rootLink={rootLink} />}
        />
        <Route
          path={"/declined"}
          element={<DeclinedTestimony rootLink={rootLink} />}
        />
        <Route
          path={"/published/:testRef"}
          element={<ViewTestimony rootLink={rootLink} />}
        />
        <Route
          path={"/pending/:testRef"}
          element={<ViewTestimony rootLink={rootLink} />}
        />
        <Route
          path={"/declined/:testRef"}
          element={<ViewTestimony rootLink={rootLink} />}
        />
      </Routes>
    </>
  );
}

export default TestimoniesRouter;

import { Routes, Route, useLocation } from "react-router-dom";
import { useLayoutEffect } from "react";
import Home from "./index";
import EventRouter from "./events/router";
import TestimoniesRouter from "./testimonies/router";
import DonationRouter from "./donation/router";
import VolunteerRouter from "./volunteer/router";
import ContactRouter from "./contact/router";
import AboutRouter from "./about/router";
import ParticipateRouter from "./participate/router";
import Footer from "./footer";
import SponsorsRouter from "./sponsors/router";
import PaymentRouter from "./payment/router";
import AnnouncementRouter from "./announcement/router";
import ErrorPage from "./404";

function HomeRouter({ rootLink }) {
  const { pathname } = useLocation();

  useLayoutEffect(
    function () {
      window.scrollTo(0, 0);
    },
    [pathname]
  );
  return (
    <>
      <Routes>
        <Route path={"/"} element={<Home rootLink={rootLink} />} />
        <Route
          path={"/events/*"}
          element={<EventRouter rootLink={rootLink} />}
        />
        <Route
          path={"/testimonials/*"}
          element={<TestimoniesRouter rootLink={rootLink} />}
        />

        {/* PAYSTACK <Route
          path={"/payment/*"}
          element={<PaymentRouter rootLink={rootLink} />}
        /> */}

        <Route
          path={"/donate/*"}
          element={<DonationRouter rootLink={rootLink} />}
        />
        <Route
          path={"/announcement/*"}
          element={<AnnouncementRouter rootLink={rootLink} />}
        />

        <Route
          path={"/volunteer/*"}
          element={<VolunteerRouter rootLink={rootLink} />}
        />
        <Route
          path={"/contact"}
          element={<ContactRouter rootLink={rootLink} />}
        />
        <Route
          path={"/participate"}
          element={<ParticipateRouter rootLink={rootLink} />}
        />
        <Route
          path={"/sponsors"}
          element={<SponsorsRouter rootLink={rootLink} />}
        />

        <Route path={"/about"} element={<AboutRouter rootLink={rootLink} />} />

        <Route path={"*"} element={<ErrorPage rootLink={rootLink} />} />
      </Routes>
      <Footer rootLink={rootLink} />
    </>
  );
}

export default HomeRouter;

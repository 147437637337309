import Nav from "../../../components/layout/Nav";
import { useEffect, useState } from "react";
import Loading1 from "../../../components/action/Loading1";
import { LoadingButton } from "@mui/lab";
import { TextField, Button, Paper, Stack, MenuItem } from "@mui/material";
import EditWebsite from "../../../components/action/modals/website/EditWebsite";
import { NavLink } from "react-router-dom";

function WebsiteIndex({ rootLink }) {
  const [alert, setAlert] = useState(false);
  const [response, setResponse] = useState({ status: "", response: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [edit, setEdit] = useState("");
  const [modal, setModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  //OPEN MODAL
  const openModal = () => {
    if (edit === "") {
      const msg = "Please select an option";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    } else {
      setModal(true);
    }
  };
  //END OF OPEN MODAL
  return (
    <>
      <Nav
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <div className='content'>
        <div className='breadCrumb'>
          <NavLink
            to={"/admin/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Dashboard
          </NavLink>{" "}
          /{" "}
          <NavLink
            to={"/admin/website/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Edit Website Content
          </NavLink>
        </div>

        <h1>Edit Website Content</h1>
        <Paper
          elevation={4}
          sx={{
            width: "95%",
            maxWidth: "500px",
            margin: "0 auto",
            padding: "20px",
          }}
        >
          <TextField
            onChange={(e) => setEdit(e.target.value)}
            label='Select what you want to edit'
            select
            fullWidth
          >
            <MenuItem value='banner'>Welcome Note</MenuItem>
            <MenuItem value='banner_picture'>Background Image</MenuItem>
            <MenuItem value='volunteer'>Volunteer Note</MenuItem>
            <MenuItem value='donation'>Donation Note</MenuItem>
            <MenuItem value='participate'>Participation Note</MenuItem>
          </TextField>
          <br />
          <br />

          <center>
            <Button onClick={openModal} variant='contained'>
              Edit
            </Button>
          </center>
          <Loading1 loading={isLoading} />
        </Paper>
      </div>

      {modal ? (
        <EditWebsite
          edit={edit}
          setModal={setModal}
          modal={modal}
          rootLink={rootLink}
          setAlert={setAlert}
          setResponse={setResponse}
        />
      ) : null}
    </>
  );
}

export default WebsiteIndex;

import Nav from "../../../components/layout/Nav";
import { useState } from "react";
import Loading1 from "../../../components/action/Loading1";
import { LoadingButton } from "@mui/lab";
import { Stack, Paper, Button, TextField } from "@mui/material";
import { NavLink } from "react-router-dom";

function SettingsIndex({ rootLink }) {
  const [alert, setAlert] = useState(false);
  const [response, setResponse] = useState({ status: "", response: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [current, setCurrent] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirm, setConfirm] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (current === "") {
      const msg = "Current password is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (newPass === "") {
      const msg = "New password is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (confirm === "") {
      const msg = "Confirm password is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (current && newPass && confirm) {
      setLoadingBtn(true);

      const formData = new FormData();
      formData.append("changePassword", "change");
      formData.append("current", current);
      formData.append("newPass", newPass);
      formData.append("confirm", confirm);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/server/settings.php", true);
      http.onload = function () {
        const jsonData = JSON.parse(this.responseText);
        setResponse(jsonData);
        setAlert(true);
        setTimeout(() => setIsLoading(false), 2000);
        setLoadingBtn(false);
      };
      http.send(formData);
      //END OF AJAX
    }
  };
  //END OF FORM SUBMIT

  return (
    <>
      <Nav
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <div className='content'>
        <div className='breadCrumb'>
          <NavLink
            to={"/admin/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Dashboard
          </NavLink>{" "}
          /{" "}
          <NavLink
            to={"/admin/settings/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Change Password
          </NavLink>
        </div>

        <h1>Change Password</h1>
        <Paper
          elevation={4}
          sx={{
            width: "95%",
            maxWidth: "600px",
            margin: "0 auto",
            padding: "20px",
          }}
        >
          <form onSubmit={formSubmit}>
            <Stack spacing={2}>
              <TextField
                onChange={(e) => setCurrent(e.target.value)}
                label='Current Password'
                type='password'
                fullWidth
              />
              <TextField
                onChange={(e) => setNewPass(e.target.value)}
                label='New Password'
                type='password'
                fullWidth
              />
              <TextField
                onChange={(e) => setConfirm(e.target.value)}
                label='Confirm Password'
                type='password'
                fullWidth
              />

              <LoadingButton
                variant='contained'
                onClick={formSubmit}
                loading={loadingBtn ? true : false}
              >
                Change Password
              </LoadingButton>
            </Stack>
          </form>

          <Loading1 loading={isLoading} />
        </Paper>
      </div>
    </>
  );
}

export default SettingsIndex;

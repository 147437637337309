import { useEffect, useState } from "react";
import Nav from "../../../components/layout/Nav";
import Loading1 from "../../../components/action/Loading1";
import DeleteEvent from "../../../components/action/modals/events/DeleteEvent";
import EditModal from "../../../components/action/modals/events/EditModal";
import SetAsMain from "../../../components/action/modals/events/SetAsMain";
import AddMedia from "../../../components/action/modals/events/AddMedia";
import DeleteMedia from "../../../components/action/modals/events/DeleteMedia";
import AddGallery from "../../../components/action/modals/events/AddGallery";
import { IconButton, Button, ButtonGroup, Paper } from "@mui/material";
import HTMLReactParser from "html-react-parser";
import { useParams, NavLink } from "react-router-dom";
import Sponsors from "../../../components/action/modals/events/Sponsors";
import { Delete } from "@mui/icons-material";
import ReactPaginate from "react-paginate";

function ViewEvent({ rootLink }) {
  const { eventRef } = useParams();
  const [response, setResponse] = useState({ status: "", response: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [loadContent, setLoadContent] = useState({});
  const [loadMedia, setLoadMedia] = useState([]);
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [changePhotoModal, setChangePhotoModal] = useState(false);
  const [addMediaModal, setAddMediaModal] = useState(false);
  const [gallery, setGallery] = useState(false);
  const [deleteMediaModal, setDeleteMediaModal] = useState(false);
  const [mediaRef, setMediaRef] = useState("");
  const [alert, setAlert] = useState(false);
  const [sponsors, setSponsors] = useState([]);
  const [sponsorModal, setSponsorModal] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [statistics, setStatistics] = useState([]);
  const [cause, setCause] = useState("");

  useEffect(() => {
    loadThisEvent();
    loadEventMedia();
    loadSponsors();
    loadParticipants();
    loadParticipantStatistics();
  }, [eventRef]);

  //LOAD THIS EVENT
  function loadThisEvent() {
    const formData = new FormData();
    formData.append("fetchThisEvent", "Fetch");
    formData.append("eventRef", eventRef);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/event.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setLoadContent(jsonData);
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD THIS EVENT

  //LOAD EVENT MEDIA
  function loadEventMedia() {
    const formData = new FormData();
    formData.append("fetchEventMedia", "Fetch");
    formData.append("eventRef", eventRef);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/event.php", true);
    http.onload = function () {
      setLoadMedia(JSON.parse(this.responseText));
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD EVENT MEDIA

  //DISPLAY MEDIA
  const displayItems = loadMedia.map((item) => {
    return (
      <div className='media' key={item.ref}>
        <div className='mediaFile'>
          {item.file_type === "picture" ? (
            <img
              width={"100%"}
              height={"100%"}
              src={"../../images/events/" + item.name}
            />
          ) : item.media_type === "video" ? (
            <video width={"100%"} height={"100%"} controls loading='lazy'>
              <source src={"../../images/events/" + item.name} />
            </video>
          ) : null}
        </div>

        <div className='mediaAction'>
          <ButtonGroup variant='contained' size='small'>
            {item.file_type === "picture" ? (
              <>
                {item.gallery === "yes" ? null : (
                  <Button onClick={() => addGallery(item.ref)}>
                    Add to Gallery
                  </Button>
                )}

                <Button onClick={() => openChangeModal(item.ref)}>
                  Set As Main
                </Button>
              </>
            ) : null}

            <Button color='error' onClick={() => openDeleteMedia(item.ref)}>
              Delete
            </Button>
          </ButtonGroup>
        </div>
      </div>
    );
  });
  //END OF DISPLAY MEDIA

  //LOAD SPONSORS
  const loadSponsors = () => {
    const formData = new FormData();
    formData.append("fetchEventSponsor", eventRef);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/sponsor.php", true);
    http.onload = function () {
      setSponsors(JSON.parse(this.responseText));
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF LOAD SPONSORS

  //REMOVE SPONSOR
  const removeSponsor = (sponsor) => {
    const formData = new FormData();
    formData.append("removeSponsor", sponsor);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/sponsor.php", true);
    http.onload = function () {
      loadSponsors();
      setAlert(true);
      setResponse(JSON.parse(this.responseText));
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF REMOVE SPONSOR

  //LOAD PARTICIPANTS
  const loadParticipants = () => {
    const formData = new FormData();
    formData.append("fetchEventParticipants", eventRef);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/participate.php", true);
    http.onload = function () {
      setParticipants(JSON.parse(this.responseText));
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF LOAD PARTICIPANTS

  //PAGINATION
  const itemsPerPage = 10;
  const pagesVisited = pageNumber * itemsPerPage;

  const displayParticipants = participants
    .slice(pagesVisited, pagesVisited + itemsPerPage)
    .map((item) => {
      return (
        <div className='participantsList' key={item.ref}>
          <div className='name'>{item.first_name + " " + item.last_name}</div>
        </div>
      );
    });

  const pageCount = Math.ceil(participants.length / itemsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  //END OF PAGINATION

  //LOAD PARTICIPANTS STATISTICS
  const loadParticipantStatistics = () => {
    const formData = new FormData();
    formData.append("fetchEventParticipantStatistics", eventRef);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/participate.php", true);
    http.onload = function () {
      setStatistics(JSON.parse(this.responseText));
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF LOAD PARTICIPANTS STATISTICS

  //OPEN MODAL
  const openModal = (test_ref) => {
    setModal(true);
  };
  //END OF OPEN MODAL

  //EDIT MODAL
  const openEdit = (action) => {
    setEditModal(true);
  };
  //END OF EDIT MODAL

  //ADD GALLERY
  const addGallery = (mediaRef) => {
    setMediaRef(mediaRef);
    setGallery(true);
  };
  //END OF ADD GALLERY

  //CHANGE PHOTO MODAL
  const openChangeModal = (mediaRef) => {
    setMediaRef(mediaRef);
    setChangePhotoModal(true);
  };
  //END OF CHANGE PHOTO MODAL

  //ADD MEDIA FILES MODAL
  const openAddMedia = () => {
    setAddMediaModal(true);
  };
  //END OF ADD MEDIA FILES MODAL

  //DELETE MEDIA FILES MODAL
  const openDeleteMedia = (mediaRef) => {
    setMediaRef(mediaRef);
    setDeleteMediaModal(true);
  };
  //END OF DELETE MEDIA FILES MODAL

  return (
    <>
      <Nav
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <div className='content'>
        {loadContent.status === "error" ? (
          <>
            <div className='breadCrumb'>
              <NavLink
                to={"/admin/"}
                className={(navData) =>
                  navData.isActive ? "item active" : "item"
                }
              >
                Dashboard
              </NavLink>{" "}
              /{" "}
              <NavLink
                to={"/admin/events/"}
                className={(navData) =>
                  navData.isActive ? "item active" : "item"
                }
              >
                Events
              </NavLink>{" "}
              /{" "}
              <NavLink
                to={"/admin/events/" + eventRef}
                className={(navData) =>
                  navData.isActive ? "item active" : "item"
                }
              >
                View Event
              </NavLink>
            </div>
            <Paper
              elevation={4}
              sx={{ width: "95%", margin: "0 auto", padding: "20px" }}
            >
              <h2>{loadContent.response}</h2>
            </Paper>
          </>
        ) : (
          <>
            <div className='breadCrumb'>
              <NavLink
                to={"/admin/"}
                className={(navData) =>
                  navData.isActive ? "item active" : "item"
                }
              >
                Dashboard
              </NavLink>{" "}
              /{" "}
              <NavLink
                to={"/admin/events/"}
                className={(navData) =>
                  navData.isActive ? "item active" : "item"
                }
              >
                Events
              </NavLink>{" "}
              /{" "}
              <NavLink
                to={"/admin/events/" + eventRef}
                className={(navData) =>
                  navData.isActive ? "item active" : "item"
                }
              >
                {loadContent.event}
              </NavLink>
            </div>
            <h1>{loadContent.event}</h1>

            <Paper
              elevation={4}
              sx={{ width: "95%", margin: "0 auto", padding: "20px" }}
            >
              <div className='mediaSlider'>{displayItems}</div>

              <div className='viewDescription'>
                {loadContent.description
                  ? HTMLReactParser(loadContent.description)
                  : null}
              </div>

              <br />

              <br />

              {participants.length > 0 ? (
                <>
                  <h2>
                    Participant{participants.length > 1 ? "s" : null} (
                    {statistics.selected})
                  </h2>
                  <div>{displayParticipants}</div>

                  {participants.length < itemsPerPage + 1 ? null : (
                    <div className='pagination'>
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"paginationContainer"}
                        previousLinkClassName={"prevBtn"}
                        nextLinkClassName={"nextBtn"}
                        activeClassName={"activePagination"}
                      />
                    </div>
                  )}
                </>
              ) : null}

              <br />

              {sponsors.length > 0 ? (
                <>
                  <h2>Sponsor{sponsors.length > 1 ? "s" : null}</h2>
                  <div className='sponsorContainer'>
                    {sponsors.map((item) => {
                      return (
                        <div className='sponsorCard' key={item.ref}>
                          <img
                            src={"/images/assets/sponsors/" + item.logo}
                            alt={item.name}
                          />
                          <div className='text'>
                            <div className='name'>{item.name}</div>
                            <IconButton onClick={() => removeSponsor(item.ref)}>
                              <Delete color='error' />
                            </IconButton>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : null}

              <br />

              <center>
                <ButtonGroup
                  size='small'
                  sx={{
                    flexWrap: "wrap",
                    justifyContent: "center",
                    rowGap: "5px",
                  }}
                >
                  <Button onClick={() => setSponsorModal(true)}>
                    Add Sponsor
                  </Button>

                  <Button onClick={() => openAddMedia()}>Add Media</Button>
                  <Button onClick={() => openEdit()}>Edit</Button>
                  <Button color='error' onClick={() => openModal()}>
                    Delete
                  </Button>
                </ButtonGroup>
              </center>
            </Paper>
          </>
        )}
      </div>

      {sponsorModal ? (
        <Sponsors
          eventId={eventRef}
          modal={sponsorModal}
          setModal={setSponsorModal}
          setResponse={setResponse}
          setAlert={setAlert}
          rootLink={rootLink}
          loadSponsorContent={loadSponsors}
        />
      ) : null}

      {changePhotoModal ? (
        <SetAsMain
          mediaRef={mediaRef}
          eventRef={eventRef}
          setModal={setChangePhotoModal}
          modal={changePhotoModal}
          setAlert={setAlert}
          rootLink={rootLink}
          setResponse={setResponse}
          loadEvent={loadThisEvent}
        />
      ) : null}

      {addMediaModal ? (
        <AddMedia
          eventRef={eventRef}
          setModal={setAddMediaModal}
          modal={addMediaModal}
          setAlert={setAlert}
          rootLink={rootLink}
          setResponse={setResponse}
          loadEvent={loadEventMedia}
        />
      ) : null}

      {editModal ? (
        <EditModal
          eventRef={eventRef}
          setModal={setEditModal}
          modal={editModal}
          setAlert={setAlert}
          rootLink={rootLink}
          setResponse={setResponse}
          loadEvent={loadThisEvent}
        />
      ) : null}

      {deleteMediaModal ? (
        <DeleteMedia
          mediaRef={mediaRef}
          eventRef={eventRef}
          setModal={setDeleteMediaModal}
          modal={deleteMediaModal}
          setAlert={setAlert}
          rootLink={rootLink}
          setResponse={setResponse}
          loadEvent={loadEventMedia}
        />
      ) : null}

      {gallery ? (
        <AddGallery
          mediaRef={mediaRef}
          setModal={setGallery}
          modal={gallery}
          setAlert={setAlert}
          rootLink={rootLink}
          setResponse={setResponse}
          loadEvent={loadEventMedia}
        />
      ) : null}

      {modal ? (
        <DeleteEvent
          eventRef={eventRef}
          setModal={setModal}
          modal={modal}
          setAlert={setAlert}
          rootLink={rootLink}
          setResponse={setResponse}
        />
      ) : null}

      <Loading1 loading={isLoading} />
    </>
  );
}

export default ViewEvent;

import React from "react";
import { Routes, Route } from "react-router-dom";
import VolunteerIndex from ".";

function VolunteerRouter({ rootLink }) {
  return (
    <Routes>
      <Route path='/' element={<VolunteerIndex rootLink={rootLink} />} />
    </Routes>
  );
}

export default VolunteerRouter;

import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Nav from "../../../components/layout/Nav";
import Loading1 from "../../../components/action/Loading1";
import ReactPaginate from "react-paginate";
import TestimonyDeleteHome from "../../../components/action/modals/testimony/DeleteHome";
import AddIcon from "@mui/icons-material/Add";
import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";

function Testimony({ rootLink }) {
  const [alert, setAlert] = useState(false);
  const [response, setResponse] = useState({ status: "", response: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [loadContent, setLoadContent] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [modal, setModal] = useState(false);
  const [testRef, setTestRef] = useState("");
  const [emptyContent, setEmptyContent] = useState({
    status: "",
    response: "",
  });

  useEffect(() => {
    loadTestimony();
  }, []);

  //PAGINATION
  const itemsPerPage = 20;
  const pagesVisited = pageNumber * itemsPerPage;

  const displayItems = loadContent
    .slice(pagesVisited, pagesVisited + itemsPerPage)
    .map((item) => {
      return (
        <div className='card' key={item.test_ref}>
          <Link to={"../../testimony/published/" + item.test_ref}>
            <div className='cardPicture'>
              <img src={"/images/testimony/photo/" + item.photo} />
            </div>
          </Link>
          <Link to={"../../testimony/published/" + item.test_ref}>
            <div className='cardTitle'>{item.name}</div>
          </Link>

          <div className='actions'>
            <IconButton color='error' onClick={() => openModal(item.test_ref)}>
              <Delete />
            </IconButton>
          </div>
        </div>
      );
    });

  const pageCount = Math.ceil(loadContent.length / itemsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  //END OF PAGINATION

  //LOAD TESTIMONY
  function loadTestimony() {
    const formData = new FormData();
    formData.append("fetchTestimony", "Published");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/testimony.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      if (jsonData.status === "error") {
        setEmptyContent(jsonData);
      } else {
        setLoadContent(jsonData);
      }
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD TESTIMONY

  //OPEN MODAL
  const openModal = (test_ref) => {
    setTestRef(test_ref);
    setModal(true);
  };
  //END OF OPEN MODAL

  return (
    <>
      <Nav
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <div className='content'>
        <div className='breadCrumb'>
          <NavLink
            to={"/admin/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Dashboard
          </NavLink>{" "}
          /{" "}
          <NavLink
            to={"/admin/testimony/published/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Testimonies
          </NavLink>
        </div>

        <h1>Testimonies</h1>

        <div className='homeContent'>
          <div className='contentHead'>
            <Link to={"/admin/testimony/create"}>
              <div className='addContentBtn'>
                <AddIcon />
                Create
              </div>
            </Link>

            <div className='contentNav'>
              <NavLink
                to={"../../testimony/published"}
                className={(navData) =>
                  navData.isActive ? "contentNavItem active" : "contentNavItem"
                }
              >
                Published
              </NavLink>
              <NavLink
                to={"../../testimony/pending"}
                className={(navData) =>
                  navData.isActive ? "contentNavItem active" : "contentNavItem"
                }
              >
                Pending
              </NavLink>
              <NavLink
                to={"../../testimony/declined"}
                className={(navData) =>
                  navData.isActive ? "contentNavItem active" : "contentNavItem"
                }
              >
                Declined
              </NavLink>
            </div>
          </div>

          {emptyContent.status === "error" ? (
            <>
              <h3>{emptyContent.response}</h3>
            </>
          ) : (
            <>
              <div className='cardsContainer'>{displayItems}</div>

              {loadContent.length < itemsPerPage + 1 ? null : (
                <div className='pagination'>
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationContainer"}
                    previousLinkClassName={"prevBtn"}
                    nextLinkClassName={"nextBtn"}
                    activeClassName={"activePagination"}
                  />
                </div>
              )}

              {modal ? (
                <TestimonyDeleteHome
                  testRef={testRef}
                  setModal={setModal}
                  modal={modal}
                  rootLink={rootLink}
                  setResponse={setResponse}
                  setAlert={setAlert}
                  loadTestimony={loadTestimony}
                />
              ) : null}
            </>
          )}
          <Loading1 loading={isLoading} />
        </div>
      </div>
    </>
  );
}

export default Testimony;

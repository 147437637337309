import { Routes, Route } from "react-router-dom";
import QualificationIndex from "./index";

function QualificationRouter({ rootLink }) {
  return (
    <>
      <Routes>
        <Route
          path={"/"}
          element={<QualificationIndex rootLink={rootLink} />}
        />
      </Routes>
    </>
  );
}

export default QualificationRouter;

import { Link, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import Nav from "../../../components/layout/Nav";
import Loading1 from "../../../components/action/Loading1";
import ReactPaginate from "react-paginate";
import SearchIcon from "@mui/icons-material/Search";
import { Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DeleteDonation from "../../../components/action/modals/donations/DeleteDonation";

function PendingDonationIndex({ rootLink }) {
  const [alert, setAlert] = useState(false);
  const [response, setResponse] = useState({ status: "", response: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [loadContent, setLoadContent] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [modal, setModal] = useState(false);
  const [search, setSearch] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [testRef, setTestRef] = useState("");
  const [emptyContent, setEmptyContent] = useState({
    status: "",
    response: "",
  });

  useEffect(() => {
    loadDonations();
  }, []);

  //PAGINATION
  const itemsPerPage = 20;
  const pagesVisited = pageNumber * itemsPerPage;

  const displayItems = loadContent
    .slice(pagesVisited, pagesVisited + itemsPerPage)
    .map((item) => {
      return (
        <div className='simpleCard' key={item.ref}>
          <Link to={"../../donations/pending/" + item.ref} className='head'>
            <div className='name'>{item.first_name + " " + item.last_name}</div>
            <div className='subName'>{item.status}</div>
          </Link>

          <Link to={"../../donations/pending/" + item.ref}>
            <div className='detail'>
              <div className='amount'>&#8358;{item.amount}</div>
            </div>
          </Link>
        </div>
      );
    });

  const pageCount = Math.ceil(loadContent.length / itemsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  //END OF PAGINATION

  //LOAD DONATIONS
  function loadDonations() {
    const formData = new FormData();
    formData.append("fetchDonationsCategory", "Pending");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/donations.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      if (jsonData.status === "error") {
        setEmptyContent(jsonData);
      } else {
        setLoadContent(jsonData);
      }
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD DONATIONS

  //SEARCH FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();
    setEmptyContent({
      status: "",
      response: "",
    });

    if (search === "") {
      const msg = "Search field is empty";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (search) {
      setLoadingBtn(true);

      const formData = new FormData();
      formData.append("searchDonationsCategory", search);
      formData.append("status", "Pending");

      //AJAX
      let https = new XMLHttpRequest();
      https.open("POST", rootLink + "backend/server/donations.php", true);
      https.onload = function () {
        const jsonData = JSON.parse(this.responseText);
        if (jsonData.status === "error") {
          setEmptyContent(jsonData);
        } else {
          setLoadContent(jsonData);
        }
      };
      https.send(formData);
      //END OF AJAX
    }
  };
  //END OF SEARCH FORM SUBMIT

  return (
    <>
      <Nav
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <div className='content'>
        <div className='breadCrumb'>
          <NavLink
            to={"/admin/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Dashboard
          </NavLink>{" "}
          /{" "}
          <NavLink
            to={"/admin/donations/pending"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Donations
          </NavLink>
        </div>
        <h1>Donations</h1>

        <div className='homeContent'>
          <center>
            <div className='searchDonation'>
              <form onSubmit={formSubmit}>
                <Stack direction={"row"}>
                  <input
                    type='text'
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder='Search'
                  />
                  <LoadingButton
                    onClick={formSubmit}
                    loading={loadingBtn ? true : false}
                  >
                    <SearchIcon />
                  </LoadingButton>
                </Stack>
              </form>
            </div>
          </center>

          <div className='contentHead'>
            <div className='contentNav'>
              <NavLink
                to={"../../donations/all"}
                className={(navData) =>
                  navData.isActive ? "contentNavItem active" : "contentNavItem"
                }
              >
                All
              </NavLink>

              <NavLink
                to={"../../donations/pending"}
                className={(navData) =>
                  navData.isActive ? "contentNavItem active" : "contentNavItem"
                }
              >
                Pending
              </NavLink>

              <NavLink
                to={"../../donations/successful"}
                className={(navData) =>
                  navData.isActive ? "contentNavItem active" : "contentNavItem"
                }
              >
                Successful
              </NavLink>

              <NavLink
                to={"../../donations/failed"}
                className={(navData) =>
                  navData.isActive ? "contentNavItem active" : "contentNavItem"
                }
              >
                Failed
              </NavLink>
            </div>
          </div>
          {emptyContent.status === "error" ? (
            <>
              <h3>{emptyContent.response}</h3>
            </>
          ) : loadContent.length === 0 ? (
            <h3>No donation</h3>
          ) : (
            <>
              <center>
                <div className='simpleCardContainer'>
                  <Stack
                    direction={"row"}
                    sx={{ flexWrap: "wrap", justifyContent: "center" }}
                  >
                    {displayItems}
                  </Stack>
                </div>
              </center>

              {loadContent.length < itemsPerPage + 1 ? null : (
                <div className='pagination'>
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationContainer"}
                    previousLinkClassName={"prevBtn"}
                    nextLinkClassName={"nextBtn"}
                    activeClassName={"activePagination"}
                  />
                </div>
              )}

              {modal ? (
                <DeleteDonation
                  testRef={testRef}
                  setModal={setModal}
                  modal={modal}
                  rootLink={rootLink}
                  setResponse={setResponse}
                  setAlert={setAlert}
                />
              ) : null}
            </>
          )}
          <Loading1 loading={isLoading} />
        </div>
      </div>
    </>
  );
}

export default PendingDonationIndex;

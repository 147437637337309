import { useState, useRef, useEffect } from "react";
import NavBar from "../../../components/layout/NavBar";
import { TextField, MenuItem, Paper, IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Loading1 from "../../../components/action/Loading1";
import Editor from "../../../components/Editor/Editor";
import { Helmet } from "react-helmet";

function AddTestimony({ rootLink }) {
  const [alert, setAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fullName, setFullName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState("");
  const inputRef = useRef();
  const filesRef = useRef();
  const [response, setResponse] = useState({ status: "", response: "" });
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [causes, setCauses] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    loadCauses();
  }, []);

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (fullName === "") {
      const msg = "Full Name is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (description === "") {
      const msg = "Detail is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (category === "") {
      const msg = "Course is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (project === "") {
      const msg = "Project is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (inputRef.current.files.length == 0) {
      const msg = "Photo is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (
      fullName &&
      description &&
      category &&
      project &&
      inputRef.current.files.length > 0
    ) {
      setLoadingBtn(true);
      const formData = new FormData();

      //load Inputs
      const files = filesRef.current.files;
      async function loadForm() {
        if (filesRef.current.files.length > 0) {
          for (let i in files) {
            formData.append("files" + i, files[i]);
            formData.append("filesNumber", filesRef.current.files.length);
          }
        } else {
          formData.append("files", "none");
        }
      }

      loadForm();
      formData.append("fullName", fullName);
      formData.append("category", category);
      formData.append("project", project);
      formData.append("description", description);
      formData.append("photo", inputRef.current.files[0]);
      formData.append("addTestimony", "Add");

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/client/testimony.php", true);
      http.onload = function () {
        setAlert(true);
        setResponse(JSON.parse(this.responseText));
        setLoadingBtn(false);
      };
      http.send(formData);
      //END OF AJAX
    }
  };
  //END OF FORM SUBMIT

  //LOAD CAUSES
  function loadCauses() {
    const formData = new FormData();
    formData.append("fetchCauses", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/donation.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setCauses(jsonData);
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD CAUSES

  //LOAD PROJECTS
  function loadProjects(fetch) {
    const formData = new FormData();
    formData.append("fetchThisProjects", fetch);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/testimony.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setProjects(jsonData);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD PROJECTS

  return (
    <>
      <NavBar
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <Helmet>
        <title>HUMAN TELOS INITIATIVE - TESTIMONIALS</title>
        <meta
          name='description'
          content='Human Telos Initiative - Testimonials'
        />
        <meta
          name='keywords'
          content='Human Telos Initiative Testimonials, Human Telos Testimonials, Human Telos, Human Telos Initiative, Donations, Charity, Help the less privileged, Fund raising,'
        />
      </Helmet>

      <div className='container'>
        <h2>Add Testimony</h2>

        <Paper
          elevation={2}
          sx={{
            maxWidth: "700px",
            width: "95%",
            margin: "0 auto",
            padding: "20px",
            borderRadius: "5px",
          }}
        >
          <form>
            <TextField
              label='Full Name'
              onChange={(e) => setFullName(e.target.value)}
              required
              fullWidth
            />
            <br />

            <TextField
              onChange={(e) => {
                setCategory(e.target.value);
                loadProjects(e.target.value);
              }}
              label='Course'
              fullWidth
              select
              required
            >
              {causes.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.name}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </TextField>
            <br />

            <TextField
              onChange={(e) => setProject(e.target.value)}
              label='Project'
              fullWidth
              select
              required
            >
              {projects.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.name}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </TextField>
            <br />

            <label htmlFor='files'>Photo</label>
            <input
              className='fileInput'
              ref={inputRef}
              allowed='jpg, jpeg, png'
              type='file'
            />

            <label htmlFor='description'>Detail</label>
            <Editor
              style={{ width: "100% !important" }}
              value=''
              setValue={setDescription}
            />

            <br />

            <label htmlFor='files'>Documents</label>
            <input
              ref={filesRef}
              allowed='jpg, jpeg, png, mp4'
              type='file'
              multiple
              className='fileInput'
            />

            <br />

            <LoadingButton
              variant='contained'
              onClick={formSubmit}
              loading={loadingBtn ? true : false}
            >
              Add
            </LoadingButton>
          </form>

          <IconButton onClick={() => navigate("../")}>
            <ArrowBack />
          </IconButton>
          <br />
        </Paper>

        <br />
      </div>

      <Loading1 loading={isLoading} />
    </>
  );
}

export default AddTestimony;

import NavBar from "../../../components/layout/NavBar";
import { useEffect, useState } from "react";
import Loading1 from "../../../components/action/Loading1";
import EditIcon from "@mui/icons-material/Edit";
import { useParams, useNavigate } from "react-router-dom";
import { Stack, Paper, IconButton } from "@mui/material";
import EditAnnouncement from "../../../components/action/modals/announcement/EditAnnouncement";
import ClearAnnouncement from "../../../components/action/modals/announcement/ClearAnnouncement";
import DeleteIcon from "@mui/icons-material/Delete";
import { ArrowBack } from "@mui/icons-material";
import HTMLReactParser from "html-react-parser";
import { Helmet } from "react-helmet";

function ViewAnnouncement({ rootLink }) {
  const [alert, setAlert] = useState(false);
  const [response, setResponse] = useState({ status: "", response: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [clearModal, setClearModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [loadContent, setLoadContent] = useState([]);
  const { viewRef } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    loadAnnouncement();
  }, [viewRef]);

  //LOAD ANNOUNCEMENT
  function loadAnnouncement() {
    const formData = new FormData();
    formData.append("fetchThisAnnouncement", viewRef);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/announcement.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setLoadContent(jsonData);
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD ANNOUNCEMENT

  //OPEN EDIT MODAL
  const openEditModal = () => {
    setEditModal(true);
  };
  //END OF OPEN EDIT MODAL

  //OPEN CLEAR MODAL
  const openClearModal = () => {
    setClearModal(true);
  };
  //END OF OPEN CLEAR MODAL

  return (
    <>
      <NavBar
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <Helmet>
        <title>HUMAN TELOS INITIATIVE - ANNOUNCEMENTS</title>
        <meta
          name='description'
          content='Human Telos Initiative - Announcements'
        />
        <meta
          name='keywords'
          content='Human Telos Initiative Announcements, Human Telos Announcements, Human Telos, Human Telos Initiative, Donations, Charity, Help the less privileged, Fund raising,'
        />
      </Helmet>

      <div className='container'>
        {loadContent.status === "error" ? (
          <Paper
            elevation={4}
            sx={{ width: "95%", margin: "0 auto", padding: "20px" }}
          >
            <h2>{loadContent.response}</h2>
          </Paper>
        ) : (
          <>
            <h2>{loadContent.title}</h2>

            <Paper
              elevation={4}
              sx={{ width: "95%", margin: "0 auto", padding: "20px" }}
            >
              <div>
                {loadContent.message
                  ? HTMLReactParser(loadContent.message)
                  : null}
              </div>
              <br />
              <br />

              {loadContent.link === "" ? null : (
                <center>
                  <a className='linkButton' href={loadContent.link}>
                    Click Here
                  </a>
                </center>
              )}

              <IconButton onClick={() => navigate("../")}>
                <ArrowBack />
              </IconButton>
            </Paper>
          </>
        )}
      </div>
      <Loading1 loading={isLoading} />
    </>
  );
}

export default ViewAnnouncement;

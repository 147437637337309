import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import { useState } from "react";

function SendMessage({ rootLink, setAlert, setResponse }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (name === "") {
      const msg = "Full Name is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }
    if (email === "") {
      const msg = "Email is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }
    if (!isEmail(email)) {
      const msg = "Email is invalid";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (phone === "") {
      const msg = "Phone number is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (phone.length < 8) {
      const msg = "Phone number is invalid";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (isNaN(phone)) {
      const msg = "Phone number is invalid";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (subject === "") {
      const msg = "Subject is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (message === "") {
      const msg = "Message is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (
      name &&
      email &&
      isEmail(email) &&
      phone &&
      !isNaN(phone) &&
      phone.length > 8 &&
      subject &&
      message
    ) {
      setLoadingBtn(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("subject", subject);
      formData.append("message", message);
      formData.append("sendMessage", "Send");

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/client/message.php", true);
      http.onload = function () {
        setAlert(true);
        setResponse(JSON.parse(this.responseText));
        setLoadingBtn(false);
      };
      http.send(formData);
      //END OF AJAX
    }
  };
  //END OF FORM SUBMIT

  function isEmail(email) {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  }

  return (
    <form onSubmit={formSubmit}>
      <TextField
        onChange={(e) => setName(e.target.value)}
        label='Full Name'
        size='small'
        required
        fullWidth
      />
      <br />
      <TextField
        onChange={(e) => setEmail(e.target.value)}
        label='Email'
        type='email'
        required
        size='small'
        fullWidth
      />
      <br />
      <TextField
        onChange={(e) => setPhone(e.target.value)}
        label='Phone Number'
        type='number'
        required
        size='small'
        fullWidth
      />
      <br />
      <TextField
        onChange={(e) => setSubject(e.target.value)}
        label='Subject'
        size='small'
        required
        fullWidth
      />
      <br />
      <TextField
        onChange={(e) => setMessage(e.target.value)}
        label='Messaage'
        required
        fullWidth
        size='small'
        multiline
      />
      <br />

      <LoadingButton
        onClick={formSubmit}
        variant='contained'
        startIcon={<SendIcon />}
        loading={loadingBtn ? true : false}
      >
        Send
      </LoadingButton>
    </form>
  );
}

export default SendMessage;

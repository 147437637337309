import Nav from "../../../components/layout/Nav";
import DeleteEvent from "../../../components/action/modals/events/DeleteEvent";
import { useEffect, useState } from "react";
import Loading1 from "../../../components/action/Loading1";
import ReactPaginate from "react-paginate";
import AddIcon from "@mui/icons-material/Add";
import { Paper, Stack } from "@mui/material";
import { Link, NavLink } from "react-router-dom";

function EventsIndex({ rootLink }) {
  const [alert, setAlert] = useState(false);
  const [response, setResponse] = useState({ status: "", response: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [modal, setModal] = useState(false);
  const [eventRef, setEventRef] = useState("");
  const [loadContent, setLoadContent] = useState([]);
  const [emptyContent, setEmptyContent] = useState({
    status: "",
    response: "",
  });

  useEffect(() => {
    loadEvents();
  }, []);

  //LOAD CONTENTS
  function loadEvents() {
    const formData = new FormData();
    formData.append("fetchEvents", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/event.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      if (jsonData.status === "error") {
        setEmptyContent(jsonData);
      } else {
        setLoadContent(jsonData);
      }
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD CONTENTS

  //PAGINATION
  const itemsPerPage = 20;
  const pagesVisited = pageNumber * itemsPerPage;

  const displayItems = loadContent
    .slice(pagesVisited, pagesVisited + itemsPerPage)
    .map((item) => {
      return (
        <div className='eventCard' key={item.ref}>
          <Link to={"../../events/" + item.ref}>
            <div>
              <div>
                <img src={"/images/events/" + item.picture} />
              </div>

              <div className='name'>
                <div>{item.event}</div>
              </div>
            </div>
          </Link>

          <div className='delete'>
            <div onClick={() => openModal(item.ref)}>Delete</div>
          </div>
        </div>
      );
    });

  const pageCount = Math.ceil(loadContent.length / itemsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  //END OF PAGINATION

  //OPEN MODAL
  const openModal = (eventRef) => {
    setEventRef(eventRef);
    setModal(true);
  };
  //END OF OPEN MODAL

  return (
    <>
      <Nav
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <div className='content'>
        <div className='breadCrumb'>
          <NavLink
            to={"/admin/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Dashboard
          </NavLink>{" "}
          /{" "}
          <NavLink
            to={"/admin/events/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Events
          </NavLink>
        </div>

        <h1>Events</h1>
        <Paper
          elevation={4}
          sx={{ width: "95%", margin: "0 auto", padding: "20px" }}
        >
          <Link to={"../add"}>
            <div className='addContentBtn'>
              <AddIcon />
              Add
            </div>
          </Link>

          {emptyContent.status === "error" ? (
            <>
              <h3>{emptyContent.response}</h3>
            </>
          ) : loadContent.length === 0 ? (
            <h3>No Event</h3>
          ) : (
            <>
              <center>
                <div className='simpleCard1Container'>
                  <Stack
                    direction={"row"}
                    sx={{ justifyContent: "center", flexWrap: "wrap" }}
                  >
                    {displayItems}
                  </Stack>
                </div>
              </center>

              {loadContent.length < itemsPerPage + 1 ? null : (
                <div className='pagination'>
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationContainer"}
                    previousLinkClassName={"prevBtn"}
                    nextLinkClassName={"nextBtn"}
                    activeClassName={"activePagination"}
                  />
                </div>
              )}

              {modal ? (
                <DeleteEvent
                  eventRef={eventRef}
                  setModal={setModal}
                  modal={modal}
                  rootLink={rootLink}
                  setResponse={setResponse}
                  setAlert={setAlert}
                  loadEvent={loadEvents}
                />
              ) : null}
            </>
          )}

          <Loading1 loading={isLoading} />
        </Paper>
      </div>
    </>
  );
}

export default EventsIndex;

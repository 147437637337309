import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Loading1 from "../../Loading1";
import Editor from "../../../Editor/Editor";

function EditModal({
  testRef,
  modal,
  rootLink,
  setResponse,
  loadTestimony,
  setModal,
  setAlert,
}) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loadContent, setLoadContent] = useState({});
  const [fullName, setFullName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState("");
  const [causes, setCauses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadThisTestimony();
    loadCauses();
    loadProjects();
  }, []);

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (fullName === "") {
      const msg = "Full Name is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (category === "") {
      const msg = "Course is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (project === "") {
      const msg = "Project is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (description === "") {
      const msg = "Detail is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (fullName && description && category && project) {
      setLoadingBtn(true);
      const formData = new FormData();
      formData.append("fullName", fullName);
      formData.append("category", category);
      formData.append("project", project);
      formData.append("description", description);
      formData.append("testRef", testRef);
      formData.append("editTestimonyContent", "Edit");

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/server/testimony.php", true);
      http.onload = function () {
        setResponse(JSON.parse(this.responseText));
        loadTestimony();
        setModal(false);
        setAlert(true);
        setLoadingBtn(false);
      };
      http.send(formData);
      //END OF AJAX
    }
  };
  //END OF FORM SUBMIT

  //LOAD PROJECTS
  function loadProjects() {
    const formData = new FormData();
    formData.append("fetchThisProjects", category);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/participate.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setProjects(jsonData);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD PROJECTS

  //LOAD CAUSES
  function loadCauses() {
    const formData = new FormData();
    formData.append("fetchCauses", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/causes.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setCauses(jsonData);
      setIsLoading(false);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD CAUSES

  //LOAD THIS TESTIMONY
  function loadThisTestimony() {
    const formData = new FormData();
    formData.append("fetchThisTestimony", "Fetch");
    formData.append("testRef", testRef);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/testimony.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setFullName(jsonData.name);
      setDescription(jsonData.description);
      setCategory(jsonData.category);
      setProject(jsonData.project);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD THIS TESTIMONY

  return (
    <Dialog open={modal} fullWidth>
      <DialogTitle>Edit Testimony</DialogTitle>

      <DialogContent>
        <form>
          <br />
          <TextField
            label='Full Name'
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            fullWidth
          />

          <br />

          <TextField
            label='Course'
            value={category}
            onChange={(e) => {
              setCategory(e.target.value);
              loadProjects();
            }}
            select
            fullWidth
            required
          >
            {causes.map((item) => {
              return (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              );
            })}
          </TextField>

          <br />

          <TextField
            label='Project'
            value={project}
            onChange={(e) => setProject(e.target.value)}
            select
            fullWidth
            required
          >
            {projects.map((item) => {
              return (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              );
            })}
          </TextField>

          <br />

          <label htmlFor='description'>Detail</label>

          <Editor value={description} setValue={setDescription} />

          <br />
          <DialogActions>
            <Stack spacing={2} direction={"row"}>
              <Button
                variant='contained'
                onClick={(e) => {
                  e.preventDefault();
                  setModal(false);
                }}
                color='error'
              >
                Cancel
              </Button>
              <LoadingButton
                loading={loadingBtn ? true : false}
                variant='contained'
                onClick={formSubmit}
              >
                Edit
              </LoadingButton>
            </Stack>
          </DialogActions>
        </form>

        <Loading1 loading={isLoading} />
      </DialogContent>
    </Dialog>
  );
}

export default EditModal;

import { Snackbar, Alert, AlertProps, IconButton } from "@mui/material";
import { forwardRef, useState, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import Person from "@mui/icons-material/Person";
import Menu from "@mui/icons-material/Menu";
import Cookies from "js-cookie";
import { RootLink } from "../../pages/admin/router";
import { LoggedIn } from "../../pages/admin/router";

const SnackbarAlert = forwardRef(function SnackbarAlert(props, ref) {
  return <Alert elevation={6} ref={ref} {...props} />;
});

function Nav({ response, setResponse, alert, setAlert }) {
  const [sideBar, setSideBar] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const rootLink = useContext(RootLink);
  const setLoggedin = useContext(LoggedIn);

  if (response.response !== "") {
    setTimeout(() => {
      setResponse({
        status: "",
        response: "",
      });
    }, 5500);

    setTimeout(() => {
      setAlert(false);
    }, 5000);
  }

  //HANDLE DROP DOWN
  const handleDropDown = () => {
    if (dropDown === false) {
      setDropDown(true);
      setSideBar(false);
    } else {
      setDropDown(false);
    }
  };
  //END OF HANDLE DROP DOWN

  //HANDLE SIDE BAR
  const handleSideBar = () => {
    if (sideBar === false) {
      setSideBar(true);
      setDropDown(false);
    } else {
      setSideBar(false);
    }
  };
  //END OF HANDLE SIDE BAR

  //LOG OUT FUNCTION
  const logout = () => {
    const login = Cookies.get("logged");

    const data = new FormData();
    data.append("logOutUser", login);

    const https = new XMLHttpRequest();
    https.open("POST", rootLink + "backend/server/login.php", true);
    https.onload = function () {
      const res = JSON.parse(this.responseText);
      setResponse(res);
      setLoggedin(false);
      Cookies.remove("logged");
    };
    https.send(data);
  };
  //END LOG OUT FUNCTION

  return (
    <>
      <nav>
        <IconButton
          size='large'
          onClick={handleSideBar}
          className='adminHamburger'
        >
          <Menu />
        </IconButton>

        <div className='adminLogo'>
          <img src='/images/assets/logo/logo1.png' />
          <div>
            <div className='siteName'>Human Telos</div>
            <div className='subName'>Initiative</div>
          </div>
        </div>

        <IconButton onClick={handleDropDown} className='profile'>
          <Person />
        </IconButton>
      </nav>

      <div className={sideBar ? "sideBar active" : "sideBar"}>
        <div className='sideBarContent'>
          <NavLink
            to={"/admin/"}
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideBar(false)}
          >
            Dashboard
          </NavLink>

          <NavLink
            to={"/admin/testimony/published/"}
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideBar(false)}
          >
            Testimonies
          </NavLink>

          <NavLink
            to={"/admin/donations/all/"}
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideBar(false)}
          >
            Donations
          </NavLink>

          <NavLink
            to={"/admin/volunteers/"}
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideBar(false)}
          >
            Volunteers
          </NavLink>

          <NavLink
            to={"/admin/events/"}
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideBar(false)}
          >
            Events
          </NavLink>

          <NavLink
            to={"/admin/gallery/"}
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideBar(false)}
          >
            Gallery
          </NavLink>

          <NavLink
            to={"/admin/messages/"}
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideBar(false)}
          >
            Messages
          </NavLink>

          <NavLink
            to={"/admin/socials/"}
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideBar(false)}
          >
            Contact Details
          </NavLink>

          <NavLink
            to={"/admin/replies/"}
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideBar(false)}
          >
            Auto Response
          </NavLink>

          <NavLink
            to={"/admin/announcement/"}
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideBar(false)}
          >
            Announcement
          </NavLink>

          <NavLink
            to={"/admin/projects"}
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideBar(false)}
          >
            Projects
          </NavLink>

          <NavLink
            to={"/admin/participants/"}
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideBar(false)}
          >
            Participants
          </NavLink>

          <NavLink
            to={"/admin/about"}
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideBar(false)}
          >
            About Us
          </NavLink>

          <NavLink
            to={"/admin/sponsors"}
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideBar(false)}
          >
            Sponsors
          </NavLink>

          <NavLink
            to={"/admin/website"}
            className={(navData) =>
              navData.isActive ? "navLink active" : "navLink"
            }
            onClick={() => setSideBar(false)}
          >
            Web Content
          </NavLink>
        </div>
      </div>

      <div className={dropDown ? "dropDown active" : "dropDown"}>
        <Link to={"/"}>
          <div>Visit Website</div>
        </Link>
        <Link to={"/admin/settings"}>
          <div>Account Settings</div>
        </Link>
        <div style={{ color: "red" }} onClick={logout}>
          Log Out
        </div>
      </div>

      <Snackbar
        open={alert}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <SnackbarAlert color={response.status}>
          {response.response}
        </SnackbarAlert>
      </Snackbar>
    </>
  );
}

export default Nav;

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { color } from "@mui/system";

export default function ConfirmAction({
  action,
  testRef,
  modal,
  setModal,
  setAlert,
  rootLink,
  setResponse,
  loadTestimony,
}) {
  const [loadingBtn, setLoadingBtn] = useState(false);

  //CONTINUE ACTION
  const continueAction = () => {
    setLoadingBtn(true);
    const formData = new FormData();
    formData.append("testimonyStatus", action);
    formData.append("testRef", testRef);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/testimony.php", true);
    http.onload = function () {
      setResponse(JSON.parse(this.responseText));
      loadTestimony();
      setModal(false);
      setAlert(true);
      setLoadingBtn(false);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF CONTINUE ACTION
  return (
    <Dialog open={modal}>
      <DialogTitle>Confirm Action</DialogTitle>

      <DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={() => {
              setModal(false);
            }}
            color={action === "Declined" ? "primary" : "error"}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loadingBtn ? true : false}
            onClick={continueAction}
            variant='contained'
            color={action === "Declined" ? "error" : "primary"}
          >
            {action === "Published"
              ? "Publish"
              : action === "Declined"
              ? "Decline"
              : "Continue"}
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";

function DeleteCause({
  causeRef,
  modal,
  rootLink,
  setResponse,
  setModal,
  setAlert,
  loadCauses,
}) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const deleteId = causeRef;

  const deleteBtn = (e) => {
    setLoadingBtn(true);
    e.preventDefault();

    //DELETE CAUSE
    const formData = new FormData();
    formData.append("deleteCause", deleteId);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/causes.php", true);
    http.onload = function () {
      setResponse(JSON.parse(this.responseText));
      setModal(false);
      setAlert(true);
      setLoadingBtn(false);
      loadCauses();
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF DELETE CAUSE;

  return (
    <Dialog open={modal}>
      <DialogTitle>Delete Course</DialogTitle>

      <DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={() => {
              setModal(false);
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loadingBtn ? true : false}
            onClick={deleteBtn}
            variant='contained'
            color='error'
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default DeleteCause;

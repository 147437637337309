import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState, useRef } from "react";

function AddSponsor({
  modal,
  rootLink,
  setResponse,
  setModal,
  setAlert,
  loadSponsors,
}) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const inputRef = useRef();

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (name === "") {
      const msg = "Name is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (inputRef.current.files.length === 0) {
      const msg = "Logo/Picture is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (name && inputRef.current.files.length > 0) {
      setLoadingBtn(true);

      const formData = new FormData();
      formData.append("addSponsor", "Add");
      formData.append("name", name);
      formData.append("link", link);
      formData.append("logo", inputRef.current.files[0]);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/server/sponsor.php", true);
      http.onload = function () {
        setResponse(JSON.parse(this.responseText));
        setModal(false);
        setAlert(true);
        setLoadingBtn(false);
        loadSponsors();
      };
      http.send(formData);
    }
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal}>
      <DialogTitle>Add Sponsors</DialogTitle>

      <DialogContent>
        <div className='formContainer' style={{ width: "100%" }}>
          <form onSubmit={formSubmit}>
            <label htmlFor='files'>Logo/Picture</label>
            <input ref={inputRef} allowed='jpg, jpeg, png' type='file' />

            <br />
            <TextField
              onChange={(e) => setName(e.target.value)}
              type='text'
              label='Name'
              required
            />

            <br />

            <TextField
              onChange={(e) => setLink(e.target.value)}
              type='url'
              label='Link'
            />

            <DialogActions>
              <Button
                color='error'
                variant='contained'
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={loadingBtn ? true : false}
                onClick={formSubmit}
                variant='contained'
              >
                Add
              </LoadingButton>
            </DialogActions>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default AddSponsor;

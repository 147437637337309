import { Routes, Route } from "react-router-dom";
import GalleryIndex from "./index";

function GalleryRouter({ rootLink }) {
  return (
    <>
      <Routes>
        <Route path={"/*"} element={<GalleryIndex rootLink={rootLink} />} />
      </Routes>
    </>
  );
}

export default GalleryRouter;

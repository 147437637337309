import { useEffect, useState } from "react";
import Nav from "../../../components/layout/Nav";
import Loading1 from "../../../components/action/Loading1";
import { Stack, Button, ButtonGroup, Paper } from "@mui/material";
import HTMLReactParser from "html-react-parser";
import DeleteParticipant from "../../../components/action/modals/participants/DeleteParticipant";
import { useParams, NavLink } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

function ViewParticipant({ rootLink }) {
  const { participantRef } = useParams();
  const [response, setResponse] = useState({ status: "", response: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [loadContent, setLoadContent] = useState({});
  const [modal, setModal] = useState(false);
  const [replyModal, setReplyModal] = useState(false);
  const [displayReply, setDisplayReply] = useState(false);
  const [alert, setAlert] = useState(false);
  const [loadingSelect, setLoadingSelect] = useState(false);

  useEffect(() => {
    loadThisParticipant();
  }, [participantRef]);

  //LOAD THIS PARTICIPANT
  function loadThisParticipant() {
    const formData = new FormData();
    formData.append("fetchThisParticipant", "Fetch");
    formData.append("participantRef", participantRef);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/participate.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setLoadContent(jsonData);
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD THIS PARTICIPANT

  //OPEN MODAL
  const openModal = () => {
    setModal(true);
  };
  //END OF OPEN MODAL

  //OPEN REPLY
  const openReply = () => {
    setReplyModal(true);
  };
  //END OF OPEN REPLY

  //HANDLE SELECTED PARTICIPANTS
  const handleSelect = (id) => {
    setLoadingSelect(true);

    const formData = new FormData();
    formData.append("participantSelect", id);

    //AJAX
    let https = new XMLHttpRequest();
    https.open("POST", rootLink + "backend/server/participate.php", true);
    https.onload = function () {
      setAlert(true);
      setResponse(JSON.parse(this.responseText));
      loadThisParticipant();
      setLoadingSelect(false);
    };
    https.send(formData);
    //END OF AJAX
  };
  //END OF HANDLE SELECTED PARTICIPANTS

  return (
    <>
      <Nav
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <div className='content'>
        <div className='breadCrumb'>
          <NavLink
            to={"/admin/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Dashboard
          </NavLink>{" "}
          /{" "}
          <NavLink
            to={"/admin/participants/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Participants
          </NavLink>{" "}
          /{" "}
          <NavLink
            to={"/admin/participants/" + participantRef}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            View Participant
          </NavLink>
        </div>
        {loadContent.status === "error" ? (
          <Paper
            elevation={4}
            sx={{ width: "95%", margin: "0 auto", padding: "20px" }}
          >
            <h2>{loadContent.response}</h2>
          </Paper>
        ) : (
          <>
            <h1>View Participant</h1>

            <Paper
              elevation={4}
              sx={{
                width: "90%",
                maxWidth: "700px",
                margin: "0 auto",
                padding: "20px",
              }}
            >
              <div className='detailContainer'>
                <div className='detail'>
                  <div className='title'>Full Name</div>
                  <div className='body'>
                    {loadContent.first_name + " " + loadContent.last_name}
                  </div>
                </div>
                <div className='detail'>
                  <div className='title'>Phone Number</div>
                  <div className='body'>{loadContent.phone}</div>
                </div>
                <div className='detail'>
                  <div className='title'>Email</div>
                  <div className='body'>{loadContent.email}</div>
                </div>

                <div className='detail'>
                  <div className='title'>Course</div>
                  <div className='body'>{loadContent.cause}</div>
                </div>

                <div className='detail'>
                  <div className='title'>Project</div>
                  <div className='body'>{loadContent.project}</div>
                </div>

                <div className='detail'>
                  <div className='title'>Qualification</div>
                  <div className='body'>{loadContent.qualification}</div>
                </div>

                <div className='detail'>
                  <div className='title'>School Attended</div>
                  <div className='body'>{loadContent.school}</div>
                </div>

                <div className='detail'>
                  <div className='title'>Course of Study</div>
                  <div className='body'>{loadContent.course}</div>
                </div>

                <div className='detail'>
                  <div className='title'>Address</div>
                  <div className='body'>{loadContent.address}</div>
                </div>

                <div className='detail'>
                  <div className='title'>Status</div>
                  <div className='body'>
                    {loadContent.selected === "yes"
                      ? "Selected"
                      : "Not Selected"}
                  </div>
                </div>
              </div>

              <br />

              <center>
                <ButtonGroup
                  size='small'
                  sx={{
                    flexWrap: "wrap",
                    justifyContent: "center",
                    rowGap: "5px",
                  }}
                >
                  {loadContent.status === "closed" ? null : (
                    <LoadingButton
                      loading={loadingSelect ? true : false}
                      onClick={() => handleSelect(loadContent.ref)}
                      variant='outlined'
                    >
                      {loadContent.selected === "no" ? "Select" : "Unselect"}
                    </LoadingButton>
                  )}
                  <Button color='error' onClick={() => openModal()}>
                    Delete
                  </Button>
                </ButtonGroup>
              </center>
            </Paper>
          </>
        )}
      </div>

      {modal ? (
        <DeleteParticipant
          participantRef={participantRef}
          setModal={setModal}
          modal={modal}
          setAlert={setAlert}
          rootLink={rootLink}
          setResponse={setResponse}
        />
      ) : null}

      <Loading1 loading={isLoading} />
    </>
  );
}

export default ViewParticipant;

import React, { useState, useEffect } from "react";
import NavBar from "../../../components/layout/NavBar";
import Loading1 from "../../../components/action/Loading1";
import { Paper, IconButton } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import HTMLReactParser from "html-react-parser";
import { ArrowBack } from "@mui/icons-material";
import { Helmet } from "react-helmet";

function ViewTestimonies({ rootLink }) {
  const [response, setResponse] = useState({ status: "", response: "" });
  const [alert, setAlert] = useState(false);
  const [testimony, setTestimony] = useState([]);
  const [media, setMedia] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { ref } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    LoadTestimony();
    LoadTestimonyMedia();
  }, [ref]);

  //FETCH TESTIMONY
  const LoadTestimony = () => {
    const formData = new FormData();
    formData.append("fetchThisTestimony", ref);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/testimony.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setTestimony(jsonData);

      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF FETCH TESTIMONY

  //FETCH TESTIMONY MEDIA
  const LoadTestimonyMedia = () => {
    const formData = new FormData();
    formData.append("fetchThisTestimonyMedia", ref);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/testimony.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setMedia(jsonData);

      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF FETCH TESTIMONY MEDIA

  return (
    <>
      <NavBar
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <Helmet>
        <title>HUMAN TELOS INITIATIVE - TESTIMONIALS</title>
        <meta
          name='description'
          content='Human Telos Initiative - Testimonials'
        />
        <meta
          name='keywords'
          content='Human Telos Initiative Testimonials, Human Telos Testimonials, Human Telos, Human Telos Initiative, Donations, Charity, Help the less privileged, Fund raising,'
        />
      </Helmet>

      <div className='container'>
        <h2>View Testimony</h2>
        <Paper
          elevation={2}
          sx={{
            maxWidth: "1000px",
            width: "95%",
            margin: "0 auto",
            padding: "20px",
            borderRadius: "5px",
          }}
        >
          <IconButton onClick={() => navigate("../")}>
            <ArrowBack />
          </IconButton>

          {testimony.length === 0 ? (
            <h2>No Testimony Found</h2>
          ) : testimony.status === "error" ? (
            <h2>{testimony.response}</h2>
          ) : (
            <>
              <div className='discription'>
                {testimony.description
                  ? HTMLReactParser(testimony.description)
                  : null}
              </div>

              {media.length === 0 ? null : (
                <div className='scroller'>
                  {media.map((item) => {
                    return (
                      <>
                        <div className='scrollItem'>
                          {item.media_type === "picture" ? (
                            <a
                              href={
                                "/images/testimony/media/" + item.media_file
                              }
                              target='_blank'
                            >
                              <img
                                width={"100%"}
                                height={"100%"}
                                src={
                                  "../../images/testimony/media/" +
                                  item.media_file
                                }
                              />
                            </a>
                          ) : item.media_type === "video" ? (
                            <video
                              width={"100%"}
                              height={"100%"}
                              controls
                              loading='lazy'
                            >
                              <source
                                src={"../../images/testimonys/" + item.name}
                              />
                            </video>
                          ) : null}
                        </div>
                      </>
                    );
                  })}
                </div>
              )}

              <br />
              <div className='infoCard'>
                <img
                  width={"100%"}
                  height={"100%"}
                  src={"../../images/testimony/photo/" + testimony.photo}
                />

                <div className='name'>{testimony.name}</div>
                <div className='subTitle'>
                  {testimony.project ? testimony.project : null}
                </div>
              </div>
            </>
          )}
        </Paper>
      </div>
      <Loading1 loading={isLoading} />
    </>
  );
}

export default ViewTestimonies;

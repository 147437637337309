import { useEffect, useState } from "react";
import Nav from "../../../components/layout/Nav";
import Loading1 from "../../../components/action/Loading1";
import ConfirmAction from "../../../components/action/modals/testimony/ConfirmAction";
import TestimonyDelete from "../../../components/action/modals/testimony/Delete";
import EditModal from "../../../components/action/modals/testimony/EditModal";
import ChangePhoto from "../../../components/action/modals/testimony/ChangePhoto";
import AddMedia from "../../../components/action/modals/testimony/AddMedia";
import DeleteMedia from "../../../components/action/modals/testimony/DeleteMedia";
import { Stack, Button, ButtonGroup, Paper } from "@mui/material";
import { useNavigate, useParams, Link, NavLink } from "react-router-dom";
import HTMLReactParser from "html-react-parser";

function ViewTestimony({ rootLink }) {
  const { testRef } = useParams();
  const [response, setResponse] = useState({ status: "", response: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [loadContent, setLoadContent] = useState({});
  const [loadMedia, setLoadMedia] = useState([]);
  const [modal, setModal] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [changePhotoModal, setChangePhotoModal] = useState(false);
  const [addMediaModal, setAddMediaModal] = useState(false);
  const [deleteMediaModal, setDeleteMediaModal] = useState(false);
  const [mediaRef, setMediaRef] = useState("");
  const [action, setAction] = useState("");
  const [alert, setAlert] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    loadThisTestimony();
    loadMediaTestimony();
    if (loadContent !== "") {
      setTimeout(() => setIsLoading(false), 2000);
    }
  }, [testRef]);

  //LOAD THIS TESTIMONY
  function loadThisTestimony() {
    const formData = new FormData();
    formData.append("fetchThisTestimony", "Fetch");
    formData.append("testRef", testRef);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/testimony.php", true);
    http.onload = function () {
      setLoadContent(JSON.parse(this.responseText));
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD THIS TESTIMONY

  //LOAD TESTIMONY MEDIA
  function loadMediaTestimony() {
    const formData = new FormData();
    formData.append("fetchTestimonyMedia", "Fetch");
    formData.append("testRef", testRef);

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/testimony.php", true);
    http.onload = function () {
      setLoadMedia(JSON.parse(this.responseText));
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD TESTIMONY MEDIA

  //DISPLAY MEDIA
  const displayItems = loadMedia.map((item) => {
    return (
      <div key={item.test_media_ref}>
        <div className='media'>
          <div className='mediaFile'>
            {item.media_type === "picture" ? (
              <img
                width={"100%"}
                height={"100%"}
                src={"/images/testimony/media/" + item.media_file}
              />
            ) : item.media_type === "video" ? (
              <video width={"100%"} height={"100%"} controls loading='lazy'>
                <source src={"/images/testimony/media/" + item.media_file} />
              </video>
            ) : null}
          </div>

          <div className='mediaAction'>
            <Button
              variant='contained'
              color='error'
              size='small'
              onClick={() => openDeleteMedia(item.test_media_ref)}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    );
  });
  //END OF DISPLAY MEDIA

  //OPEN MODAL
  const openModal = (test_ref) => {
    setModal(true);
  };
  //END OF OPEN MODAL

  //ACTION TAKEN
  const actionTaken = (action) => {
    setAction(action);
    setActionModal(true);
  };
  //END OF ACTION TAKEN

  //EDIT MODAL
  const openEdit = (action) => {
    setEditModal(true);
  };
  //END OF EDIT MODAL

  //CHANGE PHOTO MODAL
  const openChangeModal = (action) => {
    setChangePhotoModal(true);
  };
  //END OF CHANGE PHOTO MODAL

  //ADD MEDIA FILES MODAL
  const openAddMedia = (action) => {
    setAddMediaModal(true);
  };
  //END OF ADD MEDIA FILES MODAL

  //DELETE MEDIA FILES MODAL
  const openDeleteMedia = (mediaRef) => {
    setMediaRef(mediaRef);
    setDeleteMediaModal(true);
  };
  //END OF DELETE MEDIA FILES MODAL

  return (
    <>
      <Nav
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />
      <div className='content'>
        <div className='breadCrumb'>
          <NavLink
            to={"/admin/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Dashboard
          </NavLink>{" "}
          /{" "}
          <NavLink
            to={"/admin/testimony/published/"}
            className={(navData) => (navData.isActive ? "item active" : "item")}
          >
            Testimonies
          </NavLink>{" "}
          / <div className='item active'>View Testimony</div>
        </div>

        {loadContent.status === "error" ? (
          <Paper
            elevation={4}
            sx={{ width: "95%", margin: "0 auto", padding: "20px" }}
          >
            <h2>{loadContent.response}</h2>
          </Paper>
        ) : (
          <>
            <h1>View Testimony</h1>
            <Paper
              elevation={4}
              sx={{ width: "95%", margin: "0 auto", padding: "20px" }}
            >
              {loadMedia.length > 0 ? (
                <div className='mediaSlider'>{displayItems}</div>
              ) : null}

              <div className='viewPersonal'>
                <div className='viewPictureContainer'>
                  <div className='viewPicture'>
                    <img
                      width={"100%"}
                      height={"100%"}
                      src={"/images/testimony/photo/" + loadContent.photo}
                    />
                  </div>
                </div>

                <div className='viewNameContainer'>
                  <div className='viewName'>{loadContent.name}</div>

                  {loadContent.project !== "" ? (
                    <>
                      <div className='viewCategory'>{loadContent.project}</div>
                    </>
                  ) : null}

                  <div
                    className={
                      loadContent.status === "Pending"
                        ? "viewStatus blue"
                        : loadContent.status === "Published"
                        ? "viewStatus green"
                        : loadContent.status === "Declined"
                        ? "viewStatus red"
                        : "viewStatus"
                    }
                  >
                    {loadContent.status}
                  </div>
                </div>
              </div>

              <div className='viewDescription'>
                {loadContent.description
                  ? HTMLReactParser(loadContent.description)
                  : null}
              </div>

              <br />
              <br />

              <br />
              <br />

              <center>
                <ButtonGroup>
                  {loadContent.status !== "Published" ? (
                    <Button
                      variant='contained'
                      onClick={() => actionTaken("Published")}
                    >
                      Publish
                    </Button>
                  ) : null}
                  {loadContent.status === "Pending" ? (
                    <Button
                      variant='contained'
                      color='error'
                      onClick={() => actionTaken("Declined")}
                    >
                      Decline
                    </Button>
                  ) : null}
                </ButtonGroup>

                <br />

                <br />
                <ButtonGroup
                  size='small'
                  sx={{
                    flexWrap: "wrap",
                    justifyContent: "center",
                    rowGap: "5px",
                  }}
                >
                  <Button onClick={() => openAddMedia()}>Add Media</Button>
                  <Button onClick={() => openChangeModal()}>
                    Change Photo
                  </Button>
                  <Button onClick={() => openEdit()}>Edit</Button>
                  <Button color='error' onClick={() => openModal()}>
                    Delete
                  </Button>
                </ButtonGroup>
              </center>
            </Paper>
          </>
        )}
      </div>

      {changePhotoModal ? (
        <ChangePhoto
          testRef={testRef}
          setModal={setChangePhotoModal}
          modal={changePhotoModal}
          setAlert={setAlert}
          rootLink={rootLink}
          setResponse={setResponse}
          loadTestimony={loadThisTestimony}
        />
      ) : null}

      {addMediaModal ? (
        <AddMedia
          testRef={testRef}
          setModal={setAddMediaModal}
          modal={addMediaModal}
          setAlert={setAlert}
          rootLink={rootLink}
          setResponse={setResponse}
          loadTestimony={loadMediaTestimony}
        />
      ) : null}

      {editModal ? (
        <EditModal
          testRef={testRef}
          setModal={setEditModal}
          modal={editModal}
          setAlert={setAlert}
          rootLink={rootLink}
          setResponse={setResponse}
          loadTestimony={loadThisTestimony}
        />
      ) : null}

      {deleteMediaModal ? (
        <DeleteMedia
          mediaRef={mediaRef}
          testRef={testRef}
          setModal={setDeleteMediaModal}
          modal={deleteMediaModal}
          setAlert={setAlert}
          rootLink={rootLink}
          setResponse={setResponse}
          loadTestimony={loadMediaTestimony}
        />
      ) : null}

      {actionModal ? (
        <ConfirmAction
          action={action}
          testRef={testRef}
          setModal={setActionModal}
          modal={actionModal}
          setAlert={setAlert}
          rootLink={rootLink}
          setResponse={setResponse}
          loadTestimony={loadThisTestimony}
        />
      ) : null}

      {modal ? (
        <TestimonyDelete
          testRef={testRef}
          setModal={setModal}
          modal={modal}
          setAlert={setAlert}
          rootLink={rootLink}
          setResponse={setResponse}
          redirect={redirect}
        />
      ) : null}

      <Loading1 loading={isLoading} />
    </>
  );
}

export default ViewTestimony;

import Nav from "../../components/layout/Nav";
import { useState, useEffect } from "react";
import Loading1 from "../../components/action/Loading1";
import { Badge, Paper } from "@mui/material";
import { Link } from "react-router-dom";

function Admin({ rootLink }) {
  const [alert, setAlert] = useState(false);
  const [response, setResponse] = useState({ status: "", response: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [recentDonations, setRecentDonations] = useState([]);
  const [recentVolunteers, setRecentVolunteers] = useState([]);
  const [details, setDetails] = useState([]);

  useEffect(() => {
    loadDonations();
    loadVolunteers();
    loadDetails();
  }, []);

  //LOAD RECENT DONATIONS
  function loadDonations() {
    const formData = new FormData();
    formData.append("fetchDonations", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/dashboard.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setRecentDonations(jsonData);
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD RECENT DONATIONS

  //LOAD RECENT VOLUNTEERS
  function loadVolunteers() {
    const formData = new FormData();
    formData.append("fetchVolunteers", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/dashboard.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setRecentVolunteers(jsonData);
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  }
  //END OF LOAD RECENT VOLUNTEERS

  //LOAD RECENT DETAILS
  const loadDetails = () => {
    const formData = new FormData();
    formData.append("fetchDetails", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/server/dashboard.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setDetails(jsonData);
      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF LOAD RECENT DETAILS

  return (
    <>
      <Nav
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />
      <div className='content'>
        <h1>Dashboard</h1>

        <div className='dashConatainer'>
          <div className='dashCard'>
            <div className='cardChildHeader'>Donations</div>
            <div className='cardChildren'>
              <div className='childItem'>
                <Link to={"/admin/donations/all/"}>
                  <div className='childHead'>
                    Total{" "}
                    {details.total_donations === 0
                      ? null
                      : "(" + details.total_donations + ")"}
                  </div>
                </Link>
                <div className='childNumber'>
                  &#8358;
                  {details.donations_total_amount === null
                    ? 0
                    : details.donations_total_amount}
                </div>
              </div>
              <div className='childItem'>
                <Link to={"/admin/donations/successful/"}>
                  <div className='childHead'>
                    Successful{" "}
                    {details.successful_donations === 0
                      ? null
                      : "(" + details.successful_donations + ")"}
                  </div>
                </Link>
                <div className='childNumber'>
                  &#8358;
                  {details.donations_successful_amount === null
                    ? 0
                    : details.donations_successful_amount}{" "}
                </div>
              </div>
              <div className='childItem'>
                <Link to={"/admin/donations/pending/"}>
                  <div className='childHead'>
                    Pending{" "}
                    {details.pending_donations === 0
                      ? null
                      : "(" + details.pending_donations + ")"}
                  </div>
                </Link>
                <div className='childNumber'>
                  &#8358;
                  {details.donations_pending_amount === null
                    ? 0
                    : details.donations_pending_amount}
                </div>
              </div>
            </div>
          </div>

          <div className='dashCard'>
            <div className='cardChildHeader'>Statistics</div>
            <div className='cardChildren'>
              <div className='childItem'>
                <Link to={"/admin/volunteers/"}>
                  <>
                    <div className='childHead'>Volunteers</div>
                    <div className='childNumber'>
                      {details.total_volunteers}
                    </div>
                  </>
                </Link>
              </div>
              <div className='childItem'>
                <Link to={"/admin/testimony/published/"}>
                  <>
                    <div className='childHead'>Testimonies</div>
                    <div className='childNumber'>
                      {details.total_testimonies}
                    </div>
                  </>
                </Link>
              </div>
              <div className='childItem'>
                <Badge badgeContent={details.unread_messages} color='error'>
                  <Link to={"/admin/messages/"}>
                    <div className='msg'>
                      <div className='childHead'>Messages</div>
                      <div className='childNumber'>
                        {details.total_messages}
                      </div>
                    </div>
                  </Link>
                </Badge>
              </div>
            </div>
          </div>
        </div>

        <Paper
          elevation={4}
          sx={{
            width: "80%",
            margin: "40px auto",
            padding: "20px",
          }}
        >
          <div className='dashboardContents'>
            <Link to={"/admin/donations/all/"}>
              <div className='dashLink'>Donations</div>
            </Link>

            <Link to={"/admin/testimony/published/"}>
              <div className='dashLink'>Testimonies</div>
            </Link>

            <Link to={"/admin/events/"}>
              <div className='dashLink'>Events</div>
            </Link>

            <Link to={"/admin/volunteers/"}>
              <div className='dashLink'>Volunteers</div>
            </Link>

            <Link to={"/admin/gallery/"}>
              <div className='dashLink'>Gallery</div>
            </Link>

            <Link to={"/admin/participants/"}>
              <div className='dashLink'>Participants</div>
            </Link>

            <Link to={"/admin/messages/"}>
              <div className='dashLink'>Messages</div>
            </Link>

            <Link to={"/admin/socials/"}>
              <div className='dashLink'>Contact Details</div>
            </Link>

            <Link to={"/admin/replies/"}>
              <div className='dashLink'>Auto Response</div>
            </Link>

            <Link to={"/admin/sponsors/"}>
              <div className='dashLink'>Sponsors</div>
            </Link>

            <Link to={"/admin/announcement/"}>
              <div className='dashLink'>Announcement</div>
            </Link>

            <Link to={"/admin/courses/"}>
              <div className='dashLink'>Courses</div>
            </Link>

            <Link to={"/admin/qualifications/"}>
              <div className='dashLink'>Qualifications</div>
            </Link>

            <Link to={"/admin/about/"}>
              <div className='dashLink'>About Us</div>
            </Link>

            <Link to={"/admin/website/"}>
              <div className='dashLink'>Web Content</div>
            </Link>
          </div>
        </Paper>

        <Loading1 loading={isLoading} />
      </div>
    </>
  );
}

export default Admin;

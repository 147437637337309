import React, { useState, useEffect } from "react";
import NavBar from "../../../components/layout/NavBar";
import Loading1 from "../../../components/action/Loading1";
import { Link, useNavigate } from "react-router-dom";
import { Paper, IconButton } from "@mui/material";
import {
  Mail,
  Phone,
  Facebook,
  WhatsApp,
  Twitter,
  Instagram,
  LocationOn,
  ArrowBack,
} from "@mui/icons-material";
import SendMessage from "../../../components/action/features/SendMessage";
import { Helmet } from "react-helmet";

function ContactIndex({ rootLink }) {
  const [response, setResponse] = useState({ status: "", response: "" });
  const [alert, setAlert] = useState(false);
  const [socials, setSocials] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    LoadSocials();
  }, []);

  //FETCH SOCIALS
  const LoadSocials = () => {
    const formData = new FormData();
    formData.append("fetchSocials", "fetch");

    //AJAX
    let http = new XMLHttpRequest();
    http.open("POST", rootLink + "backend/client/homepage.php", true);
    http.onload = function () {
      const jsonData = JSON.parse(this.responseText);
      setSocials(jsonData);

      setTimeout(() => setIsLoading(false), 2000);
    };
    http.send(formData);
    //END OF AJAX
  };
  //END OF FETCH SOCIALS

  return (
    <>
      <NavBar
        response={response}
        setResponse={setResponse}
        alert={alert}
        setAlert={setAlert}
      />

      <Helmet>
        <title>HUMAN TELOS INITIATIVE - CONTACT US</title>
        <meta
          name='description'
          content='Human Telos Initiative - Contact Us'
        />
        <meta
          name='keywords'
          content='Human Telos Initiative Contact Us, Human Telos Contact Us, Human Telos, Human Telos Initiative, Donations, Charity, Help the less privileged, Fund raising,'
        />
      </Helmet>

      <div className='container'>
        <div className='events'>
          <h2 className='title'>Contact Us</h2>

          <Paper
            elevation={2}
            sx={{
              maxWidth: "1200px",
              width: "95%",
              margin: "0 auto",
              padding: "20px",
              borderRadius: "5px",
            }}
          >
            <div className='contactContent'>
              <div className='socials'>
                {socials.length > 0 ? (
                  <>
                    <h3>Socials</h3>

                    {socials[0].name === "" ? null : (
                      <div className='social'>
                        <div className='platform'>
                          <Phone />
                        </div>
                        <div className='platformName'>
                          <a href={"tel:" + socials[0].name}>
                            {socials[0].name}
                          </a>
                        </div>
                      </div>
                    )}

                    {socials[1].name === "" ? null : (
                      <div className='social'>
                        <div className='platform'>
                          <Mail />
                        </div>
                        <div className='platformName'>
                          <a href={"mailto:" + socials[1].name}>
                            {socials[1].name}
                          </a>
                        </div>
                      </div>
                    )}

                    {socials[2].name === "" ? null : (
                      <div className='social'>
                        <div className='platform'>
                          <Facebook />
                        </div>
                        <div className='platformName'>
                          <a href={socials[2].link}>{socials[2].name}</a>
                        </div>
                      </div>
                    )}

                    {socials[3].name === "" ? null : (
                      <div className='social'>
                        <div className='platform'>
                          <WhatsApp />
                        </div>
                        <div className='platformName'>
                          <a href={socials[3].link}>{socials[3].name}</a>
                        </div>
                      </div>
                    )}

                    {socials[4].name === "" ? null : (
                      <div className='social'>
                        <div className='platform'>
                          <Twitter />
                        </div>
                        <div className='platformName'>
                          <a href={socials[4].link}>{socials[4].name}</a>
                        </div>
                      </div>
                    )}

                    {socials[5].name === "" ? null : (
                      <div className='social'>
                        <div className='platform'>
                          <Instagram />
                        </div>
                        <div className='platformName'>
                          <a href={socials[5].link}>{socials[5].name}</a>
                        </div>
                      </div>
                    )}

                    {socials[6].name === "" ? null : (
                      <div className='social'>
                        <div className='platform'>
                          <LocationOn />
                        </div>
                        <div className='platformName'>{socials[6].name}</div>
                      </div>
                    )}
                  </>
                ) : null}
              </div>

              <div className='message'>
                <h3>Send Us A Message</h3>
                <SendMessage
                  rootLink={rootLink}
                  setAlert={setAlert}
                  setResponse={setResponse}
                />
              </div>
            </div>
            <IconButton onClick={() => navigate("../../")}>
              <ArrowBack />
            </IconButton>
          </Paper>
        </div>
      </div>

      <Loading1 loading={isLoading} />
    </>
  );
}

export default ContactIndex;

import { useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";

function ChangePhoto({
  modal,
  rootLink,
  setResponse,
  loadAbout,
  setModal,
  setAlert,
}) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const inputRef = useRef();

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (inputRef.current.files.length === 0) {
      const msg = "Photo is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }
    if (inputRef.current.files.length > 0) {
      setLoadingBtn(true);
      const formData = new FormData();
      formData.append("changeAboutPhoto", "Change");
      formData.append("photo", inputRef.current.files[0]);

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/server/about.php", true);
      http.onload = function () {
        const jsonData = JSON.parse(this.responseText);
        setResponse(jsonData);
        loadAbout();
        setModal(false);
        setAlert(true);
      };
      http.send(formData);
      //END OF AJAX
    }
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal}>
      <DialogTitle>Change Photo</DialogTitle>

      <DialogContent>
        <div className='formContainer' style={{ width: "100%" }}>
          <form>
            <label htmlFor='files'>Photo</label>
            <input ref={inputRef} allowed='jpg, jpeg, png' type='file' />
            <DialogActions>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setModal(false);
                }}
                variant='contained'
                color='error'
              >
                Cancel
              </Button>

              <LoadingButton
                loading={loadingBtn ? true : false}
                variant='contained'
                onClick={formSubmit}
              >
                Change
              </LoadingButton>
            </DialogActions>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ChangePhoto;

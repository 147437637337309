import React from "react";
import { Routes, Route } from "react-router-dom";
import SponsorsIndex from ".";
import ErrorPage from "../404";

function SponsorsRouter({ rootLink }) {
  return (
    <Routes>
      <Route path='/' element={<SponsorsIndex rootLink={rootLink} />} />
      <Route path='*' element={<ErrorPage rootLink={rootLink} />} />
    </Routes>
  );
}

export default SponsorsRouter;

import { Routes, Route } from "react-router-dom";
import SocialsIndex from "./index";

function SocialsRouter({ rootLink }) {
  return (
    <>
      <Routes>
        <Route path={"/"} element={<SocialsIndex rootLink={rootLink} />} />
      </Routes>
    </>
  );
}

export default SocialsRouter;

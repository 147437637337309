import { Snackbar, Alert, AlertProps, IconButton } from "@mui/material";
import { forwardRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Menu from "@mui/icons-material/Menu";
import { Helmet } from "react-helmet";

const SnackbarAlert = forwardRef(function SnackbarAlert(props, ref) {
  return <Alert elevation={6} ref={ref} {...props} />;
});

function NavBar({ response, setResponse, alert, setAlert }) {
  const [dropDown, setDropDown] = useState(false);

  if (response.response != "") {
    setTimeout(() => {
      setResponse({
        status: "",
        response: "",
      });
    }, 5500);

    setTimeout(() => {
      setAlert(false);
    }, 5000);
  }

  //HANDLE DROP DOWN
  const handleDropDown = () => {
    if (dropDown === false) {
      setDropDown(true);
    } else {
      setDropDown(false);
    }
  };
  //END OF HANDLE DROP DOWN

  return (
    <>
      <nav>
        <Link to={"/"}>
          <div className='logo'>
            <img src='/images/assets/logo/logo1.png' />
            <div>
              <div className='siteName'>Human Telos</div>
              <div className='subName'>Initiative</div>
            </div>
          </div>
        </Link>

        <IconButton
          size='large'
          onClick={handleDropDown}
          className='adminHamburger'
        >
          <Menu />
        </IconButton>

        <ul className='navBarList'>
          <li>
            <NavLink
              to={"/"}
              className={(navData) =>
                navData.isActive ? "navLink1 active" : "navLink1"
              }
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/volunteer"}
              className={(navData) =>
                navData.isActive ? "navLink1 active" : "navLink1"
              }
            >
              Volunteer
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/events"}
              className={(navData) =>
                navData.isActive ? "navLink1 active" : "navLink1"
              }
            >
              Events
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/donate"}
              className={(navData) =>
                navData.isActive ? "navLink1 active" : "navLink1"
              }
            >
              Donation
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"/about"}
              className={(navData) =>
                navData.isActive ? "navLink1 active" : "navLink1"
              }
            >
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/testimonials"}
              className={(navData) =>
                navData.isActive ? "navLink1 active" : "navLink1"
              }
            >
              Testimonials
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/contact"}
              className={(navData) =>
                navData.isActive ? "navLink1 active" : "navLink1"
              }
            >
              Contact Us
            </NavLink>
          </li>
        </ul>
      </nav>

      <div className={dropDown ? "dropMenu active" : "dropMenu"}>
        <ul className='dropList'>
          <li>
            <NavLink
              to={"/"}
              className={(navData) =>
                navData.isActive ? "dropLink active" : "dropLink"
              }
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/volunteer"}
              className={(navData) =>
                navData.isActive ? "dropLink active" : "dropLink"
              }
            >
              Volunteer
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/events"}
              className={(navData) =>
                navData.isActive ? "dropLink active" : "dropLink"
              }
            >
              Events
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/donate"}
              className={(navData) =>
                navData.isActive ? "dropLink active" : "dropLink"
              }
            >
              Donation
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"/participate"}
              className={(navData) =>
                navData.isActive ? "dropLink active" : "dropLink"
              }
            >
              Participation
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/about"}
              className={(navData) =>
                navData.isActive ? "dropLink active" : "dropLink"
              }
            >
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/testimonials"}
              className={(navData) =>
                navData.isActive ? "dropLink active" : "dropLink"
              }
            >
              Testimonials
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/contact"}
              className={(navData) =>
                navData.isActive ? "dropLink active" : "dropLink"
              }
            >
              Contact Us
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"/announcement"}
              className={(navData) =>
                navData.isActive ? "dropLink active" : "dropLink"
              }
            >
              Announcement
            </NavLink>
          </li>

          <li>
            <NavLink
              to={"/sponsors"}
              className={(navData) =>
                navData.isActive ? "dropLink active" : "dropLink"
              }
            >
              Sponsors
            </NavLink>
          </li>
        </ul>
      </div>

      <Helmet>
        <meta name='description' content='Human Telos Initiative' />
        <meta
          name='keywords'
          content='Human Telos, Human Telos Initiative, Donations, Charity, Help the less privileged, Fund raising,'
        />
      </Helmet>

      <Snackbar
        open={alert}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <SnackbarAlert color={response.status}>
          {response.response}
        </SnackbarAlert>
      </Snackbar>
    </>
  );
}

export default NavBar;

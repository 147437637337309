import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/globals.css";
import "./styles/generalElement.css";
import "./styles/classElement.css";
import "./styles/homepage.css";
import "./styles/gallery.css";
import "./styles/footer.css";
import "./styles/testimony.css";
import "./styles/forms.css";
import "./styles/nav.css";
import "./styles/cleanList.css";
import "./styles/announcement.css";
import "./styles/event.css";
import "./styles/breadcrumb.css";
import "./styles/navBar.css";
import "./styles/sideBar.css";
import "./styles/sponsor.css";
import "./styles/homeList.css";
import "./styles/message.css";
import "./styles/details.css";
import "./styles/dashboard.css";
import "./styles/search.css";
import "./styles/simpleList.css";
import "./styles/simpleList1.css";
import "./styles/simplerList.css";
import "./styles/viewTestimony.css";
import "./styles/pagination.css";
import App from "./App";
import { HashRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <App />
  </HashRouter>
);

import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Editor from "../../../Editor/Editor";

function ReplyModal({
  messageRef,
  modal,
  rootLink,
  setResponse,
  loadMessage,
  setModal,
  setAlert,
}) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  //FORM SUBMIT
  const formSubmit = (e) => {
    e.preventDefault();

    if (subject === "") {
      const msg = "Subject is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (message === "") {
      const msg = "Message is required";

      const arr = { status: "error", response: msg };
      setResponse(arr);
      setAlert(true);
    }

    if (subject && message) {
      setLoadingBtn(true);
      const formData = new FormData();
      formData.append("subject", subject);
      formData.append("message", message);
      formData.append("messageRef", messageRef);
      formData.append("replyMessage", "Reply");

      //AJAX
      let http = new XMLHttpRequest();
      http.open("POST", rootLink + "backend/server/messages.php", true);
      http.onload = function () {
        setResponse(JSON.parse(this.responseText));
        loadMessage();
        setModal(false);
        setAlert(true);
        setLoadingBtn(false);
      };
      http.send(formData);
      //END OF AJAX
    }
  };
  //END OF FORM SUBMIT

  return (
    <Dialog open={modal} fullWidth>
      <DialogTitle>Reply Message</DialogTitle>

      <DialogContent>
        <form>
          <br />
          <TextField
            label='Subject'
            onChange={(e) => setSubject(e.target.value)}
            fullWidth
          />

          <label htmlFor='message'>Message</label>

          <Editor setValue={setMessage} />

          <br />
          <DialogActions>
            <Stack spacing={2} direction={"row"}>
              <Button
                variant='contained'
                onClick={(e) => {
                  e.preventDefault();
                  setModal(false);
                }}
                color='error'
              >
                Cancel
              </Button>
              <LoadingButton
                loading={loadingBtn ? true : false}
                variant='contained'
                onClick={formSubmit}
              >
                Send
              </LoadingButton>
            </Stack>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default ReplyModal;

import logo from "./logo.svg";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import AdminRouter from "./pages/admin/router";
import HomeRouter from "./pages/home/router";

function App() {
  //const rootLink = "http://localhost/react/humantelos/public/";
  const rootLink = "https://humantelos.org/";

  return (
    <>
      <Routes>
        <Route path={"*"} element={<HomeRouter rootLink={rootLink} />} />
        <Route
          path={"/admin/*"}
          element={<AdminRouter rootLink={rootLink} />}
        />
      </Routes>
    </>
  );
}

export default App;
